import { Injectable } from '@angular/core';
import { MappingsContext } from 'source-list-map';
import {round}  from 'mathjs';
//import {regression} from 'spm-regression';
import { MapType } from '@angular/compiler/src/output/output_ast';
//import { time } from 'console';

const NORMAL_TEST_SAMPLES = 16000;
const LONG_TEST_SAMPLES = 53333;
const POSTVESCTOMY_SAMPLES = 106666;


function XYpow(X:number, Y:number){
  let Res :number=1;

  for(let i=0;i<Y;i++){
    Res*=X;
  }
  return Res;
}

function round_float(Val:number, digitNum:number){
  
  let devNum:number=XYpow(10.0,digitNum);
  let TemVal:number= Val*devNum;

  TemVal=round(TemVal);

  return TemVal/devNum;
}

export enum TESTTYPE
{
  NORMAL=1,
  DILUTED ,
  LOW_VOLUME,

}

export enum SAMPLETYPE
{
  FRESH=1,
  WASHED,
  FROZEN,
  POSTVASECTOMY,
  LONGEVITY,
  SWIM_UP, 
  MANUAL,
  GRADING,
  NONE,
  CONTROL=100,
}

export enum Debris_Scan
{
    LOW= 1,
    MODERATE,
    HIGH,
    GROSS
}
export enum ChamberStd
{
  MAKLER=1,
  NEUBAUER

}

export enum WBCConcStd
{
  WBC_CONC_NORMAL=1,
  WBC_CONC_ABNORMAL,

}
export enum LES
{
   OneUS = 1,
   TwoROW,
}
export enum MorphCriteria{
  MORPH_WHO3=1,
  MORPH_WHO4,
  MORPH_WHO5,
  MORPH_WHO6,
}
export enum ControlType{

    CONTROL_TYPE_LATEX_BEADS= 1,
    CONTROL_TYPE_STAB_SPERM,
    CONTROL_TYPE_PROF_CAP,
    CONTROL_TYPE_PROF_NEQAS,
}
export enum ControlLevel{
    LEVEL_1 = 1,
    LEVEL_2,
    NEGATIVE_CONTROL
}
export class KeyParameters{
  OD : number;
  COUNT : number;
  AV : number;
  AW : number;
  constructor(od: number, count : number, av :number, aw: number){
    this.OD=od;
    this.COUNT=count;
    this.AV=av;
    this.AW=aw;
  }
  printKeyPar(){
    console.log("\nInput keys:\nOD: "+this.OD+"\nCount: "+this.COUNT,"\nAV: "+this.AV,"\nAW: "+this.AW);
}
}

export class Results{

   TSC :number;
    MSC :number;
    Motility : number;
    MotilitySign :number; //0- No Sign   1- '<'   2- '>'
    SMI :number;
    Velocity :number;
    Morphology :number;
    FSC :number;
    PMSCa :number;
    PMSCb :number;
    PMSC :number;
    PMota :number;
    PMotb :number;
    PMot :number;
    NonProgMot :number;
    Immot :number;
    TotalTSC :number;
    TotalMSC :number;
    TotalPMSC :number;
    TotalFSC :number;
    TotalMorphNorm :number;
    NWbcMotF3:number;
    TSC_Raw:number;
    constructor(){
      this.TSC=-1;
      this.MSC=-1;
      this.Motility=-1;
      this.MotilitySign=0;
      this.SMI=-1;
      this.Velocity=-1;
      this.Morphology=-1;
      this.FSC=-1;
      this.PMSC=-1;
      this.PMSCa=-1;
      this.PMSCb=-1;
      this.PMot=-1;
      this.PMota=-1;
      this.PMotb=-1;
      this.NonProgMot=-1;
      this.Immot=-1;
      this.TotalTSC=-1;
      this.TotalMSC=-1;
      this.TotalPMSC=-1;
      this.TotalFSC=-1;
      this.TotalMorphNorm=-1;
      this.NWbcMotF3=-1;
      this.TSC_Raw=-1;
    }
    printAllResults(){
          console.log("\nThe final results:\nTSC: "+this.TSC+"\nMotility: "+this.Motility,"\nPmota: "+this.PMota,"\nPmotb: "+this.PMotb,"\nPmot: "+this.PMot,
          "\nNonProg.: "+this.NonProgMot,"\nImmotile: "+this.Immot,"\nMorph: "+this.Morphology,"\nMSC: "+this.MSC,"\nPMSCa: "+this.PMSCa,"\nPMSCb: "+this.PMSCb,
          "\nPMSC: "+this.PMSC,"\nFSC: "+this.FSC,"\nSMI: "+this.SMI,"\nVelocity: "+this.Velocity ,"\nTotal TSC: "+this.TotalTSC,
          "\nTotal MSC: "+this.TotalMSC,"\nTotal PMSC: "+this.TotalPMSC,"\nTotal FSC: "+this.TotalFSC,"\nTotal Morph: "+this.TotalMorphNorm);
    }


    getResults(){
       
        var resultdata =  
        { 
        "TSC": this.TSC,
        "MSC": this.MSC,
        "Motility" : this.Motility,
        "MotilitySign" :this.MotilitySign,   //0- No Sign   1- '<'   2- '>'
        "SMI" :this.SMI,
        "Velocity" :this.Velocity,
        "Morphology" :this.Morphology,
        "FSC" :this.FSC,
        "PMSCa" :this.PMSCa,
        "PMSCb" :this.PMSCb,
        "PMSC" :this.PMSC,
        "PMota" :this.PMota,
        "PMotb" :this.PMotb,
        "PMot" :this.PMot,
        "NonProgMot" :this.NonProgMot,
        "Immot" :this.Immot,
        "TotalTSC" :this.TotalTSC,
        "TotalMSC" :this.TotalMSC,
        "TotalPMSC" :this.TotalPMSC,
        "TotalFSC" :this.TotalFSC,
        "TotalMorphNorm": this.TotalMorphNorm,
       // "NWbcMotF3":this.NWbcMotF3,
       }

       return resultdata;
    }


};

export class ManualResults{
    Immot :number;
    MSC :number;
    PMSCa :number;
    PMSCb :number;
    PMSC :number;
    NonProgMot :number;
    TotalTSC :number;
    TotalMSC :number;
    TotalPMSC :number;
    TotalMorphNorm :number;
    isError :boolean;
    

    constructor(){
        this.Immot=-1;
        this.MSC=-1;
        this.PMSC=-1;
        this.PMSCa=-1;
        this.PMSCb=-1;
        this.NonProgMot=-1;
        this.TotalTSC=-1;
        this.TotalMSC=-1;
        this.TotalPMSC=-1;
        this.TotalMorphNorm=-1;
        this.isError=false;
       
    }
    
    printAllResults(){
        console.log("\nThe final Manual results:\nMSC: "+this.MSC,"\nPMSCa: "+this.PMSCa,"\nPMSCb: "+this.PMSCb,
        "\nPMSC: "+this.PMSC,"\nNone Progresive: "+this.NonProgMot,"\nImMotile: "+this.Immot,"\nTotal TSC: "+this.TotalTSC,"\nTotal MSC: "+this.TotalMSC,"\nTotal PMSC: "+this.TotalPMSC,"\nTotal Morph: "+this.TotalMorphNorm,"\nError: "+String(this.isError) );
  }


  getResults(){
     
      var resultdata =  
      { 
      "MSC": this.MSC,
      "PMSCa" :this.PMSCa,
      "PMSCb" :this.PMSCb,
      "PMSC" :this.PMSC,
      "NonProgMot" :this.NonProgMot,
      "Immot" :this.Immot,
      "TotalTSC" :this.TotalTSC,
      "TotalMSC" :this.TotalMSC,
      "TotalPMSC" :this.TotalPMSC,
      "TotalMorphNorm": this.TotalMorphNorm,
     }
  }


}
export class TestSetup{
   
  MorphologyCriteria : MorphCriteria;
  ConcStandard :WBCConcStd;
  sampleType :SAMPLETYPE;
  chamberStandard: ChamberStd;
  Volume : number;
  testType: TESTTYPE;
  LesFlag: LES;
  DebrisScan: Debris_Scan;
  control_Level: ControlLevel;  //= ControlLevel.LEVEL_1;
  control_Type: ControlType;
  LBODAmp:number;
  constructor(SampleT: SAMPLETYPE, TestT : TESTTYPE, WBCConc:WBCConcStd, chamberSt:ChamberStd , morph:MorphCriteria, LesF:LES, vol:number,debris:Debris_Scan, C_level:ControlLevel = ControlLevel.LEVEL_1, con_type:ControlType=ControlType.CONTROL_TYPE_LATEX_BEADS, LB_OD_Amp:number=1000){
    this.sampleType=SampleT;
    this.testType=TestT;
    this.ConcStandard=WBCConc;
    this.chamberStandard=chamberSt;
    this.MorphologyCriteria=morph;
    this.LesFlag=LesF;
    this.Volume=vol;
    this.DebrisScan=debris;
    this.control_Level=C_level;
    this.control_Type=con_type;
    this.LBODAmp=LB_OD_Amp;
  }
}

class FirstRoundPar
{
    PerlimTSC :number;
    ReCalc_TSC_LQ1 :number;
    ReCalc_TSC_LQ2 :number;
    TSC1 :number;
    TSC2 :number;
    TSC3 :number;
    TSC4 :number;
    //public double TSC5;
    Morph :number;

           constructor( P_TSC:number, _TSC1:number, TSC_LQ1: number, TSC_LQ2:number, P_TSC2:number, P_TSC3:number,P_TSC4:number, P_Morph:number)
            {
                this.PerlimTSC = P_TSC;
                this.ReCalc_TSC_LQ1 = TSC_LQ1;
                this.ReCalc_TSC_LQ2 = TSC_LQ2;
                this.TSC1 = _TSC1;
                this.TSC2 = P_TSC2;
                this.TSC3 = P_TSC3;
                this.TSC4 = P_TSC4;
                this.Morph = P_Morph;
            }
           /* constructor(FirstRoundPar F_RoundP)
            {
                PerlimTSC = F_RoundP.PerlimTSC;
                ReCalc_TSC_LQ1 = F_RoundP.ReCalc_TSC_LQ1;
                ReCalc_TSC_LQ2 = F_RoundP.ReCalc_TSC_LQ2;
                TSC1 = F_RoundP.TSC1;
                TSC2 = F_RoundP.TSC2;
                TSC4 = F_RoundP.TSC4;
                TSC3 = F_RoundP.TSC3;
                Morph = F_RoundP.Morph;
            }*/

        

}

class ManualTestResult{
  protected WHO: MorphCriteria;  
  protected TSC: number;
  protected TotalMotility: number;
  protected PMota: number;
  protected PMotb: number;
  protected NormalForm: number;
  protected Volume : number;
  public TheTestRes:ManualResults;

  constructor(who_Type: MorphCriteria ,Conc:number, totalMot:number, normaMorph: number, pmot1: number, pmot2:number=-1,volume: number=-1){
      this.Volume=volume;
      this.WHO=who_Type;
      this.TSC=Conc;
      this.TotalMotility=totalMot;
      this.NormalForm=normaMorph;
      this.PMota=pmot1;
      this.PMotb=pmot2;
      this.TheTestRes=new ManualResults();

  }

  PerformTest():void{ 
      
    if(this.TSC<0.0){
        this.TheTestRes.isError=true;
        return;
    }
    this.TheTestRes.MSC=this.TSC*this.TotalMotility/100.0;
    if(this.TotalMotility<0.0 || this.TotalMotility>100.0){
        this.TheTestRes.isError=true;
        return;
    }
    else{
        this.TheTestRes.Immot=100.0-this.TotalMotility;
        
    }
    
    if(this.PMota<0.0 || this.PMota>100.0 || this.PMota>this.TotalMotility){
        this.TheTestRes.isError=true;
        return;
    }
    if(this.WHO==MorphCriteria.MORPH_WHO5){
        this.TheTestRes.PMSC= this.TSC*this.PMota/100.0;
        this.TheTestRes.NonProgMot=this.TotalMotility-this.PMota;
        
    }
    else{
        if(this.PMotb<0.0 || this.PMotb>100.0 || (this.PMota+this.PMotb)>this.TotalMotility){
            this.TheTestRes.isError=true;
            return;
        }
        this.TheTestRes.PMSCa= this.TSC*this.PMota/100.0;
        this.TheTestRes.PMSCb= this.TSC*this.PMotb/100.0;  
        this.TheTestRes.NonProgMot=this.TotalMotility-this.PMota-this.PMotb;
    
    }
    
    if(this.Volume>=0){
        this.TheTestRes.TotalTSC=this.Volume*this.TSC;
        this.TheTestRes.TotalMSC=this.Volume*this.TheTestRes.MSC;
        if(this.WHO==MorphCriteria.MORPH_WHO5){
            this.TheTestRes.TotalPMSC=this.TheTestRes.PMSC*this.Volume;
        }
        else{
            this.TheTestRes.TotalPMSC=(this.TheTestRes.PMSCb+this.TheTestRes.PMSCa)*this.Volume;
        }
        if(this.NormalForm<0.0 || this.NormalForm>100.0){
            this.TheTestRes.isError=true;
            return;
        }
        this.TheTestRes.TotalMorphNorm=this.TSC*this.NormalForm/100.0*this.Volume;
    }

  }
}

class TestResult{

  protected SetupConstants:TestSetup;  
  protected CurrKeyPar:KeyParameters;
  protected PerlimKeyPar:KeyParameters;
  public TheTestRes:Results;
  protected NormFactor:number;
  protected ODAdjusted: number;
  
  protected IsAverWidthNormalized:boolean=false;
  protected IsABNormalWbcDebris:boolean= false;
  protected IsLESODLimit :boolean= false;
  protected IsCalcTSC1_round:boolean=false;
  AbNWbcTSC1 :number;
  AbNWbcMotF2: number;    
  // Basic MSC.
  protected MSCVel: number;
  // The COUNT after recalculation because of a 1:1 dilution. if not - NewCount=COUNT.
  protected NewCount: number;
  // Preliminary TSC
  protected TSCod:number;
  protected TSC1:number;
  protected MSCperlim: number;
  // For Longevity
  protected InterMPar:FirstRoundPar;

  constructor(SetUp:TestSetup,KeysP: KeyParameters, sampleCounts:number){
    this.SetupConstants= new TestSetup(SetUp.sampleType,SetUp.testType,SetUp.ConcStandard,SetUp.chamberStandard,SetUp.MorphologyCriteria,SetUp.LesFlag,SetUp.Volume,SetUp.DebrisScan,SetUp.control_Level,SetUp.control_Type, SetUp.LBODAmp);
    this.CurrKeyPar= new KeyParameters(KeysP.OD,KeysP.COUNT,KeysP.AV,KeysP.AW);
    this.PerlimKeyPar= new KeyParameters(KeysP.OD,KeysP.COUNT,KeysP.AV,KeysP.AW);
    this.TheTestRes=new Results();
    this.NormFactor=NORMAL_TEST_SAMPLES/sampleCounts;
    this.NewCount=KeysP.COUNT;
    this.InterMPar=new FirstRoundPar(-1,-1,-1,-1,-1,-1,-1,-1);
    this.ODAdjusted=this.CurrKeyPar.OD;
    if (this.SetupConstants.ConcStandard==WBCConcStd.WBC_CONC_ABNORMAL)
                this.IsABNormalWbcDebris = true;

     if ((this.SetupConstants.LesFlag==LES.TwoROW) && (this.SetupConstants.sampleType==SAMPLETYPE.FRESH) && ((this.IsABNormalWbcDebris==false &&
                this.CurrKeyPar.OD < 0.454) || (this.IsABNormalWbcDebris==true && this.CurrKeyPar.OD < 0.77)))

        this.IsLESODLimit = true;            
  }
  protected getTSCofDebris():any{
      return this.AbNWbcTSC1;
  }
  protected AdjustOD():void
    {
        //  if (SetupConstants.ConcStandard == WBCConcStd.WBC_CONC_ABNORMAL)
        if (this.IsABNormalWbcDebris)
            this.ODAdjusted = 0.5132 * this.ODAdjusted + 0.1262;
        //else
            //this.ODAdjusted = this.RawDataResults.OD;
    }
  protected calcManMorph():void{
      
  }
  
  protected CheckVeryLowQuality():boolean
    {
        let TSC:number = this.TSC1;
        let MSC:number = this.TheTestRes.MSC;

        if ((TSC < 2.0 && MSC < 2.0) || (TSC >= 2.0 && MSC < 0.2) || (TSC < 2.0 && MSC >= 0.2))
        {
            if (TSC < 2.0)
                this.TheTestRes.TSC = -3; // < 2.0	

            if (MSC < 0.2)
            {
                this.TheTestRes.MSC = -2;  // <0.2

                //ClearMotilityParameters();
                //ClearAllTotalResults();
            }
            return (true);
        }
        return (false);
    }
  protected CalcStdCoeff(){
    
     switch(this.SetupConstants.chamberStandard){
       case ChamberStd.MAKLER:
           return 1.2;
        case ChamberStd.NEUBAUER:
           return 1.6;
        default:
           return 1.0;

     }
  }

  protected CheckIfMSCGreaterThenTSC():boolean
  {
    if ((this.TheTestRes.MSC > this.TSCod) && this.TheTestRes.TSC > 5 && this.TheTestRes.MSC > 5)
            return (true);
        else
            return (false);
  }
  protected LimitDataByUsingCount( MaxData:number, Data:number,divideby:number):number
  {
      //return ((double)MaxData + (Data / 250.0));
      return (MaxData + (Data / divideby));
  }
  protected NormalizeCount(){
     this.CurrKeyPar.COUNT = this.NormFactor * this.CurrKeyPar.COUNT;
   }

   protected NormalizeAverWidth():void{
     
      if(this.IsAverWidthNormalized){
         
          this.CurrKeyPar.AW= round(this.NormFactor*this.CurrKeyPar.AW);
        
      }
   }
   protected  ReCalcLQTSC():void
    {
        if (this.NewCount < 260) //InterMOT1
            this.TheTestRes.Motility = -0.0003 * this.NewCount * this.NewCount + 0.226 * this.NewCount + 35.957;
        else
            this.TheTestRes.Motility = 0.21 * this.NewCount + 20;
        //InterMOT2
        if (this.TheTestRes.Motility > 95)
            this.TheTestRes.Motility = this.LimitDataByUsingCount(95, this.NewCount,250);

        //TSC1
        if (this.TheTestRes.Motility == 0)
            this.TheTestRes.TSC = 0;
        else
            this.TheTestRes.TSC = (this.TheTestRes.MSC * 100) / (this.TheTestRes.Motility+0.001);   //
    }
   protected UpdateIfMSCGreaterThenTSC():void
   {
            if ((this.TheTestRes.MSC > this.TheTestRes.TSC) && this.TheTestRes.TSC > 5 && this.TheTestRes.MSC > 5)
                this.ReCalcLQTSC();
   }
   protected CalculateSMI():void
   {
      
      let  x :number = this.NewCount;
      let y:number=4;
      

       if (x <= 105)
           this.TheTestRes.SMI = (-0.00000008 * XYpow(x,4) +0.00006 * XYpow(x, 3) -0.0136 * XYpow(x, 2) + 1.2243 * x) * 0.7;
       else
           this.TheTestRes.SMI = (0.000006 * XYpow(x, 3) -0.0003 * XYpow(x, 2) + 0.3274 * x) * 0.7;

       // this.TheTestRes.SMI = (short)Math.Round(temp);

   }

        protected CalcBasicMSC():void
        {
            let Avg:number = this.CurrKeyPar.AV;

            if (Avg <= 75)
                this.MSCVel = 0.0002 * XYpow(Avg, 3) - 0.019 * XYpow(Avg, 2) + 1.4366 * Avg;
            else
                this.MSCVel = 0.0053 *XYpow(Avg, 2) + 0.8077 * Avg;

            this.TheTestRes.MSC = this.MSCVel;
            console.log("After bacic MSC calculation: "+this.TheTestRes.MSC );

        }

        protected  CheckAverWidth()
        {
            let AW:number= this.CurrKeyPar.AW;
            if (AW <= 3400 || this.MSCVel <= 1)
            {
                if (AW > 3400)
                    AW = 3400;
                //if(this.RawDataResults.TestRound==2) // need to be check!!! main no extended test 
                this.TheTestRes.MSC = (0.000000001 * AW * AW * AW - 0.000004 * AW * AW + 0.0065 * AW) / 15.0;
            }
            this.TheTestRes.MSC += 0.001;//MSCw1
        }

        protected CalcVelocity()
        {
           
            let Msc:number = this.TheTestRes.MSC;
            if (Msc < 0.1)
                this.TheTestRes.Velocity = 0;
            else
            {
                this.TheTestRes.Velocity = 0.6919 * (this.NewCount) / Msc;
                this.TheTestRes.Velocity *= (0.00007 * Msc * Msc + 0.0422 * Msc + 0.4);
                if (this.TheTestRes.Velocity > 35) this.TheTestRes.Velocity = 35;
            }
            console.log("The Velocity after first calc: "+this.TheTestRes.Velocity);
            //this.TheTestRes.Velocity = (double)(long)this.TheTestRes.Velocity;
            //UpdateChamberCoef(ref this.TheTestRes.MSC);
        }


        protected UpdateChamberCoef(Data:number)
        {
             return Data * this.CalcStdCoeff();
        }

        protected RecalcTSCFromMSC()
        {
            if (this.TheTestRes.TSC == 0 || this.TheTestRes.MSC > this.TSCod)
                this.TheTestRes.Motility = 0;
            else
                this.TheTestRes.Motility = (this.TheTestRes.MSC / this.TSCod) * 100; // MOTw

                console.log("After RecalcTSCFROMMSC TSC:"+this.TheTestRes.TSC+"Motility:"+this.TheTestRes.Motility);
        }
       
        protected CalcPMSC():void
        {
           
           let Veloc:number = this.TheTestRes.Velocity;

           let Temp:number = 0.0053 * XYpow(Veloc, 3) - 0.4235 *XYpow(Veloc, 2) + 10.933 * Veloc;
          // console.log("intermadate Temp "+Temp+ "Veloc: "+Veloc);
            let PMSC1:number = this.TheTestRes.MSC * Temp / 100;
           // console.log("intermadate 1 PMSC1 "+PMSC1);
           // if (this.RawDataResults.DebrisScan.Equals(Debris_Scan.MODERATE) || this.RawDataResults.DebrisScan.Equals(Debris_Scan.HIGH))
            if (this.SetupConstants.DebrisScan != Debris_Scan.LOW)
            {
                PMSC1 *= 1.169;
               // console.log("intermadate 2 PMSC1 "+PMSC1);
                if (PMSC1 >= 0.9 * this.TheTestRes.MSC)
                    PMSC1 = 0.9 * this.TheTestRes.MSC;
            }
         
            this.TheTestRes.PMSC = PMSC1; 
            //console.log("intermadate PMSC "+this.TheTestRes.PMSC+" use MSC: "+this.TheTestRes.MSC+ "Veloc: "+Veloc);
        }

        
        protected CalcPMSCm(Mscm:number)
        {
            let Veloc:number = this.TheTestRes.Velocity;
            let Temp:number = 0.0053 * Veloc * Veloc * Veloc - 0.4235 * Veloc * Veloc + 10.933 * Veloc;

            let Pmscm:number = Mscm * Temp / 100;
            let x:number = 0.95 * Mscm;
            if (Pmscm > x)
                Pmscm = x;

            console.log("Pmscm: "+Pmscm);

            return (Pmscm);
        }
        
        protected ReCalcAvg()
        {
            let Coeff:number;
            
            let Avg:number =this.CurrKeyPar.AV; //RawDataResults.Average;

            if (Avg >= 110)
                Coeff = 1.645;
            else
            {
                if (Avg > 61)
                    Coeff = -0.00003 * Avg * Avg + 0.0068 * Avg + 1.2603;   //change 9.10.11
                else
                {
                    if (Avg >= 12.7 && Avg <= 61)
                        Coeff = -0.0154 * Avg + 2.4939;
                    else
                        Coeff = -0.00000007 * Avg * Avg * Avg * Avg + 0.00002 * Avg * Avg * Avg - 0.0019 * Avg * Avg + 0.0434 * Avg + 1.9756;
                }
            }
            return Coeff;
        }
        
        protected CalcMotilityParameters():void
        {
            
            let x:number;
            if ((this.TheTestRes.TSC < 0) || (this.TheTestRes.MSC < 0))
                return;

            let Veloc:number = this.TheTestRes.Velocity;

            let Temp:number = 0.0053 *XYpow(Veloc, 3) - 0.4235 *XYpow(Veloc, 2) + 10.933 * Veloc;

            this.CalcPMSC();

            //Preli. PMOT a of MSC
            this.TheTestRes.PMota = -0.00002 * XYpow(Veloc, 5) +
                                     0.0024 * XYpow(Veloc, 4) -
                                     0.1056 * XYpow(Veloc, 3) +
                                     1.9873 * XYpow(Veloc, 2) - 10.98 * Veloc;

            //this.TheTestRes.PMota = this.TheTestRes.PMota + 0.0024 * Veloc * Veloc * Veloc * Veloc;
            //this.TheTestRes.PMota = this.TheTestRes.PMota - 0.1056 * Veloc * Veloc * Veloc;
            //this.TheTestRes.PMota = this.TheTestRes.PMota + 1.9873 * Veloc * Veloc - 10.98 * Veloc;

            if (this.TheTestRes.PMota < 0.0)
                this.TheTestRes.PMota = 0.0;  //Prog.Mot "a" of MSC


            let PMSCa:number = (this.TheTestRes.PMota * this.TheTestRes.MSC) / 100.0;

            if (Veloc < 10)
                x = 0.001 *XYpow(Veloc, 3) - 0.0326 * XYpow(Veloc, 2) + 0.3573 * Veloc;
            else
                x = 1.3;

            Temp = x + 0.0003 * XYpow(PMSCa, 3) - 0.0479 * XYpow(PMSCa, 2) + 2.6569 * PMSCa;
            if (Temp < 0)
                Temp = 0;

            PMSCa = Temp;

            x = 0.85 * this.TheTestRes.PMSC;
            if (PMSCa > x)
                PMSCa = x;

            this.TheTestRes.PMSCb = this.TheTestRes.PMSC - PMSCa;

            if ((this.TheTestRes.TSC < 0) || (this.TheTestRes.MSC < 0))
            {
                this.TheTestRes.PMota = -1;
                this.TheTestRes.PMotb = -1;
            }
            else
            {
                Temp = this.TheTestRes.TSC;
                this.TheTestRes.PMota = (PMSCa / Temp) * 100.0;
                this.TheTestRes.PMotb = (this.TheTestRes.PMSCb / Temp) * 100.0;

            }

            // update the latest PMSCa result.
            this.TheTestRes.PMSCa = PMSCa;

            this.TheTestRes.PMot = this.TheTestRes.PMSC * 100 / this.TheTestRes.TSC;
        }
        protected CalcLowQMot():number
        {
            let Res:number = 0.0;

            if (this.NewCount < 260 && this.NewCount > 0)
                Res = -0.0003 * this.NewCount * this.NewCount + 0.226 * this.NewCount + 15.957;
            else
                Res = 0.21 * this.NewCount;

            if (Res > 95)
                Res = this.LimitDataByUsingCount(95, this.NewCount,250);

            if (this.NewCount == 0)
            {
                if (this.CurrKeyPar.AV > 0 && this.CurrKeyPar.AW > 0)
                    Res = 5.0;
                else
                    Res = 0.0;
            }

            return (Res);
        }
        protected UpdateMotility():void
        {
            console.log("The low quility, update is needed");
             // Motility calculated from NewCount.
           // TheTestRes.MotilitySign = 1;  //sign '<'
            let PrelimMot:number = this.CalcLowQMot();  // c - a variable that holds the prelim. motility2 results

            //this.TheTestRes.Velocity = -1; //N.A

            if (this.TheTestRes.TSC == -3 && this.TheTestRes.MSC == -2)
                this.TheTestRes.Motility = PrelimMot;
            else
            {
                if (this.TheTestRes.TSC == -3)
                {

                  //  if (this.TheTestRes.MSC > 5)
                   //     this.TheTestRes.Motility = 0;
                    //else
                        this.TheTestRes.Motility = this.MSCperlim * 50; //VLQ1:Min.MOT1

                    if (this.TheTestRes.Motility >= 90)
                        this.TheTestRes.Motility = this.LimitDataByUsingCount(90, this.NewCount,250);

                    if (PrelimMot > this.TheTestRes.Motility)
                        this.TheTestRes.Motility = PrelimMot;

                   // TheTestRes.MotilitySign = 2;  //sign '>'

                    /*if (this.TheTestRes.MSC > 5)  // MSC>TSC :  MSC>5 and TSC<2 - change MSC to <0.2
                        this.TheTestRes.MSC = -2; */ //MSC<0.2								
                }
                else
                {
                    if (this.TheTestRes.MSC == -2)
                    {
                        this.TheTestRes.Motility = 20 / this.TSC1;  //(0.2/TSC)*100;
                        if (this.TheTestRes.Motility >= 90)
                            this.TheTestRes.Motility = this.LimitDataByUsingCount(90, this.NewCount,250);
                        if (PrelimMot < this.TheTestRes.Motility)
                            this.TheTestRes.Motility = PrelimMot;
                    }
                }
            }

            
        }
        protected CalcMorphology():void
        {
            let Avg :number;
            let TSC:number;
            let PMSCm:number;
            let PMOT:number;

           /* if (this.SetupConstants.ManualMorph >= 0)
            {
                if (this.SetupConstants.MorphologyCriteria.Equals(MorphCriteria.MORPH_WHO5)) 
                     this.TheTestRes.Morphology =Math.Round( this.SetupConstants.ManualMorph,0,MidpointRounding.AwayFromZero);
                else
                     this.TheTestRes.Morphology = Math.Round(this.SetupConstants.ManualMorph, 1, MidpointRounding.AwayFromZero);
                
                return;
            }*/

            if ((this.TheTestRes.TSC < 2) || (this.TheTestRes.MSC == -2))
            {
                this.TheTestRes.Morphology = -1;
                return;
            }

           // if (this.CurrKeyPar.UserCount == false)
           // {
                if (this.SetupConstants.sampleType==SAMPLETYPE.WASHED && this.SetupConstants.testType==TESTTYPE.DILUTED)
                    Avg =this.CurrKeyPar.AV * this.ReCalcAvg();
                else
                    Avg = this.CurrKeyPar.AV;

                //MSCm = 0.0047 * Avg * Avg + 0.869 * Avg;
                //UpdateChamberCoef(ref MSCm);
                //PMSCm = CalcPMSCm(MSCm);
                PMSCm = this.CalcPMSCm(this.TheTestRes.MSC); //was changed 10.7.13
                console.log("PMSCm= "+PMSCm);
                if (this.TheTestRes.TSC == 0)
                    PMOT = 0;
                else
                    PMOT = PMSCm / this.TheTestRes.TSC * 100;
                
                    console.log("Pmot in morph calc: "+PMOT);    
           // }
            /*else
            {
                double Temp, Veloc;

                Veloc = this.TheTestRes.Velocity;

                Temp= 0.0053 * Math.Pow(Veloc, 3) - 0.4235 * Math.Pow(Veloc, 2) + 10.933 * Veloc;//ProgMotofMSC
                if (this.TheTestRes.TSC == 0)
                    PMOT = 0;
                else
                    PMOT = Temp * this.TheTestRes.MSC / this.TheTestRes.TSC;

            }*/
          //  if(this.SetupConstants.ConcStandard.Equals(WBCConcStd.WBC_CONC_ABNORMAL))
                TSC=this.TheTestRes.TSC;
           // else
             //   TSC=this.TSC5;

            this.TheTestRes.Morphology = (0.4 * PMOT + (10.438 * TSC / (TSC + 0.02))) * 1.4;

            // Get kind of morphology

            if (this.SetupConstants.MorphologyCriteria != MorphCriteria.MORPH_WHO3)
            {
                this.TheTestRes.Morphology = (0.0076 * this.TheTestRes.Morphology * this.TheTestRes.Morphology + 0.0739 * this.TheTestRes.Morphology) * 0.9 ;

               let morph:number = this.TheTestRes.Morphology;


                if (morph >= 25)
                    morph /= 1.07;
                else if (morph > 20)
                    morph /= 1.15;
                else if (morph >= 10)
                    morph /= 1.25;
                else
                    morph /= 1.5;

                console.log("Morph in case who 4 or 5: "+morph);

                if (this.SetupConstants.MorphologyCriteria== MorphCriteria.MORPH_WHO4)
                    this.TheTestRes.Morphology = round_float(morph,1);//Math.Round(morph, 1, MidpointRounding.AwayFromZero);
                else
                    this.TheTestRes.Morphology = round(morph); //Math.Round(morph, 0, MidpointRounding.AwayFromZero);
            }
            if (this.TheTestRes.Morphology > 90) this.TheTestRes.Morphology = 90.0;
            if (this.TheTestRes.MSC < 0.1 || this.TheTestRes.PMSC < 0.1)
                this.TheTestRes.Morphology = -1; //N.A
        }

        protected AdditionalCalculations():void
        {
            if (this.TheTestRes.TSC < 0 || this.TheTestRes.MSC < 0)
               return;

            if (this.SetupConstants.MorphologyCriteria == MorphCriteria.MORPH_WHO5)
            {
                if (this.TheTestRes.PMot >= 0)
                {
                    this.TheTestRes.NonProgMot =round(this.TheTestRes.Motility) - round(this.TheTestRes.PMot);
                    if (this.TheTestRes.NonProgMot < 0)
                        this.TheTestRes.NonProgMot = 0.0;
                }
                this.TheTestRes.Immot = 100.0 -round(this.TheTestRes.Motility);
            }
            else
            {
                if (this.TheTestRes.PMota >= 0 && this.TheTestRes.PMotb >= 0)
                {
                    if (this.SetupConstants.MorphologyCriteria == MorphCriteria.MORPH_WHO6){
                        
                        this.TheTestRes.NonProgMot =round(this.TheTestRes.Motility) - round(this.TheTestRes.PMota)- round(this.TheTestRes.PMotb);
                        this.TheTestRes.Immot = 100.0 -round(this.TheTestRes.Motility);
                    }
                    else{
                        this.TheTestRes.NonProgMot = round_float(this.TheTestRes.Motility, 1) - round_float(this.TheTestRes.PMota, 1) - round_float(this.TheTestRes.PMotb, 1);
                        this.TheTestRes.Immot = 100.0 - round_float(this.TheTestRes.Motility, 1);
                    }
                        if (this.TheTestRes.NonProgMot < 0)
                        this.TheTestRes.NonProgMot = 0.0;
                }
                
            }

            
            //if (this.TheTestRes.NonProgMot < 0)
               // this.TheTestRes.NonProgMot = 0.0;
            //this.TheTestRes.Immot = 100.0 - this.TheTestRes.Motility;
        }

        protected CalcFSC()
        {
           
            let Morph:number = this.TheTestRes.Morphology;
           /* if( this.RawDataResults.UserCount == true ||(this.SetupConstants.ManualMorph >= 0 && (this.TheTestRes.TSC==-3 ||this.TheTestRes.MSC==-2)))
            {
                this.TheTestRes.FSC = -4;
                return;
            }*/

            if (Morph == -1 ) return;

            let FSC:number = this.TheTestRes.FSC;
            FSC = -0.000003 * XYpow(Morph, 3) + 0.0006 * XYpow(Morph, 2) - 0.048 * Morph + 2.7;
            if (this.TheTestRes.MSC < 0 || this.TheTestRes.PMSC < 0)
            {
                this.TheTestRes.FSC = -1; //N.A 
            }
            else
            {
                let PMSC1:number = this.TheTestRes.PMSC;// CalcPMSC();
                FSC = FSC * PMSC1 * Morph / 100.0;
                //FSC = FSC * this.TheTestRes.PMSC * Morph / 100.0;
                // if (FSC > (0.9 * this.TheTestRes.PMSC))
                //    FSC = 0.9 * this.TheTestRes.PMSC;

                if (FSC < 0)
                    FSC = 0.0;

                this.TheTestRes.FSC = FSC;
            }
        }

       
        protected CalcTotalMSCPMSC():void
        {
           
            let Volume:number = this.SetupConstants.Volume;
            if (Volume >= 0.1 && this.TheTestRes.MSC>=0)
            {
                this.TheTestRes.TotalMSC = this.TheTestRes.MSC * Volume;
                if (this.TheTestRes.PMSC>=0)
                        this.TheTestRes.TotalPMSC = this.TheTestRes.PMSC * Volume;
            }
        }

        protected CalcTotals():void
        {
            if (this.TheTestRes.TSC < 0/* && this.SetupConstants.ManualMorph < 0*/) return;

           // if (this.SetupConstants.ConcStandard.Equals(WBCConcStd.WBC_CONC_ABNORMAL))
             let TSC:number = this.TheTestRes.TSC;
           // else
            //    TSC = this.TSC5;

            let Volume:number = this.SetupConstants.Volume;
            if (Volume >= 0.1)
            {
                // All Sperm

                this.TheTestRes.TotalTSC =(TSC>=0)? TSC * Volume: -1;
                
                this.CalcTotalMSCPMSC();

                if (this.TheTestRes.Morphology != -1 && this.TheTestRes.Morphology != -4)
                {
                    if (this.TheTestRes.FSC >= 0)
                        this.TheTestRes.TotalFSC = this.TheTestRes.FSC * Volume;
                    else
                        this.TheTestRes.TotalFSC = this.TheTestRes.FSC;

                   /* if (this.RawDataResults.UserCount == true && this.SetupConstants.ManualMorph >= 0)
                        this.TheTestRes.TotalMorphNorm = -4;
                    else*/if(this.TheTestRes.TotalTSC>=0)
                               this.TheTestRes.TotalMorphNorm = this.TheTestRes.TotalTSC * this.TheTestRes.Morphology / 100;
                }
            }
        }

        protected CalcTotalsForManMorph():void
        {
           let Volume:number = this.SetupConstants.Volume;
            if (Volume <= 0)
                this.TheTestRes.TotalFSC = -1;
            else if (this.TheTestRes.FSC >= 0)
                this.TheTestRes.TotalFSC = this.TheTestRes.FSC * Volume;
            else
                this.TheTestRes.TotalFSC = this.TheTestRes.FSC;

            if (this.TheTestRes.TotalTSC >= 0)
                this.TheTestRes.TotalMorphNorm = this.TheTestRes.TotalTSC * this.TheTestRes.Morphology / 100;

        }
        
        protected Debris_TSC1andMotF2():void
        {
            let NormalWbcResults_Super :Results;
            let  ABNormalWbcSetUp :TestSetup = new TestSetup(this.SetupConstants.sampleType,this.SetupConstants.testType,WBCConcStd.WBC_CONC_ABNORMAL, 
                                                           this.SetupConstants.chamberStandard,this.SetupConstants.MorphologyCriteria,this.SetupConstants.LesFlag,
                                                           this.SetupConstants.Volume, this.SetupConstants.DebrisScan);
            let Keys:KeyParameters =new KeyParameters (this.PerlimKeyPar.OD,this.PerlimKeyPar.COUNT,this.PerlimKeyPar.AV,this.PerlimKeyPar.AW);
            
           /* let ABNormalTSC:number;
            let ABNormalWbcTest :NormalFreshTest;
           let ABNormalWbcResults:Results=new Results();
          
            let ABNormalWbcSetUp =this.SetupConstants;// this.SetupConstants;
            ABNormalWbcSetUp.ConcStandard = WBCConcStd.WBC_CONC_ABNORMAL;*/
          
          // ABNormalWbcTest=new NormalFreshTest(ABNormalWbcSetUp,this.PerlimKeyPar,NORMAL_TEST_SAMPLES/this.NormFactor);
           // ABNormalWbcTest = TestResultsFactory.GetMinimalRes(ABNormalWbcSetUp, this.perlimRawData, (uint)(NORMAL_TEST_SAMPLES / this.NormFactor));
           let newservice:AlgorithmService= new AlgorithmService();
           newservice.SetTestParameters(ABNormalWbcSetUp,Keys,NORMAL_TEST_SAMPLES/this.NormFactor);
           newservice.setRecalForDebris();
          //  ABNormalWbcTest.IsCalcTSC1_round = true;
           // ABNormalWbcTest.PerformTest();
          //  ABNormalWbcResults = ABNormalWbcTest.TheTestRes;
          let ABNormalWbcResults :Results =newservice.calculateResults();
           // if (this.SetupConstants.TheTestType.Equals(TestType.DILUTED)) //added 27.6.13
           let ABNormalTSC = newservice.getTSCofDebrisRound();// ABNormalWbcResults.//ABNormalWbcTest.AbNWbcTSC1;
           // else
             //   ABNormalTSC = ABNormalWbcResults.TSC;
      
            //ABNMotility=ABNormalWbcResults.Motility;
            this.AbNWbcMotF2 = ABNormalWbcResults.Motility;
            
            if (this.TheTestRes.TSC < 0) return;
            
            if (this.SetupConstants.DebrisScan==Debris_Scan.MODERATE)
            {
                this.TheTestRes.TSC = 0.5 * this.TheTestRes.TSC + 0.5 * ABNormalTSC;
               // this.TheTestRes.Motility = 0.75 * this.TheTestRes.Motility + 0.25 * ABNMotility;
            }
            else if(this.SetupConstants.DebrisScan==Debris_Scan.HIGH)
            {
                this.TheTestRes.TSC = 0.25 * this.TheTestRes.TSC + 0.75 * ABNormalTSC;
               // this.TheTestRes.Motility = 0.5 * this.TheTestRes.Motility + 0.5 * ABNMotility;
            }
            else
            {
                this.TheTestRes.TSC = ABNormalTSC;
            }
        }
        protected CalcMotNoramlWbc():number
        {
            //TestResults NormalWbcTest;
           // Results NormalWbcResults;
           let NormalWbcResults_Super :Results;
            let  NormalWbcSetUp :TestSetup = new TestSetup(this.SetupConstants.sampleType,this.SetupConstants.testType,WBCConcStd.WBC_CONC_NORMAL, 
                                                           this.SetupConstants.chamberStandard,this.SetupConstants.MorphologyCriteria,this.SetupConstants.LesFlag,
                                                           this.SetupConstants.Volume, this.SetupConstants.DebrisScan);
            //NormalWbcSetUp = this.SetupConstants;
           // NormalWbcSetUp.ConcStandard = WBCConcStd.WBC_CONC_NORMAL;
            let Keys:KeyParameters =new KeyParameters (this.PerlimKeyPar.OD,this.PerlimKeyPar.COUNT,this.PerlimKeyPar.AV,this.PerlimKeyPar.AW);
           
            if(this.SetupConstants.DebrisScan==Debris_Scan.GROSS)
                NormalWbcSetUp.DebrisScan=Debris_Scan.LOW;
           // if (this.perlimRawData.DebrisScan.Equals(Debris_Scan.GROSS))
           //     Keys.DebrisScan = Debris_Scan.LOW;
           
           let newservice:AlgorithmService= new AlgorithmService();
           newservice.SetTestParameters(NormalWbcSetUp,Keys,NORMAL_TEST_SAMPLES/this.NormFactor);
           let NormalWbcResults :Results =newservice.calculateResults();

            // let NormalWbcTest:NormalFreshTest = new NormalFreshTest(NormalWbcSetUp, Keys,NORMAL_TEST_SAMPLES/this.NormFactor);
          //  NormalWbcTest.PerformTest();
          // let NormalWbcResults :Results= NormalWbcTest.TheTestRes;
      
            //return NormalWbcResults.Motility;
            return NormalWbcResults.NWbcMotF3;
        }
        protected CalcPMSCAndUpdate() //changed to virtual on 6.10.11
        {
            let TempMot:number;
            if (this.SetupConstants.ConcStandard==WBCConcStd.WBC_CONC_NORMAL && this.SetupConstants.DebrisScan != Debris_Scan.LOW /* && this.TheTestRes.TSC >= 0*/)
            {
                // Debris_TSC1andMotF2(); // //when wbc=normal calculate TSC and motility according to debris level
                if (this.SetupConstants.DebrisScan==Debris_Scan.MODERATE)
                {

                    this.TheTestRes.Motility = 0.75 * this.TheTestRes.Motility + 0.25 * this.AbNWbcMotF2;
                }
                else if (this.SetupConstants.DebrisScan==Debris_Scan.HIGH)
                {

                    this.TheTestRes.Motility = 0.5 * this.TheTestRes.Motility + 0.5 * this.AbNWbcMotF2;
                }
                else
                {
                    this.TheTestRes.NWbcMotF3 = this.TheTestRes.Motility;
                    this.TheTestRes.Motility = this.AbNWbcMotF2/1.065;  //new 31.1.17
                }

                this.TheTestRes.Motility *= 1.065;
             
                

            }

            if (this.SetupConstants.testType == TESTTYPE.DILUTED && this.SetupConstants.ConcStandard==WBCConcStd.WBC_CONC_NORMAL &&
                 this.SetupConstants.DebrisScan != Debris_Scan.GROSS) { 
                this.TheTestRes.Motility /= 1.05;
            }
            if(this.SetupConstants.DebrisScan != Debris_Scan.GROSS)    
                 this.TheTestRes.NWbcMotF3 = this.TheTestRes.Motility;
            
            //CalcPMSC();
            if (this.SetupConstants.ConcStandard==WBCConcStd.WBC_CONC_ABNORMAL)
            {
                    TempMot = this.CalcMotNoramlWbc();
                    if (TempMot < 0)
                        this.TheTestRes.Motility = -1;
                    else
                        this.TheTestRes.Motility = (TempMot + this.TheTestRes.Motility*1.065) / 2;
            }

            if (this.IsCalcTSC1_round == true)
                return;

            //Final motility
            if (this.TheTestRes.Motility > 100)  //changed 20.11.14
                   this.TheTestRes.Motility = 100;
                //this.TheTestRes.Motility = LimitDataByUsingCount(95, this.RawDataResults.Count, 200);

            this.InterMPar.TSC4 = this.TheTestRes.TSC;
            //FinalMSC
           /* if (this.RawDataResults.UserCount == true)
                this.TheTestRes.MSC = this.TheTestRes.TSC * this.TheTestRes.Motility / 100; //FinalMSC
            else
            {*/
                if (this.MSCperlim < 0.2)
                {
                    this.TheTestRes.MSC = -2;
                    this.TheTestRes.Velocity = -1;
                    return;
                }
                else if (this.TheTestRes.TSC == -3)
                {

                    this.TheTestRes.MSC = this.MSCperlim;
                    this.TheTestRes.Velocity = -1;
                    return;
                }
                else
                {
                    if (this.TheTestRes.Motility < 0 || this.TheTestRes.TSC < 0)
                        this.TheTestRes.MSC = -1;
                    else
                        this.TheTestRes.MSC = this.TheTestRes.TSC * this.TheTestRes.Motility / 100;//FinalMSC
                }
          //  }

            if (this.TheTestRes.MSC < 0.1)  //added 04.08.14
                this.TheTestRes.Velocity = 0;


            this.CalcMotilityParameters();
            //this.TheTestRes.PMot = this.TheTestRes.PMSC * 100 / this.TheTestRes.TSC;
            //this.TheTestRes.Motility = this.TheTestRes.MSC * 100 / this.TheTestRes.TSC;
            //CalcTotalMSCPMSC();
        }
        protected updateMotilitySignal()
        {
            if (this.TheTestRes.Motility <= 0)
            {
                this.TheTestRes.MotilitySign = 0;
                return;
            }

            if ((this.TheTestRes.TSC == -3 && this.TheTestRes.MSC == -2) || (this.TheTestRes.MSC == -2))
               this.TheTestRes.MotilitySign = 1;
            else if (this.TheTestRes.TSC == -3)
              this. TheTestRes.MotilitySign = 2;
            else
               this.TheTestRes.MotilitySign = 0;
            

        }

        protected LV_SignAsEmpty()
        {
            this.TheTestRes.TSC = -4;
            this.TheTestRes.Motility = -4;
            this.TheTestRes.Immot = -4;
            this.TheTestRes.NonProgMot = -4;
            this.TheTestRes.Morphology = -4;
            this.TheTestRes.FSC = -4;
            this.TheTestRes.TotalTSC = -4;
            this.TheTestRes.TotalFSC = -4;
            this.TheTestRes.TotalMorphNorm = -4;

            if (this.SetupConstants.MorphologyCriteria==MorphCriteria.MORPH_WHO5)
            {
                this.TheTestRes.PMot = -4;
               

            }
            else
            {
                this.TheTestRes.PMota = -4;
                this.TheTestRes.PMotb = -4;
                

            }
          

        }

       /* protected LVParameterUsingMotInput()
        {
            if (this.TheTestRes.MSC < 0.2 || this.RawDataResults.LVInput.Equals(false)) return;

            this.TheTestRes.TSC = this.TheTestRes.MSC / RawDataResults.LVMotilityInput * 100;
            if (this.TheTestRes.TSC > 500)
            {
                this.TheTestRes.TSC = -500;
                return;
            } 
            this.TheTestRes.Motility = this.RawDataResults.LVMotilityInput;
            if (this.SetupConstants.MorphologyCriteria.Equals(MorphCriteria.MORPH_WHO5))
            {
                this.TheTestRes.PMot = this.TheTestRes.PMSC / this.TheTestRes.TSC * 100;
                this.TheTestRes.NonProgMot = Math.Round(this.TheTestRes.Motility, 0, MidpointRounding.AwayFromZero) - Math.Round(this.TheTestRes.PMot, 0, MidpointRounding.AwayFromZero);
                this.TheTestRes.Immot = 100.0 - Math.Round(this.TheTestRes.Motility, 0, MidpointRounding.AwayFromZero);
                
            }
            else
            {
                this.TheTestRes.PMota = this.TheTestRes.PMSCa / this.TheTestRes.TSC * 100;
                this.TheTestRes.PMotb = this.TheTestRes.PMSCb / this.TheTestRes.TSC * 100;
                this.TheTestRes.NonProgMot = Math.Round(this.TheTestRes.Motility, 1, MidpointRounding.AwayFromZero) - Math.Round(this.TheTestRes.PMota, 1, MidpointRounding.AwayFromZero) - Math.Round(this.TheTestRes.PMotb, 1, MidpointRounding.AwayFromZero);
                this.TheTestRes.Immot = 100.0 - Math.Round(this.TheTestRes.Motility, 1, MidpointRounding.AwayFromZero);

            }
            if (this.SetupConstants.Volume >= 0)
                this.TheTestRes.TotalTSC = this.TheTestRes.TSC * this.SetupConstants.Volume;

        }*/
        protected CalcTSC()
        {
            return;
        }
        
        protected FinalRes()
        {
            return;
        }
        protected RecalcFreshWashedDiluted()
        {
            return;
        }
        setRecalcDebri(isRecalc:boolean):void{
            this.IsCalcTSC1_round=isRecalc;
        }
        PerformTest():void
        { 
          
            this.NormalizeCount();
            this.NormalizeAverWidth();
            this.CalculateSMI();
           /* if (this.RawDataResults.UserCount == true)
            {
                this.CalcLowQuality();
                this.InterMPar.TSC4 = this.TheTestRes.TSC;
                this.InterMPar.Morph = this.TheTestRes.Morphology;
                return;
            }*/
            this.AdjustOD();
            this.CalcTSC();
            this.CalcBasicMSC();
            this.CheckAverWidth();
            this.CalcVelocity();
            this.TheTestRes.MSC=this.UpdateChamberCoef( this.TheTestRes.MSC);//MSCw2
            // CalcInternalMot();
            this.RecalcTSCFromMSC();

            if (this.CheckIfMSCGreaterThenTSC() == true)
                this.UpdateIfMSCGreaterThenTSC();
            else
                this.FinalRes();
            //CalcMotilityParameters();


            //InterMot3
            if (this.TSCod <= 5 && this.TheTestRes.MSC < this.TheTestRes.TSC) //Low quality 
                this.TheTestRes.Motility = this.TheTestRes.MSC / this.TheTestRes.TSC * 100;
            this.TheTestRes.Motility += 0.001;

            this.TheTestRes.TSC += 0.001; //TSC1
            
            if (this.SetupConstants.testType == TESTTYPE.DILUTED) //added on 2.8.16
                this.TheTestRes.TSC /= 1.1;

            this.TSC1 = this.TheTestRes.TSC;
            this.InterMPar.TSC1 = this.TSC1;

            //TSC1 is needed for calculate  fial TSC in case of debris moderate or high(wbc-normal 
            if (this.IsCalcTSC1_round == true)
                this.AbNWbcTSC1 = this.TheTestRes.TSC;
                
            
            if (this.TheTestRes.TSC < 2)
                this.TheTestRes.TSC = -3;
            this.InterMPar.TSC2 = this.TheTestRes.TSC;

            if (this.SetupConstants.ConcStandard==WBCConcStd.WBC_CONC_NORMAL && this.SetupConstants.DebrisScan != Debris_Scan.LOW /*&& this.TheTestRes.TSC >= 0*/)
            {
                this.Debris_TSC1andMotF2(); //when wbc=normal calculate TSC and motility according to debris level
            }
            this.InterMPar.TSC3 = this.TheTestRes.TSC;

            //TSC4
           /* if (this.RawDataResults.UserCount == true)
            {            //Manual count
                this.CalcLowQuality();
                this.InterMPar.TSC4 = this.TheTestRes.TSC;
                this.TheTestRes.Velocity = -1;
                return;
            }
            else*/ if (this.TheTestRes.TSC < 2) this.TheTestRes.TSC = -3;

            this.InterMPar.TSC4 = this.TheTestRes.TSC;


            this.RecalcFreshWashedDiluted();//added 2.4.12
            //MSCperlim
            if (this.TheTestRes.TSC >= 2)
            {
                if (this.SetupConstants.testType != TESTTYPE.DILUTED)
                    this.MSCperlim = this.TheTestRes.TSC * this.TheTestRes.Motility / 100;
            }
            else if (this.TheTestRes.MSC < 2)
                this.MSCperlim = this.TheTestRes.MSC;
            else
                this.MSCperlim = 0;

           
            if (this.CheckVeryLowQuality() == true)        
                    this.UpdateMotility();
               

            // FinalMot2

            if (this.TheTestRes.TSC >= 2 && this.MSCperlim >= 2)
            {
               // this.TheTestRes.Motility = this.MSCperlim / this.TSC1 * 100;
                this.TheTestRes.Motility = this.MSCperlim / this.TheTestRes.TSC * 100;
                if (this.TheTestRes.Motility >= 95)
                    this.TheTestRes.Motility = this.LimitDataByUsingCount(95, this.CurrKeyPar.COUNT,250);

            }
               /* if (this.TheTestRes.TSC > 500)
                {
                    this.TheTestRes.InitResults();
                    this.TheTestRes.TSC = -500;
                    this.TheTestRes.MSC = -1;
                    return;
                }*/
            
            //TSC1 is needed for calculate  final TSC in case of debris moderate or high(wbc-normal 
            if (this.IsCalcTSC1_round == true && this.SetupConstants.DebrisScan != Debris_Scan.GROSS  )
                return;

           // this.TSC5 = this.TheTestRes.TSC;

            this.CalcPMSCAndUpdate();

            if (this.IsCalcTSC1_round == true)
                return;

            this.updateMotilitySignal(); //added 31.3.14

            this.AdditionalCalculations();
            this.CalcMorphology();
            this.InterMPar.Morph = this.TheTestRes.Morphology;
            this.CalcFSC();

            if (this.TheTestRes.TSC < 0 || this.TheTestRes.MSC < 1 )
                this.TheTestRes.Velocity = -1;
            else
            {
                if (this.TheTestRes.Velocity >= 1)
                    this.TheTestRes.Velocity *= 3.2;
                else
                    this.TheTestRes.Velocity = -10;  //when velocity is smaller than 1 then it's written "<1" 
            }

            //CalcFSC();
            this.CalcTotals();

            if (this.TheTestRes.TSC > 500)
            {
                this.TheTestRes.TSC_Raw=this.TheTestRes.TSC;
                this.TheTestRes.TSC = -500;
                this.TheTestRes.SMI = -1;
            }

           // this.RawDataResults = this.perlimRawData;
        

        }
        

}

class NormalFreshTest extends TestResult{

  TempMot :number;
  CurrSampleCount: number;
  constructor(SetUp:TestSetup,KeysP: KeyParameters, sampleCounts:number/*,numReads:number*/) {super(SetUp,KeysP, sampleCounts); this.CurrSampleCount=sampleCounts}

  protected CalcMotNoramlWbc():number
  {
      //TestResults NormalWbcTest;
     // Results NormalWbcResults;
      let  NormalWbcSetUp :TestSetup = new TestSetup(this.SetupConstants.sampleType,this.SetupConstants.testType,WBCConcStd.WBC_CONC_NORMAL, 
                                                     this.SetupConstants.chamberStandard,this.SetupConstants.MorphologyCriteria,this.SetupConstants.LesFlag,
                                                     this.SetupConstants.Volume, this.SetupConstants.DebrisScan);
      //NormalWbcSetUp = this.SetupConstants;
     // NormalWbcSetUp.ConcStandard = WBCConcStd.WBC_CONC_NORMAL;
      let Keys:KeyParameters =new KeyParameters (this.PerlimKeyPar.OD,this.PerlimKeyPar.COUNT,this.PerlimKeyPar.AV,this.PerlimKeyPar.AW);
      
      if(this.SetupConstants.DebrisScan==Debris_Scan.GROSS)
           NormalWbcSetUp.DebrisScan=Debris_Scan.LOW;
     // if (this.perlimRawData.DebrisScan.Equals(Debris_Scan.GROSS))
     //     Keys.DebrisScan = Debris_Scan.LOW;

       let NormalWbcTest:NormalFreshTest = new NormalFreshTest(NormalWbcSetUp, Keys,this.CurrSampleCount);
      NormalWbcTest.PerformTest();
      let NormalWbcResults :Results= NormalWbcTest.TheTestRes;

      //return NormalWbcResults.Motility;
      return NormalWbcResults.NWbcMotF3;
  }
   
  protected  CalcPMSCAndUpdate():void //
  {
      let TempMot:number;
     // InterMPar.TSC5 = this.TheTestRes.TSC;        
     

      // Motility Final
      if (this.SetupConstants.ConcStandard==WBCConcStd.WBC_CONC_NORMAL && this.SetupConstants.DebrisScan != Debris_Scan.LOW )
      {
         
          if (this.SetupConstants.DebrisScan ==Debris_Scan.MODERATE)
          {
              
               this.TheTestRes.Motility = 0.75 * this.TheTestRes.Motility + 0.25 * this.AbNWbcMotF2;
          }
          else if (this.SetupConstants.DebrisScan == Debris_Scan.HIGH)
          {
              
               this.TheTestRes.Motility = 0.5 * this.TheTestRes.Motility + 0.5 * this.AbNWbcMotF2;
          }
          else
          {
              this.TheTestRes.NWbcMotF3 = this.TheTestRes.Motility;
              this.TheTestRes.Motility = this.AbNWbcMotF2 / 1.065;
          }

          this.TheTestRes.Motility *= 1.065;
         
      }

     if (this.SetupConstants.DebrisScan != Debris_Scan.GROSS)
            this.TheTestRes.NWbcMotF3 = this.TheTestRes.Motility;

      //when WBC is abnormal then motility is average between motility results when it normal and abnormal
      if ( this.SetupConstants.ConcStandard == WBCConcStd.WBC_CONC_ABNORMAL)
      {
          TempMot = this.CalcMotNoramlWbc();

          if (TempMot < 0)
              this.TheTestRes.Motility = 0;
          else
            this.TheTestRes.Motility = (TempMot + this.TheTestRes.Motility*1.065) / 2;
      }

     if (this.IsCalcTSC1_round == true)
          return;


      // Motility Final
      if (this.TheTestRes.Motility > 100)
             this.TheTestRes.Motility = 100;   //changed 20.11.14
          //this.TheTestRes.Motility = LimitDataByUsingCount(95, this.RawDataResults.Count, 200);

      this.InterMPar.TSC4 = this.TheTestRes.TSC;

      //this.TheTestRes.MSC = this.TheTestRes.TSC * this.TheTestRes.Motility / 100;//FinalMSC
     /* if (this.RawDataResults.UserCount == true)
          this.TheTestRes.MSC = this.TheTestRes.TSC * this.TheTestRes.Motility / 100;
      else
      {*/
          if (this.MSCperlim < 0.2)
          {
              this.TheTestRes.MSC = -2;
              this.TheTestRes.Velocity = -1;
              return;
          }
          else if (this.TheTestRes.TSC == -3)
          {
              this.TheTestRes.MSC = this.MSCperlim;
              this.TheTestRes.Velocity = -1;
              return;
          }
          else
              this.TheTestRes.MSC = this.TheTestRes.TSC * this.TheTestRes.Motility / 100;//FinalMSC
     // }
     // updateMotilitySignal(); //added 31.3.14

      if (this.TheTestRes.MSC < 0.1) //added 04.08.14
              this.TheTestRes.Velocity = 0;

      this.CalcMotilityParameters();
      if( this.TheTestRes.TSC>0){
          this.TheTestRes.Motility = this.TheTestRes.MSC * 100 / this.TheTestRes.TSC;
          if (this.TheTestRes.PMSC >= 0)
              this.TheTestRes.PMot = this.TheTestRes.PMSC * 100 / this.TheTestRes.TSC;
      }
      //this.TheTestRes.Motility = this.TheTestRes.MSC * 100 / this.TheTestRes.TSC;
      //CalcTotalMSCPMSC();
  }

  protected CalcTSC():void
  {
      //this.ODAdjusted = RawDataResults.OD;   //added 18.9.11
      let adjOD:number = this.ODAdjusted;

      if (this.IsLESODLimit)
      {
          if (!this.IsABNormalWbcDebris)
              this.TheTestRes.TSC = 23.717 * this.CurrKeyPar.OD- 3.5509;
          else
              this.TheTestRes.TSC = 23.656 * this.CurrKeyPar.OD - 5.8405;
      }
      else
          this.TheTestRes.TSC = 114.18 * adjOD * adjOD - 35.883 * adjOD;

      this.TheTestRes.TSC *= this.CalcStdCoeff();//Perlim.TSC
      this.InterMPar.PerlimTSC = this.TheTestRes.TSC;
      if (this.TheTestRes.TSC <= 0.0)
          this.TheTestRes.TSC = 0.0;
      this.TheTestRes.TSC += 0.0001; //interTSC
      this.TSCod = this.TheTestRes.TSC; // In order to check condition(TSCod>5) to recalculate motility  
  }

  protected RecalcTSCFromMSC():void
  {
      let TSC:number = this.TheTestRes.TSC;
      let OldTSC:number= this.TheTestRes.TSC;
      let MSC:number = this.TheTestRes.MSC;
     // if (((TSC < 2 && MSC >= 2 && MSC <= 5) || (TSC >= 2 && TSC <= 5)))
     // {
          //TSC = TSC/2 + (MSC / (1.2944*MSC*MSC+0.2671*MSC+37.281))*100;
          TSC = TSC / 2 + (MSC / (2.4982 * MSC + 39.868)) * 100;
          this.InterMPar.ReCalc_TSC_LQ1 = TSC;
          if (MSC >= TSC)
              TSC = MSC / this.LimitDataByUsingCount(95, this.CurrKeyPar.COUNT,250) * 100; //changed 10.6.12
              //TSC = MSC / LimitDataByUsingCount(95, NewCount) * 100;
          this.InterMPar.ReCalc_TSC_LQ2 = TSC;
    //  }

      if ((OldTSC < 2 && MSC >= 2 && MSC <= 5) || (OldTSC >= 2 && OldTSC <= 5))
             this.TheTestRes.TSC = TSC;   //RecalcTSC_LQ2
      //InterMPar.ReCalc_TSC_LQ2 = TSC;
      super.RecalcTSCFromMSC();
     // base.RecalcTSCFromMSC();
  }

  protected FinalRes()
  {
      let x:number;

      //if ((this.TheTestRes.MSC > 5) && (this.TheTestRes.TSC > 5))
      if ((this.TheTestRes.MSC > 5) && (this.TSCod > 5))
      {
          //this.TheTestRes.Motility = this.TheTestRes.MSC * 100 / this.TheTestRes.TSC;
          x = 1.2;
          if (this.TheTestRes.Motility > 37.2272) x = 0.5;
          this.TheTestRes.Motility = 44.1845 + x * (this.TheTestRes.Motility - 37.2272);//PreMOT
          //this.TheTestRes.MSC = this.TheTestRes.TSC * this.TheTestRes.Motility / 100;//FinalMSC
      }

      if (this.TheTestRes.Motility > 95)
      {
          this.TheTestRes.Motility = this.LimitDataByUsingCount(95, this.CurrKeyPar.COUNT,250);   //InterMot2 changed 10.6.12
          //this.TheTestRes.Motility = LimitDataByUsingCount(95, NewCount);   //InterMot2
          //this.TheTestRes.TSC = (this.TheTestRes.MSC * 100) / this.TheTestRes.Motility; //Change on 29.4.12
      }

      // if (this.TheTestRes.TSC >= 2 && this.TheTestRes.Velocity >= 1)
      //    this.TheTestRes.Velocity *= 3.2;

  }
  
   PerformTest():void
   {
            
            this.NormalizeCount();
            this.NormalizeAverWidth();
            this.CalculateSMI();
           /* if (this.RawDataResults.UserCount == true){
                this.CalcLowQuality();
                return;
            }*/
            this.AdjustOD(); 
            this.CalcTSC();
            this.CalcBasicMSC();
            this.CheckAverWidth();
            this.CalcVelocity();
            this.TheTestRes.MSC=this.UpdateChamberCoef(this.TheTestRes.MSC);//MSCw2
            console.log("MSC After upadte 1: "+this.TheTestRes.MSC );
            // CalcInternalMot();
            this.RecalcTSCFromMSC();
            if (this.CheckIfMSCGreaterThenTSC() == true)
                this.UpdateIfMSCGreaterThenTSC();
            else
                this.FinalRes();
            //CalcMotilityParameters();
            console.log("MSC After upadte 1: "+this.TheTestRes.MSC );

            //InterMot3
            if (this.TSCod <= 5 && this.TheTestRes.MSC < this.TheTestRes.TSC) //Low quality 
                this.TheTestRes.Motility = this.TheTestRes.MSC / this.TheTestRes.TSC * 100;
            this.TheTestRes.Motility += 0.001;

            this.TheTestRes.TSC += 0.001; //TSC1
            this.InterMPar.TSC1 = this.TheTestRes.TSC;
            this.TSC1 = this.TheTestRes.TSC;

            //TSC1 is needed for calculate  fial TSC in case of debris moderate or high(wbc-normal 
            if (this.IsCalcTSC1_round == true)
                this.AbNWbcTSC1 = this.TheTestRes.TSC;
            //TSC2
            if (this.TheTestRes.TSC < 2)
                this.TheTestRes.TSC = -3;
            this.InterMPar.TSC2 = this.TheTestRes.TSC;

            /*if (CheckVeryLowQuality() == true)
              UpdateMotility();*/

           // RecalcFreshWashedDiluted();//added 2.4.12

            // TSC 3
            if (this.SetupConstants.ConcStandard ==WBCConcStd.WBC_CONC_NORMAL && this.SetupConstants.DebrisScan != Debris_Scan.LOW)
            {
                this.Debris_TSC1andMotF2(); // //when wbc=normal calculate TSC and motility according to debris level
            
            }
            this.InterMPar.TSC3 = this.TheTestRes.TSC;

            //TSC4
            /*if ((this.RawDataResults.UserCount == true))
            {            //Manual count
                this.CalcLowQuality();
                this.InterMPar.TSC4 = this.TheTestRes.TSC;
                this.TheTestRes.Velocity = -1;
                return;
            }*/
            /*else*/ if (this.TheTestRes.TSC < 2) this.TheTestRes.TSC = -3;

            this.InterMPar.TSC4 = this.TheTestRes.TSC;

            //MSCperlim
            if (this.TheTestRes.TSC >= 0)
            {
                
                 this.MSCperlim = this.TheTestRes.TSC * this.TheTestRes.Motility / 100;
            }
            else if (this.TheTestRes.MSC < 2)
                this.MSCperlim = this.TheTestRes.MSC;
            else
                this.MSCperlim = 0;

           /* if (CheckVeryLowQuality() == true)
            {
                if ((this.MSCperlim >= 2) && (this.TSC1 >= 2))
                    this.TheTestRes.Motility = this.MSCperlim / this.TSC1 * 100;
                else
                {
                    UpdateMotility();
                    //if (this.SetupConstants.ConcStandard.Equals(WBCConcStd.WBC_CONC_ABNORMAL))
                    //this.TheTestRes.Motility = (CalcMotNoramlWbc() + this.TheTestRes.Motility) / 2;
                    //return;
                }
            }*/

            if (this.CheckVeryLowQuality() == true)
                this.UpdateMotility();

            if (this.TSC1 >= 2 && this.MSCperlim >= 2)
            {
                //this.TheTestRes.Motility = this.MSCperlim / this.TSC1 * 100;
                this.TheTestRes.Motility = this.MSCperlim / this.TheTestRes.TSC * 100;
                if (this.TheTestRes.Motility >= 95)
                    this.TheTestRes.Motility = this.LimitDataByUsingCount(95, this.CurrKeyPar.COUNT,250);
            }
          
            // If this test calculation check TSC1 of AbNormal in order to calculate final TSC for WBC=normal   
            

          //  this.InterMPar.TSC5 = this.TheTestRes.TSC;
           // this.TSC5 = this.TheTestRes.TSC; //for calculating total sperm
         if (this.IsCalcTSC1_round == true && this.SetupConstants.DebrisScan != Debris_Scan.GROSS) { 
              /*   if(this.RawDataResults.DebrisScan == Debris_Scan.GROSS){
                    double  TempMot = CalcMotNoramlWbc();
                    if (TempMot < 0)
                        this.TheTestRes.Motility = 0;
                    else
                       this.TheTestRes.Motility = (TempMot + this.TheTestRes.Motility * 1.065) / 2;
                 }*/
                return;
        }

            this.CalcPMSCAndUpdate();
            console.log("MSC After upadte 2: "+this.TheTestRes.MSC );
            if (this.IsCalcTSC1_round == true)
                return;

           //updateMotilitySignal();
           // if (this.TheTestRes.MSC < 0.2 || this.TheTestRes.TSC < 2)
                //return;
            this.AdditionalCalculations();
            this.CalcMorphology();
            this.InterMPar.Morph = this.TheTestRes.Morphology;
            this.CalcFSC();

            if (this.TheTestRes.TSC < 0 || this.TheTestRes.MSC<1 /*|| this.RawDataResults.UserCount==true*/)
                this.TheTestRes.Velocity = -1;
            else
            {
                if (this.TheTestRes.Velocity >= 1)
                    this.TheTestRes.Velocity *= 3.2;
                else
                    this.TheTestRes.Velocity = -10;  //when velocity is smaller than 1 then it's written "<1" 
            }

            //CalcFSC();
            this.CalcTotals();

            if (this.TheTestRes.TSC > 500)
            {
                this.TheTestRes.TSC_Raw=this.TheTestRes.TSC;
                this.TheTestRes.TSC = -500;
                this.TheTestRes.SMI = -1;
            }

        }
   // }
}


class DilFreshTest extends TestResult{

    TempMot :number;
    CurrSampleCount: number;
    constructor(SetUp:TestSetup,KeysP: KeyParameters, sampleCounts:number/*,numReads:number*/) {super(SetUp,KeysP, sampleCounts); this.CurrSampleCount=sampleCounts}

    protected RecalcAverWidth()
    {
        this.NormalizeAverWidth();
        this.CurrKeyPar.AW*=2;
    }

        protected RecalcOD()
        {
            let Coeff:number;
            let TempOD:number;

            Coeff = 1;
            // if (this.SetupConstants.ConcStandard == WBCConcStd.WBC_CONC_ABNORMAL)
            if  (this.IsABNormalWbcDebris)
            {
                TempOD = this.ODAdjusted - 0.1975;
                Coeff = 1;
                if (this.ODAdjusted <= 1.9)
                    Coeff = -0.2955 * TempOD * TempOD * TempOD + 0.871 * TempOD * TempOD - 1.2699 * TempOD + 2.097;
            }
            else
            {
                TempOD = this.ODAdjusted - 0.157;
                Coeff = 1;
                if (this.ODAdjusted <= 1.9)
                    Coeff = 1.1767 * TempOD * TempOD * TempOD * TempOD - 3.7786 * TempOD * TempOD * TempOD + 4.2582 * TempOD * TempOD - 2.0761 * TempOD + 1.888;
            }
           
            if (TempOD <= 0)
                this.CurrKeyPar.OD = 0;
            else
                this.CurrKeyPar.OD= TempOD * Coeff + 0.314;

        }

        protected  AdjustOD()
        {
            //  if (SetupConstants.ConcStandard == WBCConcStd.WBC_CONC_ABNORMAL)
            if (this.IsABNormalWbcDebris)
                this.ODAdjusted = 0.5132 * this.CurrKeyPar.OD + 0.1262;
            else
                this.ODAdjusted = this.CurrKeyPar.OD;
        }


        protected CalcTSC()
        {
            let  adjOD :number, TSC :number;
            adjOD = this.ODAdjusted;
            if ((this.SetupConstants.LesFlag==LES.TwoROW) && ((!this.IsABNormalWbcDebris &&
               this.CurrKeyPar.OD < 0.454) || (this.IsABNormalWbcDebris && this.CurrKeyPar.OD < 0.77)))

                this.IsLESODLimit = true;
            else
                this.IsLESODLimit = false;

            if (this.IsLESODLimit)
            {
                if (!this.IsABNormalWbcDebris)
                    TSC = 23.717 * this.CurrKeyPar.OD- 3.5509;
                else
                    TSC = 23.656 * this.CurrKeyPar.OD- 5.8405;
            }
            else
                TSC = 114.18 * adjOD * adjOD - 35.883 * adjOD;

            //  if (this.TheTestRes.TSC * CalcStdCoeff() >= 40)
            //   this.TheTestRes.TSC /= 1.5538;

            TSC *= this.CalcStdCoeff();
            TSC = -0.0002 * XYpow(TSC, 2) + 0.8272 * TSC;

            if (TSC < 0.1)
                TSC = 0.0;
           

            this.TheTestRes.TSC = TSC;
            this.TheTestRes.TSC += 0.0001;
            this.TSCod = this.TheTestRes.TSC;//interTSC
        }


        protected RecalcCount()
        {
            let x:number;
            let Coeff:number;
            this.NormalizeCount();
            x = this.CurrKeyPar.COUNT;
            if (x < 180)
            {
                Coeff = -0.0086 * x + 3.0468;
            }
            else
            {
                if (x <= 350)
                {
                    Coeff = -0.0028 * x + 2;
                }
                else
                {
                    Coeff = 1;
                }
            }
            this.NewCount = x * Coeff;
        }

        protected RecalcTSCFromMSC()
        {
            let TSC:number;
            let MSC:number;
            // TSC = this.TheTestRes.TSC;
            TSC = this.TSCod;
            MSC = this.TheTestRes.MSC;
            if (((TSC < 2 && MSC >= 2 && MSC <= 5) || (TSC >= 2 && TSC <= 5)))
            {
                //TSC = TSC/2 + (MSC / (1.2944*MSC*MSC+0.2671*MSC+37.281))*100;
                TSC = TSC / 2 + (MSC / (2.4982 * MSC + 39.868)) * 100;

                if (MSC >= TSC)
                    TSC = MSC / this.LimitDataByUsingCount(95, this.CurrKeyPar.COUNT,250) * 100;

            }
            this.TheTestRes.TSC = TSC;
            super.RecalcTSCFromMSC();
        }

        protected RecalcFreshWashedDiluted()
        {
            let InterMot4:number;
            if (this.TheTestRes.Motility <= 40)
                InterMot4=this.TheTestRes.Motility / 0.85;
            else
                InterMot4=this.TheTestRes.Motility;

            this.MSCperlim = this.TheTestRes.TSC * InterMot4 / 100;
        }

        protected FinalRes()
        {
            let x:number;

            //if ((this.TheTestRes.MSC > 5) && (this.TheTestRes.TSC > 5))
            if ((this.TheTestRes.MSC > 5) && (this.TSCod > 5))
            {
                this.TheTestRes.Motility = this.TheTestRes.MSC * 100 / this.TheTestRes.TSC;
                x = 1.2;
                if (this.TheTestRes.Motility > 37.2272) x = 0.5;
                this.TheTestRes.Motility = 44.1845 + x * (this.TheTestRes.Motility - 37.2272);//preMot
                //this.TheTestRes.MSC = this.TheTestRes.TSC * this.TheTestRes.Motility / 100;
            }

            if (this.TheTestRes.Motility > 95)
            {
                //this.TheTestRes.Motility = LimitDataByUsingCount(95, NewCount);//interMot2
                this.TheTestRes.Motility = this.LimitDataByUsingCount(95, this.CurrKeyPar.COUNT,250);//interMot2 changed 10.6.12
                //this.TheTestRes.TSC = (this.TheTestRes.MSC * 100) / this.TheTestRes.Motility;
            }

           // if (this.TheTestRes.TSC >= 2 && this.TheTestRes.Velocity >= 1)
             //   this.TheTestRes.Velocity *= 3.2;
        }
        protected  NormalizeAverWidth()
        {
            if (this.IsAverWidthNormalized == false)
            {        // second round parameter was removed

                this.CurrKeyPar.AW = round(this.NormFactor * this.CurrKeyPar.AW);

                this.IsAverWidthNormalized = true;
            }
        }

        PerformTest():void
        {
            //ReCalcAvg();
           this.CurrKeyPar.AV *= super.ReCalcAvg();
            this.RecalcAverWidth();
            this.RecalcOD();
            this.RecalcCount();
            super.PerformTest();
        }

}

class LVFreshTest extends TestResult{

    TempMot :number;
    CurrSampleCount: number;
    constructor(SetUp:TestSetup,KeysP: KeyParameters, sampleCounts:number/*,numReads:number*/) {super(SetUp,KeysP, sampleCounts); this.CurrSampleCount=sampleCounts}
    
    protected CalcMotilityParameters()
    {
        let PMSCa:number;
        let Temp:number;
        let x:number;
        let Veloc:number;

        Veloc = this.TheTestRes.Velocity;

        Temp = 0.0053 * XYpow(Veloc, 3) - 0.4235 * XYpow(Veloc, 2) + 10.933 * Veloc;

        this.CalcPMSC();

        //Preli. PMOT a of MSC
        Temp = -0.00002 * XYpow(Veloc, 5) +
                                 0.0024 * XYpow(Veloc, 4) -
                                 0.1056 * XYpow(Veloc, 3) +
                                 1.9873 * XYpow(Veloc, 2) - 10.98 * Veloc;

        //this.TheTestRes.PMota = this.TheTestRes.PMota + 0.0024 * Veloc * Veloc * Veloc * Veloc;
        //this.TheTestRes.PMota = this.TheTestRes.PMota - 0.1056 * Veloc * Veloc * Veloc;
        //this.TheTestRes.PMota = this.TheTestRes.PMota + 1.9873 * Veloc * Veloc - 10.98 * Veloc;

        if (Temp < 0.0)
            Temp = 0.0;  //Prog.Mot "a" of MSC


        PMSCa = (Temp * this.TheTestRes.MSC) / 100.0;

        if (Veloc < 10)
            x = 0.001 * XYpow(Veloc, 3) - 0.0326 * XYpow(Veloc, 2) + 0.3573 * Veloc;
        else
            x = 1.3;

        Temp = x + 0.0003 * XYpow(PMSCa, 3) - 0.0479 * XYpow(PMSCa, 2) + 2.6569 * PMSCa;
        if (Temp < 0)
            Temp = 0;

        PMSCa = Temp;

        x = 0.85 * this.TheTestRes.PMSC;
        if (PMSCa > x)
            PMSCa = x;

        this.TheTestRes.PMSCb = this.TheTestRes.PMSC - PMSCa;

      /*  if ((this.TheTestRes.TSC == 2.0) || (this.TheTestRes.MSC < 0.2))
        {
            this.TheTestRes.PMota = -1;
            this.TheTestRes.PMotb = -1;
        }
        else
        {
            Temp = this.TheTestRes.TSC;
            this.TheTestRes.PMota = (PMSCa / Temp) * 100.0;
            this.TheTestRes.PMotb = (this.TheTestRes.PMSCb / Temp) * 100.0;

        }*/

        // update the latest PMSCa result.
        this.TheTestRes.PMSCa = PMSCa;
    }

   

   /* private void UpdateDataForLQ()
    {
        this.TheTestRes.MSC = -2;   //MSC=0.2;
        this.TheTestRes.Velocity = -1; // N.A
        ClearAllTotalResults();
        ClearMotilityParameters();

    }*/

    protected CalcLowVolumeRes()
    {
        if (this.TheTestRes.MSC > 5)
        {
            this.TheTestRes.MSC *= 1.1393;
        }
        //CalcPMSC();
       

        else if (this.TheTestRes.MSC < 0.2)
        {
            //UpdateDataForLQ();
            this.TheTestRes.MSC = -2;
            this.TheTestRes.Velocity = -1;
            return;
        }
        if (this.TheTestRes.MSC < 0.1)
            this.TheTestRes.Velocity = 0;

        this.CalcMotilityParameters();
        this.CalcTotalMSCPMSC();

        if (this.TheTestRes.Velocity < 1)
            this.TheTestRes.Velocity = -10;
        else if (this.TheTestRes.MSC < 1)
            this.TheTestRes.Velocity = -1;
        else
            this.TheTestRes.Velocity *= 3.2;
    }



    PerformTest():void
    {
        this.NormalizeCount();
        this.NormalizeAverWidth();
        this.CalculateSMI();
        /*if (this.RawDataResults.UserCount == true)
        {
            isShortReport();
            this.CalcLowQuality();
            return;
        }*/
        //AdjustOD();
       // CalcTSC();
        this.CalcBasicMSC();
        this.CheckAverWidth();
        this.CalcVelocity();
        this.TheTestRes.MSC=this.UpdateChamberCoef(this.TheTestRes.MSC);
        //RecalcTSCFromMSC();
       
        //CalcMotilityParameters();
        this.CalcLowVolumeRes();
       // this.LVParameterUsingMotInput(); Motility input
        
       // if (isShortReport()) no imput motility or manual user count
            this.LV_SignAsEmpty();
        
        this.calcManMorph();
    }

}
class NormalWashedTest extends TestResult{
   // TempMot :number;
    CurrSampleCount: number;
    constructor(SetUp:TestSetup,KeysP: KeyParameters, sampleCounts:number/*,numReads:number*/) {super(SetUp,KeysP, sampleCounts); this.CurrSampleCount=sampleCounts}
    
    protected CalcTSC()
    {
       // adjOD :Number;
        let adjOD :number = this.ODAdjusted;
        this.TheTestRes.TSC = 8.0912 * adjOD * adjOD * adjOD + 83.049 * adjOD * adjOD - 2.6576 * adjOD;
        //if (CalcStdCoeff() >= 40)
          //  this.TheTestRes.TSC /= 1.5538;
        this.TheTestRes.TSC *=this.CalcStdCoeff();
        this.InterMPar.PerlimTSC = this.TheTestRes.TSC;
        if (this.TheTestRes.TSC < 0.1)
            this.TheTestRes.TSC = 0.0;

        this.TheTestRes.TSC += 0.0001;
        this.TSCod = this.TheTestRes.TSC;
    }

    protected RecalcTSCFromMSC()
    {
   
        let TSC :number=this.TheTestRes.TSC;
       
        let OldTSC: number=TSC;
        let MSC :number = this.TheTestRes.MSC;
       
        TSC = TSC / 2.0 + (MSC / (2.4982 * MSC + 39.868)) * 100;
        this.InterMPar.ReCalc_TSC_LQ1=TSC;
        
        if (MSC >= TSC)
            TSC = MSC / this.LimitDataByUsingCount(95, this.CurrKeyPar.COUNT, 250) * 100; 
        
        this.InterMPar.ReCalc_TSC_LQ2 = TSC;
      

        if ((OldTSC < 2 && MSC >= 2 && MSC <= 5) || (OldTSC >= 2 && OldTSC <= 5))
            this.TheTestRes.TSC = TSC;   //RecalcTSC_LQ2
        

        super.RecalcTSCFromMSC();
    }
    protected FinalRes()
    {
       
        if (this.TheTestRes.Motility > 95)
            this.TheTestRes.Motility = this.LimitDataByUsingCount(95, this.CurrKeyPar.COUNT,250);

    }
    
    PerformTest():void
    {    
        super.PerformTest();
    
    }


}
class DilutedWashedTest extends TestResult{
    
    CurrSampleCount: number;
    constructor(SetUp:TestSetup,KeysP: KeyParameters, sampleCounts:number/*,numReads:number*/) {super(SetUp,KeysP, sampleCounts); this.CurrSampleCount=sampleCounts}
    
    protected RecalcAverWidth()
    {
        this.NormalizeAverWidth();
        this.CurrKeyPar.AW*=2;
    }

    protected CalcTSC()
    {
        //double adjOD;
        let adjOD :number = this.ODAdjusted;
        this.TheTestRes.TSC = 8.0912 * adjOD * adjOD * adjOD + 83.049 * adjOD * adjOD - 2.6576 * adjOD;
        if (this.CurrKeyPar.OD <= 0.26)
            this.TheTestRes.TSC = 39.0 * this.CurrKeyPar.OD;
        else
            this.TheTestRes.TSC *= 2;
        this.TheTestRes.TSC *= this.CalcStdCoeff();
        if (this.TheTestRes.TSC < 0.1)
            this.TheTestRes.TSC = 0.0;
        this.TheTestRes.TSC += 0.0001;
        this.TSCod = this.TheTestRes.TSC;

    }

    protected CalcBasicMSC()
    {
        

        let Avg: number = this.CurrKeyPar.AV;
        if (Avg <= 75)
            this.MSCVel = 0.0002 * Avg * Avg * Avg - 0.019 * Avg * Avg + 1.4366 * Avg;
        else
            this.MSCVel = 0.0053 * Avg * Avg + 0.8077 * Avg;

        this.MSCVel *= 2;
        this.TheTestRes.MSC = this.MSCVel;

    }

    protected RecalcTSCFromMSC()
    {
       
        let TSC :number = this.TSCod;
        let MSC :number = this.TheTestRes.MSC;
        if (((TSC < 2 && MSC >= 2 && MSC <= 5) || (TSC >= 2 && TSC <= 5)))
        {
            //TSC = TSC/2 + (MSC / (1.2944*MSC*MSC+0.2671*MSC+37.281))*100;
            TSC = TSC / 2 + (MSC / (2.4982 * MSC + 39.868)) * 100;

            if (MSC >= TSC)
                TSC = MSC / this.LimitDataByUsingCount(95, this.CurrKeyPar.COUNT,250) * 100;

        }
        this.TheTestRes.TSC = TSC;
        super.RecalcTSCFromMSC();
    }
    
    protected RecalcCount()
    {
        let Coeff:number;
        this.NormalizeCount();
        let x :number= this.CurrKeyPar.COUNT;
        if (x < 180)
        {
            Coeff = -0.0086 * x + 3.0468;
        }
        else
        {
            if (x <= 350)
            {
                Coeff = -0.0028 * x + 2;
            }
            else
            {
                Coeff = 1;
            }
        }
        this.NewCount = x * Coeff;
    }

    protected RecalcFreshWashedDiluted()
    {
        let InterMot4 :number;

        if (this.TheTestRes.Motility <= 45)          //WASHED, Low volume, media 1:1
            InterMot4=this.TheTestRes.Motility /0.7;

        else if (this.TheTestRes.Motility >= 55)
                InterMot4=this.TheTestRes.Motility/ 1.3;
        else
                InterMot4=this.TheTestRes.Motility;
        
        this.MSCperlim = this.TheTestRes.TSC * InterMot4 / 100;
    }
    
    protected FinalRes()
    {
        if (this.TheTestRes.Motility > 95)
        {
            this.TheTestRes.Motility = this.LimitDataByUsingCount(95, this.CurrKeyPar.COUNT,250);   //InterMot2
            //this.TheTestRes.TSC = (this.TheTestRes.MSC * 100) / this.TheTestRes.Motility;
        }
    }
    
    protected NormalizeAverWidth()
    {
        if (this.IsAverWidthNormalized == false)
        {
            this.CurrKeyPar.AW = round(this.NormFactor * this.CurrKeyPar.AW);

            this.IsAverWidthNormalized = true;
        }
    }

    PerformTest():void
    {
        this.RecalcAverWidth();
        this.RecalcCount();
        super.PerformTest();
        
    }
}
class LVWashedTest extends TestResult{
    
    CurrSampleCount: number;
    constructor(SetUp:TestSetup,KeysP: KeyParameters, sampleCounts:number/*,numReads:number*/) {super(SetUp,KeysP, sampleCounts); this.CurrSampleCount=sampleCounts}
    
    protected CalcMotilityParameters()
    {
        let PMSCa:number;
        let Temp :number;
        let x :number;
        let Veloc:number;

        Veloc = this.TheTestRes.Velocity;

        Temp = 0.0053 * XYpow(Veloc, 3) - 0.4235 * XYpow(Veloc, 2) + 10.933 * Veloc;

        this.CalcPMSC();

        //Preli. PMOT a of MSC
        Temp = -0.00002 *XYpow(Veloc, 5) +
                                 0.0024 * XYpow(Veloc, 4) -
                                 0.1056 * XYpow(Veloc, 3) +
                                 1.9873 * XYpow(Veloc, 2) - 10.98 * Veloc;

        

        if (Temp < 0.0)
            Temp = 0.0;  //Prog.Mot "a" of MSC


        PMSCa = (Temp * this.TheTestRes.MSC) / 100.0;

        if (Veloc < 10)
            x = 0.001 * XYpow(Veloc, 3) - 0.0326 * XYpow(Veloc, 2) + 0.3573 * Veloc;
        else
            x = 1.3;

        Temp = x + 0.0003 * XYpow(PMSCa, 3) - 0.0479 * XYpow(PMSCa, 2) + 2.6569 * PMSCa;
        if (Temp < 0)
            Temp = 0;

        PMSCa = Temp;

        x = 0.85 * this.TheTestRes.PMSC;
        if (PMSCa > x)
            PMSCa = x;

        this.TheTestRes.PMSCb = this.TheTestRes.PMSC - PMSCa;

        // update the latest PMSCa result.
        this.TheTestRes.PMSCa = PMSCa;
    }


    protected CalcLowVolumeRes()
    {    

        if (this.TheTestRes.MSC < 0.2)
        {
            //UpdateDataForLQ();
            this.TheTestRes.MSC = -2;
            this.TheTestRes.Velocity = -1;
            return;
        }
        
        if (this.TheTestRes.MSC < 0.1)
            this.TheTestRes.Velocity = 0;

        this.CalcMotilityParameters();
        this.CalcTotalMSCPMSC();

        if (this.TheTestRes.Velocity < 1)
            this.TheTestRes.Velocity = -10;
        else if (this.TheTestRes.MSC < 1)
            this.TheTestRes.Velocity = -1;
        else
            this.TheTestRes.Velocity *= 3.2;
    }
    
    PerformTest():void
    {
        this.NormalizeCount();
        this.NormalizeAverWidth();
        this.CalculateSMI();
       /* if (this.CurrKeyPar. == true) //usercount
        {
            isShortReport();
            this.CalcLowQuality();
            return;
        }*/
        //AdjustOD();
        // CalcTSC();
        this.CalcBasicMSC();
        this.CheckAverWidth();
        this.CalcVelocity();
        this.TheTestRes.MSC=this.UpdateChamberCoef(this.TheTestRes.MSC);
        //RecalcTSCFromMSC();
        
        //CalcMotilityParameters();
        this.CalcLowVolumeRes();
        //this.LVParameterUsingMotInput();
        
        //if (isShortReport())
        this.LV_SignAsEmpty();

        this.calcManMorph();
    }

}
class ControlTest extends TestResult{

    constructor(SetUp:TestSetup,KeysP: KeyParameters, sampleCounts:number/*,numReads:number*/) {
        super(SetUp,KeysP, sampleCounts);
    }
    
    
    protected CalcTSC()
    {
        let TSC: number=0;
        let OD:number=0;

         OD =this.CurrKeyPar.OD;// RawDataResults.OD;
         switch(this.SetupConstants.control_Type) 
         {
             case ControlType.CONTROL_TYPE_LATEX_BEADS:
                 OD = this.SetupConstants.LBODAmp * this.CurrKeyPar.OD / 1000;
                 if (OD <= 1.4)
                     TSC = -43.031 * XYpow(OD, 4) + 119.7 * XYpow(OD, 3) - 82.637 * XYpow(OD, 2) + 37.41 * OD;
                 else
                     TSC = 1337 * XYpow(OD, 3) - 5586.5 * XYpow(OD, 2) + 7866.6 * OD - 3679.4;

                 if (TSC < 1) TSC = 0;
                 break;
             case ControlType.CONTROL_TYPE_PROF_CAP:
             case ControlType.CONTROL_TYPE_STAB_SPERM:
                 if (OD <= 0.42)
                     TSC = 53.534 * XYpow(OD, 3) + 43.259 * XYpow(OD, 2) + 50.848 * OD;
                 else
                     TSC = -9.8143 * XYpow(OD, 4) + 425.21 * XYpow(OD, 3) - 901.96 * XYpow(OD, 2) + 703.32 * OD - 135.2;
                 if (TSC < 2) TSC = -3;
                 break;
          /*   case ContType.CONTROL_TYPE_STAB_SPERM:
                 TSC = 113.74 * Math.Pow(OD, 3) - 68.803 * Math.Pow(OD, 2) + 79.02 * OD;
                
                 if (TSC < 2) TSC = -3;
                 break;*/
             case ControlType.CONTROL_TYPE_PROF_NEQAS:
                 TSC = this.CalcNeqasTSC();
                 if (TSC < 2) TSC = -3;
                 break;
         }
      


       /* if (contLevel == ControlLevel.NEGATIVE_CONTROL)
        {
            if (TSC < 1)
                TSC = 0.0;
        }
        else
        {
            if (TSC < 0.1)
                TSC = 0.0;
        }*/
        this.TheTestRes.TSC = TSC;
    }

    protected  UpdatMSCIfNegControl()
    {
        if (this.SetupConstants.control_Level == ControlLevel.NEGATIVE_CONTROL)
        {
            if (this.TheTestRes.MSC < 0.2)
                this.TheTestRes.MSC = 0;
        }
    }
    protected CalcNeqasTSC():number
    {
       // FreshDilTest:TestResult;
        //FreshResults:Results;
        //SetupConsts FreshSetUp = new SetupConsts();
       // FreshSetUp.DefaultConstant();
       // FreshSetUp.ConcStandard = WBCConcStd.WBC_CONC_ABNORMAL;
        //FreshSetUp.TheTestType = TestType.DILUTED;
       // FreshSetUp.LESflag = LES.TwoROW;
        let  FreshSetUp :TestSetup = new TestSetup(SAMPLETYPE.FRESH,TESTTYPE.DILUTED,WBCConcStd.WBC_CONC_ABNORMAL, 
            ChamberStd.MAKLER,MorphCriteria.MORPH_WHO3,LES.TwoROW,
            -1, Debris_Scan.LOW);
       // KeyParameters FreshRaw = new KeyParameters();
      //  FreshRaw.Average = 0;
      //  FreshRaw.Count = 0;
       // FreshRaw.AverWidth = 0;
       // FreshRaw.OD = RawDataResults.OD;
      
        let Keys:KeyParameters =new KeyParameters (this.PerlimKeyPar.OD,0,0,0);

        let FreshDilTest:DilFreshTest = new DilFreshTest (FreshSetUp, Keys,NORMAL_TEST_SAMPLES);
        FreshDilTest.PerformTest();
        let freshResults:Results=FreshDilTest.TheTestRes;

      //  FreshDilTest = TestResultsFactory.GetMinimalRes(FreshSetUp, FreshRaw, (uint)(NORMAL_TEST_SAMPLES / this.NormFactor));
       // FreshDilTest.PerformTest();
       // FreshResults = FreshDilTest.TheTestResults;

        return freshResults.TSC;
    }

    public PerformTest():void
    {
        this.NormalizeCount();
        this.NormalizeAverWidth();
        this.CalculateSMI();
        this.CalcTSC();
        if (this.SetupConstants.control_Level != ControlLevel.NEGATIVE_CONTROL)
            return;
        //Negative level
        this.CalcBasicMSC();
        this.CheckAverWidth();
        this.TheTestRes.MSC=this.UpdateChamberCoef( this.TheTestRes.MSC);
        this.UpdatMSCIfNegControl();
    }

}

@Injectable({
  providedIn: 'root'
})

export class AlgorithmService {
  
    TestRequire: any;
    //finalResults: Results;
    constructor(){}
    SetTestParameters(SetUp:TestSetup,KeysP: KeyParameters, sampleCounts:number=NORMAL_TEST_SAMPLES) { 
        console.log('Check Sample Type');
        
        switch(SetUp.sampleType){
            case SAMPLETYPE.CONTROL:
                this.TestRequire=new ControlTest(SetUp,KeysP,sampleCounts);
                break;
            case SAMPLETYPE.FRESH:
                switch(SetUp.testType){
                    case TESTTYPE.NORMAL:
                        this.TestRequire=new NormalFreshTest(SetUp,KeysP,sampleCounts);
                        break;
                    case TESTTYPE.DILUTED:
                        this.TestRequire=new DilFreshTest(SetUp,KeysP,sampleCounts) ;
                        break;
                    case TESTTYPE.LOW_VOLUME:
                        this.TestRequire=new LVFreshTest(SetUp,KeysP,sampleCounts) ;
                        break;      
                }
                break;
            case SAMPLETYPE.WASHED:
                switch(SetUp.testType){
                    case TESTTYPE.NORMAL:
                        this.TestRequire=new NormalWashedTest(SetUp,KeysP,sampleCounts);
                        break;
                    case TESTTYPE.DILUTED:
                        this.TestRequire=new DilutedWashedTest(SetUp,KeysP,sampleCounts) ;
                        break;
                    case TESTTYPE.LOW_VOLUME:
                        this.TestRequire=new LVWashedTest(SetUp,KeysP,sampleCounts) ;
                        break;      
                }
                break;
            break;

            default:
                this.TestRequire=new TestResult(SetUp,KeysP,sampleCounts) ;
                break;

                
        }

    }

    setRecalForDebris(){
        this.TestRequire.setRecalcDebri(true);
    }
    getTSCofDebrisRound(){
       return this.TestRequire.getTSCofDebris();
    }
    calculateResults(){
    
        this.TestRequire.CurrKeyPar.printKeyPar();
        this.TestRequire.PerformTest();
        this.TestRequire.TheTestRes.printAllResults();

        return this.TestRequire.TheTestRes;
        
    }
    
    performManualCalculation(who_Type:MorphCriteria,Conc:number,totalMot:number,morph:number,pmot1:number,pmot2?:number,volume?:number){
        this.TestRequire=new ManualTestResult(who_Type,Conc,totalMot,morph,pmot1,pmot2,volume);
        this.TestRequire.PerformTest();
        this.TestRequire.TheTestRes.printAllResults();
        return this.TestRequire.TheTestRes;
    }

  
}

