import { Injectable, EventEmitter } from '@angular/core';

import * as io from 'socket.io-client';
//import { Observable } from 'rxjs';
import { timer } from 'rxjs';
import { ActivationEnd } from '@angular/router';
//import { Socket } from 'dgram';
import { HttpService } from './http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { TESTTYPE } from './algorithm.service';
import * as firebase from 'firebase';
import { conditionallyCreateMapObjectLiteral } from '@angular/compiler/src/render3/view/util';

export class MyServiceEvent {
  port: number;
  portindex: number;
  command: string;
  value: any;
  data?: any = '';
}

export class timertrigger {
  countertype: number;
  value: Counters;
}

export class Counters {
  COUNTER_1: number;
  COUNTER_2: number;
  COUNTER_3: number;
  DISCONNECT_COUNTER: number;
}

export const capillaryStatus = {
  CAP_UNKNOWN: 0,
  CAP_IN_CHAMBER: 1,
  CAP_NOT_IN_CHAMBER: 2,  
};

export const TestState = {

  IDLE: 0,

  TEST_OD_TEST_ONLY: 1,
  TEST_MOT_TEST_ONLY: 2,

  TEST_FULL_OD_TEST: 3,
  TEST_FULL_MOT_TEST: 4,

  TEST_OD_REFERENCE: 5,
  TEST_NOISE_LEVEL: 6,

  TEST_WAIT_FOR_CAPILLARY: 7,

  TEST_SELF_TEST: 8,

  TEST_READ_MEM: 9,
  TEST_READ_SN: 10,

  TEST_READ_VALUE: 11,


  TEST_READ_REF1: 15,
  TEST_READ_MOT_LED: 16,
  TEST_READ_CONC_LED: 17,
  TEST_READ_REF2: 18,

  TEST_READ_CAP: 40,
  TEST_READ_TEMP: 41,

  TEST_REFERENCE_OD_TEST: 20,
  TEST_REFERENCE_NOISE_TEST: 21,

  TEST_SAVE_SN: 25,

  // Operation 
  TEST_READ_ALL_REFFERENCES: 22,
  TEST_REFERENCE: 26,

};

export const ActiveClient = {

  ALL: 0,
  TEST_PROCESS_CLIENT: 1,
  ANALYSIS_CLIENT: 2,
  SERVICE_DATA_CLIENT: 3,
  CALIBRATION_CLIENT: 4,
  REALTIME_ANALYSIS_CLIENT: 5,
  PRE_CALIBRATION_CLIENT: 6,
  HOME_PAGE: 7,
  VERIFICATION_CLIENT: 8,
  SERVICE: 9,
  INSTALLATION: 10,
  POST_PRODUCTION: 11,
  PRE_PACKING: 12,
  FINAL_QC: 13,
  AUTOMATION: 14,
  SIDEBAR: 15,
  FILTER_CALIBRATION: 16,
  PRE_CALIBRATION: 18,
  POST_CALIBRATION: 20,
  CONTROL_CALIBRATION_CLIENT: 22,
  CLINICAL_ANALYSIS: 23,

};


//var CurrentTestState = TestState.IDLE;
var CurrentActiveClient = ActiveClient.ALL;
var pvalue = 0;
var dataValues = {}
const DELAY_BEFORE_MOTILITY_TEST = 3000;
const DELAY_BEFORE_CONC_TEST = 2000;
const NOISE_TEST_POINTS = 5000;
const DELAY_BEFORE_MONITOR = 100;
const BASICURL = 'http://localhost';

//const BASICURL = 'http://192.168.1.57';

@Injectable({
  providedIn: 'root'
})

export class CommunicatorService {

  onChange: EventEmitter<MyServiceEvent> = new EventEmitter<MyServiceEvent>();
  timerchange: EventEmitter<timertrigger> = new EventEmitter<timertrigger>();
  counters = new Counters;

  DevicePassStartup = false;

  // Hold the active sockets start with basic port (8001)   
  Parameters = [];
  calParameters = [];
  SocketArray = [];
  source = timer(100, 100);
  deviceListSocket = io(BASICURL + ':8000');
  disconnectionflag = 0;

  DriverIsConnected = false;

  constructor(private _http: HttpService, private _apihttp: HttpClient) {
    this.counters.COUNTER_1 = 0;
    this.counters.COUNTER_2 = 0;
    this.counters.COUNTER_3 = 0;
    this.counters.DISCONNECT_COUNTER = 0;

    //  Automatically find all devices
    this.Definedevicelistsocket();

  }

  private Definedevicelistsocket() {

    console.log("Call Definedevicelist")

    //this.deviceListSocket.pingTimeout = -1;
    this.deviceListSocket.autoConnect = true;


    this.deviceListSocket.on('error', () => {
      console.log('error');
      this.DriverIsConnected = false;
    });

    this.deviceListSocket.on('connect_error', () => {
      console.log('connect_error');
      this.DriverIsConnected = false;
      console.log(this.DriverIsConnected);
    });

    this.deviceListSocket.on('connect_failed', () => {
      console.log('connect_failed');
      this.DriverIsConnected = false;
    });

    this.deviceListSocket.on('connect', () => {
      console.log(" [1] Driver is running and connected");
      this.disconnectionflag = 0;
      this.counters.DISCONNECT_COUNTER = 0;
      this.DriverIsConnected = true;
      this.onChange.emit({ port: 8000, portindex: -1, command: "isConnect", value: true });
    });

    this.deviceListSocket.on('disconnect', (reason) => {
      this.disconnectionflag = 1;
      console.log(" [1_1] Counting to disconnection", reason);
    });


    this.deviceListSocket.emit('command', { command: 'getDeviceList' });

    this.deviceListSocket.on('command', (data) => {

      //console.log("Get Event from Driver", data)

      switch (data.command) {

        case 'deviceList':
          console.log(" [2] Device list: ", data.value);
          this.setCurrentClient(ActiveClient.ALL);

          for (let socket of this.SocketArray) {
            socket.disconnect();
          }

          this.SocketArray = [];

          for (let device of data.value) {
            this.Definelistener(device.httpPort);
          }


          for (let socket of this.SocketArray) {
            //this.readSerialNum(socket.portindex);
          }

          this.onChange.emit({ port: 8000, portindex: -1, command: "DeviceList", value: data.value });

          break;

        case 'dateFormat':
          this.onChange.emit({ port: 8000, portindex: -1, command: "dateFormat", value: data.value });
          console.log("Date format from communication", data.value.localdateformat)
          break;


      }
    });



    
  }


  // Define the listeners events
  // ==========================
  private Definelistener(httpPort) {

    let socketport = httpPort; //this.SocketArray.length + 8001;
    let socket = io(BASICURL + ':' + socketport.toString());
    socket['url'] = BASICURL + ':' + socketport.toString();
    socket['port'] = socketport;
    socket['portindex'] = this.SocketArray.length;
    socket['SN'] = '0';
    socket['TESTSTATE'] = TestState.IDLE;
    socket['ReadCalibraitonIsRequired'] = true;
    socket['calibration'] = { "average": [0, 1, 0], "count": [0, 1, 0], "OD": [0, 1, 0], "QC": [0, 1, 0] };     // [a0*x^n, a1*x^(n-1) ... an*x^0] 
    socket['isReference'] = false;
    socket['LB_OD_AMP'] = 1000;
    socket['amplitudeAMP'] = 100;

    socket.pingTimeout = -1;
    socket.autoConnect = true;

    //socket['OnStartUpRunAll'] = false;    

    this.SocketArray.push(socket);


    socket.on('connect', () => {
      console.log(" [3] Socket was connected: ", this.SocketArray);
      this.ReadParameters(socket.portindex);
    });

    socket.on('error', (error) => {
      console.log("Error open socket");
    });

    socket.on('disconnect', (reason) => {
      console.log("[***] Socket is disconnected", reason);
    });

    socket.on('command', (data) => {

      //console.log("Socket state ", socket.TESTSTATE, "]");
      if ((data.command != 'MotilityStatusCounter') && (data.command != 'ReadDatatatusCounter')) {
        firebase.analytics().logEvent('DriverEvent', { command: data.command });
      }


      switch (data.command) {

        case 'Disconnected':
          console.log("Device disconnected port=", socket['port'])
          break;

        case 'DeviceState':
          this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "DeviceState", value: data });
          break;


        case 'Startoperations':
          this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "Startoperations", value: data });
          break;


        case 'OperationStatus':
          this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "OperationStatus", value: data });
          break;

        case 'Finishoperations':
          this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "Finishoperations", value: data });
          break;

        case 'ReadSN':

          socket['SN'] = data.value;
          this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "ReadSN", value: data.value });
          this.ReadDeviceData(socket.SN, socket.portindex);
          console.log("Read SN", data.value, " from Port  ==>", socket.portindex)
          break;

        case "WriteMemoryCompleted":
          this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "WriteMemoryCompleted", value: data.value });
          console.log("Read memory comleted = ", data.value)
          break;

        case "SetSerialNumberCompleted":
          this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "SetSerialNumberCompleted", value: data.value });
          console.log("Save Serial number  = ", data.value)
          break;

        case "SetControlRef1Completed":
          this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "SetControlRef1Completed", value: data.value });
          console.log("ControlRef1 = ", data.value)
          break;

        case "SetControlRef2Completed":
          this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "SetControlRef2Completed", value: data.value });
          console.log("ControlRef2 = ", data.value)
          break;

        case "SetControlZLCompleted":
          this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "SetControlZLCompleted", value: data.value });
          console.log("ControlZL = ", data.value)
          break;

        case "EndInitDevice":
        case "DeviceReady":
          socket['ReadCalibraitonIsRequired'] = true;
          this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "DeviceReady", value: 0 });
          break;

        case "DeviceBussy":
          this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "DeviceBussy", value: 0 });
          console.log("Device Is Bussy ", socket.SN, socket.port)
          break;

        case 'MotilityStatusCounter':
          pvalue = Number(data.value.counter);
          //console.log("Progress ", pvalue)
          this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "Progress", value: pvalue, data: data.value });
          break;

        case 'ReadDatatatusCounter':
          pvalue = Number(data.value.counter);
          this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "Progress", value: pvalue, data: data.value });
          break;


        case 'ReadOnlineData':
          console.log("Online Data value = ", data.value, "channel = ", data.channel)
          this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "ReadDataRes", value: Number(data.value) });
          break;

        case 'ReadDataRes':
          pvalue = Number(data.value);
          dataValues = {"STATE": socket.TESTSTATE } 
          
          //switch (CurrentTestState) {
          switch (socket.TESTSTATE) {

            case TestState.IDLE:
              break;

            case TestState.TEST_FULL_OD_TEST:
              //socket.emit('command', { command: 'StopODChannel' });
              this.StartMotilityTest(16000, DELAY_BEFORE_MOTILITY_TEST, TestState.TEST_FULL_MOT_TEST, socket.portindex);

              break;

            case TestState.TEST_REFERENCE_OD_TEST:
              this.StartReferenceNoiseTest(socket.portindex);
              break;


            case TestState.TEST_READ_REF1:
              //this.ReadLedCurrent1(socket.portindex);              
              break;


            case TestState.TEST_READ_CAP:
                                           
              if (pvalue < 10) {
                  dataValues["isCap"] = capillaryStatus.CAP_IN_CHAMBER;
              } else if (pvalue > 1000) {
                  dataValues["isCap"] = capillaryStatus.CAP_NOT_IN_CHAMBER;
              } else {
                dataValues["isCap"] = capillaryStatus.CAP_UNKNOWN;
              }

              this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "ReadCap", value: pvalue , data: dataValues });

                //this.ReadLedCurrent1(socket.portindex);              
            break;              

            case TestState.TEST_READ_TEMP:
                //this.ReadLedCurrent1(socket.portindex);              
            break;   
            

            case TestState.TEST_READ_MOT_LED:
              //this.ReadLedCurrent2(socket.portindex);              
              break;

            case TestState.TEST_READ_CONC_LED:
              //this.ReadRef2(socket.portindex);              
              break;

            case TestState.TEST_READ_REF2:
              //if (socket.OnStartUpRunAll == true) {
              //  this.RunReferenceTest(socket.portindex)
              //}
              //else {
              // this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "MonitortestPointsCompleted", value: 0 });
              // this.ReadParameters(socket.portindex);
              //}
              break;

            case TestState.TEST_READ_ALL_REFFERENCES:
              this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "MonitortestPointsCompleted", value: 0 });
              //this.ReadParameters(socket.portindex);            
              break;

            case TestState.TEST_OD_REFERENCE:
              //socket.emit('command', { command: 'StopODChannel' });
              this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "ReadODReferenceCompleted", value: pvalue });
              socket.TESTSTATE = TestState.IDLE;
              //CurrentTestState = TestState.IDLE;
              break;

            case TestState.TEST_OD_TEST_ONLY:
              //socket.emit('command', { command: 'StopODChannel' });
              this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "ReadODCompleted", value: pvalue });
              //CurrentTestState = TestState.IDLE;

              socket.TESTSTATE = TestState.IDLE;
              this.ReadParameters(socket.portindex);
              break;

            default:

              break;
          }

          this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "ReadDataRes", value: pvalue , data: dataValues });
          //this.ReadParameters();
          break;

        case 'MotilityTest':

          switch (socket.TESTSTATE) {

            case TestState.IDLE:
              break;

            case TestState.TEST_FULL_MOT_TEST:
              this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "MotilityTestCompleted", value: data.value });
              //CurrentTestState = TestState.IDLE;
              socket.TESTSTATE = TestState.IDLE;
              break;

            case TestState.TEST_REFERENCE_NOISE_TEST:
              this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "ReferencetestCompleted", value: data.value });

              console.log("Start self test")
              //if (socket.OnStartUpRunAll == true) {
              //  this.StartSelftest(4000, socket.portindex);
              //} else {
              //CurrentTestState = TestState.IDLE;
              socket.TESTSTATE = TestState.IDLE;
              //}

              break;

            case TestState.TEST_MOT_TEST_ONLY:
              this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "MotilityTestCompleted", value: data.value });
              //CurrentTestState = TestState.IDLE;
              socket.TESTSTATE = TestState.IDLE;
              break;

            case TestState.TEST_NOISE_LEVEL:
              this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "NoiseTestCompleted", value: data.value });
              //CurrentTestState = TestState.IDLE;
              socket.TESTSTATE = TestState.IDLE;
              break;

            case TestState.TEST_SELF_TEST:
              console.log("Running self test for port", socket.port)
              //if (socket.OnStartUpRunAll == true) {
              //  this.ReadParameters(socket.portindex);
              //} else {
              //CurrentTestState = TestState.IDLE;
              socket.TESTSTATE = TestState.IDLE;
              //}

              this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "SelfTestCompleted", value: data.value });
              break;

          }

          this.ReadParameters(socket.portindex);

          break;

        case 'Parameters':

          if (data.value.LB_OD_AMP == undefined) {
            data.value.LB_OD_AMP = socket["LB_OD_AMP"]
          }

          if (data.value.amplitudeAMP == undefined) {
            data.value.amplitudeAMP = socket["amplitudeAMP"]
          }
        
          let Par = JSON.parse(JSON.stringify(data.value));
          Par = { ...Par, ...{ component: this.constructor.name } };
          //firebase.analytics().logEvent('Parameters', Par);

          socket.SN = data.value.serialNumber;

          if (socket['ReadCalibraitonIsRequired'] == true) {
            socket['ReadCalibraitonIsRequired'] = false;
            this.ReadDeviceData(socket.SN, socket.portindex);
          }


         

          this.Parameters[socket.portindex] = data.value;
          this.calParameters[socket.portindex] = this.getCalibratedParameters(data.value, socket.portindex);
          console.log(" [4] Read Paramaters portindex", socket.portindex, this.Parameters[socket.portindex], this.calParameters[socket.portindex])

          console.log("Read Self Test status - 1",this.Parameters[socket.portindex].SelfTest)
          this.Parameters[socket.portindex].SelfTest = this.Isselftest(socket.portindex);
          console.log("Read Self Test status - 2",this.Parameters[socket.portindex].SelfTest)

          this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "ReadParameters", value: data.value });


          //if (socket.OnStartUpRunAll == true) {
          //  socket.OnStartUpRunAll = false;
          //  this.onChange.emit({ port: socket.port, portindex: socket.portindex, command: "DeviceReady", value: 0 });
          //  console.log("Device is Ready after startup")
          //}

          socket.TESTSTATE = TestState.IDLE;
          //CurrentTestState = TestState.IDLE;
          break;

      }

    })

    // Initiate Timer Events
    this.oberserableTimer();
  }

  public IsDriverConnected() {
    return this.DriverIsConnected;
  }

  public getDateFormat() {
    this.deviceListSocket.emit('command', { command: 'dateFormat' });
  }

  public getDateFormatByAPI() {
    return this._apihttp.get(BASICURL + ':8000' + '/apicmd/getDateFormat');
  }


  public Isselftest(portindex: number = 0) {

    console.log("Checking self test ")    
  
    let OBParameters = this.Parameters[portindex];    
  
    if ((OBParameters.ref2 < 2500) || (OBParameters.ref2 > 3500)) {
      console.log("ref2")
      return 0;      
    }
     
    if ((OBParameters.amplitude < 50) || (OBParameters.amplitude > 150)) {
      console.log("amplitude")
      return 0;
    }
  
    if ((OBParameters.ledCurrent2 < 10) || (OBParameters.ledCurrent2 > 32)) {      
      console.log("led2")
      return 0;
    }
  
    if ((OBParameters.zeroLevel < 500) || (OBParameters.zeroLevel > 525)) {    
      console.log("ZL")
      return 0;
    }
  
    /*
    if ((OBParameters.count < 26) || (OBParameters.count > 36)) {      
      console.log("count")
      return 0;
    }
    */

    if ((OBParameters.ref1 < 150) || (OBParameters.ref1 > 350)) {      
      console.log("ref1")
      return 0;
    }
  
    if ((OBParameters.ledCurrent1 < 5) || (OBParameters.ledCurrent1 > 25)) {      
      console.log("led1")
      return 0;
    }
  

    return 1;
  
  }



  public ReadDeviceData(SerialNumber, portindex: number = 0) {

    console.log("Read Device Data", SerialNumber)

    this._http.getDevicedata(SerialNumber).then((data: any) => {
      console.log(SerialNumber)
      console.log("Device Data  = ", data);

      if (data.data["MSCFilter"] == undefined) {
        this.setMSCFilter(100, portindex);
      } else {
        this.setMSCFilter(data.data.MSCFilter, portindex);
      }

      if (data.data["LB_OD_AMP"] == undefined) {
        this.setLBODAMP(1000, portindex);
      } else {
        this.setLBODAMP(data.data.LB_OD_AMP, portindex);
      }
      

      if (data.data["amplitudeAMP"] == undefined) {
        this.setamplitudeAMP(100, portindex);
      } else {
        this.setamplitudeAMP(data.data.amplitudeAMP, portindex);
      }


      if (data.data["COUNTFilter"] == undefined) {
        this.setCOUNTFilter(28, portindex);
      } else {
        this.setCOUNTFilter(data.data.COUNTFilter, portindex);
      }


      if (data.data["signalFilter"] == undefined) {
        this.SetSignalFilter({ k: 1, a: 1 }, portindex);
      } else {
        this.SetSignalFilter(data.data.signalFilter, portindex);
      }

      if (data.data["isReference"] != undefined) {
        this.SocketArray[portindex].isReference = data.data["isReference"];
      }

      if (data.data.calibration != undefined) {
        if (data.data.calibration["QC"] == undefined) {
          data.data.calibration["QC"] = [0, 1, 0];
        }
        this.SocketArray[portindex].calibration = data.data.calibration;
        console.log(" [5] Load Calibration ", this.SocketArray[portindex].calibration)
      }

      //this.calParameters[portindex] = this.getCalibratedParameters(data.value,portindex);
      this.updateCalParameters(portindex);

    });

  }


  public Polyval(Poly, value) {

    let results = 0;

    if (value > 0) {
      for (let i = 0; i < Poly.length; i++) {
        results = results + Poly[i] * Math.pow(value, (Poly.length - i - 1));
      }
    }
    return results;

  }

  public PolyDerval(Poly, value) {

    let results = 0;
    let degree = 0;
    if (value > 0) {
      for (let i = 0; i < Poly.length; i++) {
        degree = (Poly.length - i - 1);
        results = results + degree * Poly[i] * Math.pow(value, (degree - 1));
      }
    }
    return results;

  }



  public getCalibratedParameters(readParameters, portindex: number = 0) {

    var calPar = { ...readParameters };

    console.log(" [5] ReadParameters Calibration = ", this.SocketArray[portindex].calibration)

    calPar.od = this.Polyval(this.SocketArray[portindex].calibration.OD, readParameters.od);
    calPar.average = this.Polyval(this.SocketArray[portindex].calibration.average, readParameters.average);
    calPar.count = this.Polyval(this.SocketArray[portindex].calibration.count, readParameters.count);
    calPar.QCod = this.Polyval(this.SocketArray[portindex].calibration.QC, calPar.od);

    if (calPar.od < 0) { calPar.od = 0; }
    if (calPar.average < 0) { calPar.average = 0; }
    if (calPar.count < 0) { calPar.count = 0; }
    if (calPar.QCod < 0) {calPar.QCod = 0}
    
    this.calParameters[portindex] = calPar;

    return calPar;

  }


  testSelfTest(portindex: number = 0) {
    this.SocketArray[portindex].emit('command', { command: 'testselfTest' });
  }


  public getNoisePoints() {
    return NOISE_TEST_POINTS;
  }

  public getCurrentState(portindex: number = 0) {
    return this.SocketArray[portindex].TESTSTATE;
    //return CurrentTestState;
  }

  public getCurrentClient(portindex: number = 0) {
    return CurrentActiveClient;
  }

  public setCurrentClient(_CurrentActiveClient, portindex: number = 0) {
    CurrentActiveClient = _CurrentActiveClient;
  }

  /*
    public addDevice() {
      this.Definelistener();
      console.log(this.SocketArray)
    }
  */
  public clearAllSockets() {

    for (let i = 0; i < this.SocketArray.length; i++) {
      this.SocketArray[i].disconnect();
    }

    this.SocketArray = [];

  }


  public IsDeviceBussy(portindex: number = 0) {
    return this._apihttp.get(this.SocketArray[portindex].url + '/apicmd/bussy');
  }
  public getDeviceList() {
    this.deviceListSocket.emit('command', { command: 'getDeviceList' });
  }

  public initDevice(portindex: number = 0) {
    this.SocketArray[portindex].emit('command', { command: 'initDevice' });
  }

  public getParameters(portindex: number = 0) {
    return this.Parameters[portindex];
  }

  public updateCalParameters(portindex: number = 0) {

    let calPar: any;

    // for (let i=0;i<this.SocketArray.length;i++) {

    this.calParameters[portindex].od = this.Polyval(this.SocketArray[portindex].calibration.OD, this.Parameters[portindex].od);
    this.calParameters[portindex].average = this.Polyval(this.SocketArray[portindex].calibration.average, this.Parameters[portindex].average);
    this.calParameters[portindex].count = this.Polyval(this.SocketArray[portindex].calibration.count, this.Parameters[portindex].count);

    if (this.calParameters[portindex].od < 0) { this.calParameters[portindex].od = 0; }
    if (this.calParameters[portindex].average < 0) { this.calParameters[portindex].average = 0; }
    if (this.calParameters[portindex].count < 0) { this.calParameters[portindex].count = 0; }

    console.log("Read Parameters comm : ", this.calParameters[portindex].average, this.SocketArray[portindex].calibration)


    //  }
    calPar = this.calParameters;

    return (calPar);

  }

  public getCalParameters(portindex: number = 0) {
    return this.calParameters[portindex];
  }


  public getpValue() {
    return pvalue;
  };


  public ReadParameters(portindex: number = 0) {
    this.SocketArray[portindex].emit('command', { command: 'ReadParameters' });
  };


  public StartReadOnline(addr, portindex: number = 0) {
    this.SocketArray[portindex].emit('command', { command: 'StartReadOnline', 'addr': addr });

  }

  public StopReadOnline(addr, portindex: number = 0) {
    this.SocketArray[portindex].emit('command', { command: 'StopReadOnline', 'addr': addr });
  }


  public StartMotilityTestOnly(nPoints, delaybeforestart, portindex: number = 0) {

    this.StartMotilityTest(nPoints, delaybeforestart, TestState.TEST_MOT_TEST_ONLY, portindex);

  }

  public ODLightOn(portindex: number = 0) {
    this.SocketArray[portindex].emit('command', { command: 'ODLightOn', value: 1 });
  }

  public ODLightOff(portindex: number = 0) {
    this.SocketArray[portindex].emit('command', { command: 'ODLightOff', value: 0 });
  }


  public setState(RequiredState, portindex: number = 0) {
    //CurrentTestState = RequiredState;
    this.SocketArray[portindex].TESTSTATE = RequiredState;
  }


  public StartMotilityTest(nPoints, delaybeforestart, TestType, portindex: number = 0) {
    //CurrentTestState = TestType;
    this.SocketArray[portindex].TESTSTATE = TestType;

    this.ODLightOff(portindex);

    this.delay(delaybeforestart).then(any => {
      console.log("Start");
      pvalue = 0;
      this.SocketArray[portindex].emit('command', { command: 'StartMotilitytest', points: nPoints });
      console.log("points:", nPoints);
    });

  }

  public StartSelftest(npoints, portindex: number = 0) {
    //CurrentTestState = TestState.TEST_SELF_TEST;
    this.SocketArray[portindex].TESTSTATE = TestState.TEST_SELF_TEST;

    console.log("Start Self test operation ", npoints);

    this.delay(100).then(any => {
      pvalue = 0;
      this.SocketArray[portindex].emit('command', { command: 'StartSelftest' });
    });
  }


  public StartautoCalibration(npoints, portindex: number = 0) {
    //CurrentTestState = TestState.TEST_SELF_TEST;
    this.SocketArray[portindex].TESTSTATE = TestState.TEST_SELF_TEST;

    console.log("Start auto calibration operation ");

    this.delay(100).then(any => {
      pvalue = 0;
      this.SocketArray[portindex].emit('command', { command: 'AutoCalibration' });
    });
  }




  public StartShortSelftest(nPoints, portindex: number = 0) {
    //CurrentTestState = TestState.TEST_SELF_TEST;
    this.SocketArray[portindex].TESTSTATE = TestState.TEST_SELF_TEST;

    console.log("Start Self test");

    this.delay(500).then(any => {
      pvalue = 0;
      this.SocketArray[portindex].emit('command', { command: 'ShortSelftest', points: nPoints });
      console.log("points:", nPoints);
    });
  }

  public SaveTestfile(IsFileToSave, portindex: number = 0) {
    if (this.SocketArray.length > 0) {
      this.SocketArray[portindex].emit('command', { command: 'Savetestfile', value: IsFileToSave });
    }
  }

  public setMSCFilter(MSCFilterValue, portindex: number = 0) {
    this.delay(100).then(any => {
      this.SocketArray[portindex].emit('command', { command: 'setMSCFilter', value: MSCFilterValue });
    });
  }

  public setCOUNTFilter(COUNTFilterValue, portindex: number = 0) {
    this.delay(100).then(any => {
      this.SocketArray[portindex].emit('command', { command: 'setCOUNTFilter', value: COUNTFilterValue });
    });
  }


  public setLBODAMP(LBODAMPValue, portindex: number = 0) {
    
    this.SocketArray[portindex]['LB_OD_AMP'] = LBODAMPValue;

    this.delay(100).then(any => {
      this.SocketArray[portindex].emit('command', { command: 'setLBODAMP', value: LBODAMPValue });
    });
  }


  public setamplitudeAMP(amplitudeAMP, portindex: number = 0) {

    this.SocketArray[portindex]['amplitudeAMP'] = amplitudeAMP;
 
    this.delay(100).then(any => {
      this.SocketArray[portindex].emit('command', { command: 'setamplitudeAMP', value: amplitudeAMP });
    });
  }



  public SetSignalFilter(FilterValue, portindex: number = 0) {
    this.delay(100).then(any => {
      this.SocketArray[portindex].emit('command', { command: 'SetSignalFilter', value: FilterValue });
    });
  }

  public SetControlRef1(CtrlRef1Value, portindex: number = 0) {
    this.delay(500).then(any => {
      this.SocketArray[portindex].emit('command', { command: 'SetControlRef1', value: CtrlRef1Value });
    });
  }

  public SetControlRef2(CtrlRef2Value, portindex: number = 0) {
    this.delay(500).then(any => {
      this.SocketArray[portindex].emit('command', { command: 'SetControlRef2', value: CtrlRef2Value });
    });
  }


  public SetControlZL(CtrlZLValue, portindex: number = 0) {
    this.delay(500).then(any => {
      this.SocketArray[portindex].emit('command', { command: 'SetControlZL', value: CtrlZLValue });
    });
  }


  public SetAutomaticZL() {

  }

  public StopTest(portindex: number = 0) {
    this.SocketArray[portindex].emit('command', { command: 'StopMotilitytest' });
    console.log('StopMotilitytest');
  }

  public ReadValue(addr: any, portindex: number = 0) {

    this.SocketArray[portindex].TESTSTATE = TestState.TEST_READ_VALUE;

    console.log("Start Reading Data value")
    this.SocketArray[portindex].emit('command', { command: 'Readvalue', 'addr': addr });
  };


  public ReadCapSensor(portindex: number = 0) {

    this.SocketArray[portindex].TESTSTATE = TestState.TEST_READ_CAP;

    console.log("Start Reading Capillary sensor")
    this.delay(DELAY_BEFORE_MONITOR).then(any => {
      this.SocketArray[portindex].emit('command', { command: 'ReadCap', 'addr': 0 });
    });
  };

  public ReadTemperature(portindex: number = 0) {

    this.SocketArray[portindex].TESTSTATE = TestState.TEST_READ_TEMP;

    console.log("Start Reading temperature")
    this.delay(DELAY_BEFORE_MONITOR).then(any => {
      this.SocketArray[portindex].emit('command', { command: 'ReadTemperature', 'addr': 7 });
    });
  };

  public ReadRef1(portindex: number = 0) {

    this.SocketArray[portindex].TESTSTATE = TestState.TEST_READ_REF1;

    console.log("Start Reading REf1 value")
    this.delay(DELAY_BEFORE_MONITOR).then(any => {
      this.SocketArray[portindex].emit('command', { command: 'ReadRef1', 'addr': 1 });
    });
  };

  public ReadLedCurrent1(portindex: number = 0) {

    this.SocketArray[portindex].TESTSTATE = TestState.TEST_READ_MOT_LED;

    console.log("Start Reading Led Current 1 value")
    this.delay(DELAY_BEFORE_MONITOR).then(any => {
      this.SocketArray[portindex].emit('command', { command: 'ReadLedcurrent1', 'addr': 6 });
    });
  };

  public ReadLedCurrent2(portindex: number = 0) {

    this.SocketArray[portindex].TESTSTATE = TestState.TEST_READ_CONC_LED;

    console.log("Start Reading Led Current 2 value")
    this.delay(DELAY_BEFORE_MONITOR).then(any => {
      this.SocketArray[portindex].emit('command', { command: 'ReadLedcurrent2', 'addr': 3 });
    });
  };

  public ReadRef2(portindex: number = 0) {

    this.SocketArray[portindex].TESTSTATE = TestState.TEST_READ_REF2;

    this.SocketArray[portindex].emit('command', { command: 'ActivateODChannel' });

    this.ODLightOn(portindex);
    this.delay(DELAY_BEFORE_MONITOR).then(any => {
      this.SocketArray[portindex].emit('command', { command: 'ReadRef2', 'addr': 2 });
    });

  }

  public MonitortestPoints(portindex: number = 0) {
    this.SocketArray[portindex].TESTSTATE = TestState.TEST_READ_ALL_REFFERENCES;
    this.SocketArray[portindex].emit('command', { command: 'Readreferences' });
  }

  public Readreferences(portindex: number = 0) {
    //TEST_REFERENCE;
  }



  public ReadODreference(portindex: number = 0) {

    this.SocketArray[portindex].TESTSTATE = TestState.TEST_OD_REFERENCE;

    this.SocketArray[portindex].emit('command', { command: 'ActivateODChannel' });

    this.ODLightOn(portindex);
    this.delay(1000).then(any => {
      this.SocketArray[portindex].emit('command', { command: 'ReadODreference' });
    });
  }


  public ReadNoiseLevel(portindex: number = 0) {
    //CurrentTestState = TestState.TEST_NOISE_LEVEL;
    this.SocketArray[portindex].TESTSTATE = TestState.TEST_NOISE_LEVEL;
    //this.SocketArray[portindex].emit('command', { command: 'StopODChannel' });

    this.ODLightOff(portindex);

    this.delay(DELAY_BEFORE_MOTILITY_TEST).then(any => {
      pvalue = 0;
      let nPoints = NOISE_TEST_POINTS;
      this.SocketArray[portindex].emit('command', { command: 'StartNoiseReading', points: nPoints });
    });
  }




  public StartReferenceNoiseTest(portindex: number = 0) {

    //500, DELAY_BEFORE_MOTILITY_TEST, TestState.TEST_REFERENCE_MOT_TEST, 

    //CurrentTestState = TestState.TEST_REFERENCE_NOISE_TEST;
    this.SocketArray[portindex].TESTSTATE = TestState.TEST_REFERENCE_NOISE_TEST;

    this.ODLightOff(portindex);

    this.delay(1000).then(any => {
      pvalue = 0;
      let nPoints = NOISE_TEST_POINTS;
      this.SocketArray[portindex].emit('command', { command: 'StartNoiseReading', points: nPoints });
    });

  }

  public RunReferenceTest(portindex: number = 0) {

    //CurrentTestState = TestState.TEST_REFERENCE_OD_TEST;
    this.SocketArray[portindex].TESTSTATE = TestState.TEST_REFERENCE_OD_TEST;

    this.ODLightOn(portindex);

    this.delay(DELAY_BEFORE_CONC_TEST).then(any => {
      this.SocketArray[portindex].emit('command', { command: 'ReadODreference' });
    });


  }


  public RunTest(portindex: number = 0) {

    //CurrentTestState = TestState.TEST_FULL_OD_TEST;
    this.SocketArray[portindex].TESTSTATE = TestState.TEST_FULL_OD_TEST;

    //this.SocketArray[portindex].emit('command', { command: 'ActivateODChannel' });

    this.ODLightOn(portindex);  // Turn on OD Led before concentration measurement  

    this.delay(DELAY_BEFORE_CONC_TEST).then(any => {
      this.SocketArray[portindex].emit('command', { command: 'StartConcTest' });
    });

  }

  public RunConcentrationtestOnly(portindex: number = 0) {

    this.SocketArray[portindex].TESTSTATE = TestState.TEST_OD_TEST_ONLY;
    this.ODLightOn(portindex);  // Turn on OD Led before concentration measurement  

    this.delay(DELAY_BEFORE_CONC_TEST).then(any => {
      this.SocketArray[portindex].emit('command', { command: 'StartConcTest' });
    });

  }



  public readSerialNum(portindex: number = 0) {

    console.log("Request SN", this.SocketArray[portindex]);
    //CurrentTestState = TestState.TEST_READ_SN;
    this.SocketArray[portindex].TESTSTATE = TestState.TEST_READ_SN;

    this.SocketArray[portindex].emit('command', { command: 'ReadSerial' });


  }

  public writeSerialNum(SN: string, portindex: number = 0) {

    console.log("Request SN", this.SocketArray[portindex]);

    //CurrentTestState = TestState.TEST_READ_SN;
    this.SocketArray[portindex].TESTSTATE = TestState.TEST_SAVE_SN;

    this.SocketArray[portindex].emit('command', { command: 'WriteSerial', addr: 0, value: SN });

  }



  oberserableTimer() {
    const TimerHandler = this.source.subscribe(val => {
      this.TimerEvents();
    });
  }


  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }



  TimerEvents() {
    this.counters.COUNTER_1++;

    if (this.counters.COUNTER_1 > 10) {
      this.counters.COUNTER_1 = 0;
      this.counters.COUNTER_2++;

      this.timerchange.emit({ countertype: 1, value: this.counters });

    }

    if (this.counters.COUNTER_2 > 10) {
      this.counters.COUNTER_2 = 0;
      this.counters.COUNTER_3++;

      this.timerchange.emit({ countertype: 2, value: this.counters });
      if (this.disconnectionflag == 1) {
        this.counters.DISCONNECT_COUNTER++;
        if (this.counters.DISCONNECT_COUNTER > 2) {
          console.log(" [1_2] Driver is disconnected");
          this.DriverIsConnected = false;
          this.onChange.emit({ port: 8000, portindex: -1, command: "isConnect", value: false });
          this.disconnectionflag = 0;
          this.counters.DISCONNECT_COUNTER = 0;
        }
      }
      //console.log("timer",this.counters.COUNTER_3)
    }

    if (this.counters.COUNTER_3 > 10) {
      this.counters.COUNTER_3 = 0;

      this.timerchange.emit({ countertype: 3, value: this.counters });

    }



  }





}
