import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'; 

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./layout/header/header.component";
import { PageLoaderComponent } from "./layout/page-loader/page-loader.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { RightSidebarComponent } from "./layout/right-sidebar/right-sidebar.component";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { DynamicScriptLoaderService } from "./Services/dynamic-script-loader.service";

import {HttpService} from './Services/http.service';
import {CommunicatorService} from './Services/communication.service';

import { AlgorithmService, Results, TestSetup, SAMPLETYPE, TESTTYPE, WBCConcStd, ChamberStd, MorphCriteria, LES, KeyParameters, Debris_Scan } from './Services/algorithm.service'

import { CoreModule } from './core/core.module';
import { UserProfileComponent } from './user-profile/user-profile.component';

import { GoogleChartsModule } from 'angular-google-charts';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    UserProfileComponent,
    
  ],
  imports: [
    BrowserModule,
    GoogleChartsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    CoreModule,
    HttpClientModule,

  ],
  providers: [HttpService, CommunicatorService,  AlgorithmService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    DynamicScriptLoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
