import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
//import { AngularFireStorage } from '@angular/fire/storage';
import { EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { TestCredit, Request, CreditBatch, Device, SQACustomer, ControlsBatch, ProficiencyBatch } from '../globals/globals';
import { AuthService } from '../core/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { EmailNotAvailableError } from '../_helpers/email-not-available-error';
import { formatDate } from '@angular/common';
//import { AuthService } from '../core/auth.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*,Origin, Content-Type, X-Auth-Token,AUTHORIZATION',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
  })
};


export class TestUpdateEvent {
  data: any;
  changeType: string;
}


@Injectable({
  providedIn: 'root'
})



export class HttpService {

  db = firebase.firestore();
  realtimefacilities: Observable<any[]>;
  currentAdmin = {
    uid: '',
    email: '',
    name: '',
    adminType: ''
  };

  onTestDataChange: EventEmitter<TestUpdateEvent> = new EventEmitter<TestUpdateEvent>();
  private apiEP: string;

  constructor(private _http: HttpClient, private afAuth: AngularFireAuth, private afs: AngularFirestore, private authService: AuthService) {

    const hostname = window.location.hostname.toString().toLowerCase();
    if (hostname.includes('localhost')) {
      this.apiEP = 'api'; //"api/backup"
    } else {
      this.apiEP = "https://us-central1-messqasystem.cloudfunctions.net"; // https://us-central1-messqasystem.cloudfunctions.net/backup
    }
    // this.realtimefacilities = this.afs.collection<any>('SQACustomers').valueChanges();
    // this.realtimefacilities.subscribe(rt => {
    //   console.log('real time facilities ', rt);
    // })
    // this.afs.collectionGroup<any>('Tests').valueChanges();
    this.authService.user.subscribe((user) => {
      if (user !== null && user !== undefined) {
        this.currentAdmin.uid = user.uid;
        this.currentAdmin.email = user.email;
        this.currentAdmin.name = user.firstName + ' ' + user.lastName;
        this.currentAdmin.adminType = user.adminType;
        console.log('http id', this.currentAdmin);
      }
    });

  }


  // ====================================================================================================
  // Global and Generic functions
  // ====================================================================================================
  public getCollectionList(collectionpath) {

    // query => {property, value}   i.e. {property: "age"}
    const markers: any = [];

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).get().subscribe(snapshot => {
        snapshot.forEach(doc => {
          markers.push({ id: doc.id, data: doc.data() });

        });
        //return markers;
        res(markers);
        console.log('success')
      })
    })
  }


  public getCollectionData(collectionpath, docid) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).doc(docid).get().subscribe((doc) => {
        if (doc.exists) {
          res({ id: doc.id, data: doc.data() });
        }
        else {
          console.log("No such document!");
          res({ id: undefined, data: undefined });
        }
      });
    });

  }

  public addCollectionItam(collectionpath, data) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).add(data)
        .then((doc) => {
          console.log({ "id": doc.id, "data": data });
          res(doc);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });


  }

  public updateCollectionData(collectionpath, Itemid, itemData) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).doc(Itemid).set(itemData, { merge: true })
        .then((docRef) => {
          console.log({ "collectionPath": collectionpath, "id": Itemid, "data": itemData });
          res({ id: Itemid, data: itemData });
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });
  }

  public deleteCollectionItem(collectionpath, Itemid) {
    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).doc(Itemid).delete()
        .then((docRef) => {
          console.log({ "collectionPath": collectionpath, "id": Itemid });
          res(docRef);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });

  }

  public getTotalNumber(collection) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collection).get()
        .subscribe(async function (querySnapshot) {
          let totalRequests = querySnapshot.docs.length;
          res({ number: totalRequests });
        });
    });

  }

  private uploadFile(file, ref, filetype: string = 'Image') {

    return new Promise<any>((res, rej) => {
      if (file.type.includes(filetype)) {
        ref.put(file).then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            res(url);
          }).catch(err => {
            rej(err);
          });
        });
      } else {
        rej("error");
      }

    });

  }

  private uploadBase64(base64Data, ref, fileName) {

    console.log(fileName); 
    const base64 = base64Data.split(",")[1];

    return new Promise<any>((res, rej) => {
     
        ref.putString(base64, 'base64').then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            console.log(url);
            res(url);
          }).catch(err => {
            rej(err);
          });
        });
     
    });


    
    // return new Promise<any>((res, rej) => {
      
    //   const uploadTask = ref.putString(base64, 'base64');
      
    //   uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
    //     (snapshot) => {

    //       const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //       console.log('Upload is ' + progress + '% done');

    //       if(progress == 100){

    //         snapshot.ref.getDownloadURL().then((url) => {            
    //           console.log(url);
    //           res(url);
    //         }).catch(err => {
    //           rej(err);
    //         });

    //       }
    //     },
    //     (error) => {
    //       // upload failed
    //       console.log(error)
    //     },
    //     (snapshot) => {}
        
    //   );
      
    // });

  }

  
  public deleteFileFromStorage(fileName: string){
    return new Promise<any>(async (resolve, reject) => {
      var ref = await firebase.storage().ref().child(fileName).delete().then((res) => {
        resolve(ref);
      }).catch(err => {
        reject(err);
      });
    });
  }


  public getFileUrl(fileName) {
    return new Promise<any>((res, rej) => {
      var ref = firebase.storage().ref().child(`${fileName}`);
      ref.getDownloadURL().then((url) => {
        res(url);
      }).catch(err => {
        rej(err);
      });
    });
  }


  public getSignalData(url) {

    return new Promise<any>((res, rej) => {
      this._http.get(url, { responseType: 'text' }).subscribe((data) => {
        res(data);
      }
      )

    });

    //return this._http.get(url,{responseType: 'text'});

  }

  

  public async UploadBase64Video(currentFacilityId, file: File, data) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(`Visualization/Videos/${currentFacilityId}/` + data.fileName);

    const res = await this.addCollectionItam('SQACustomers/' + currentFacilityId + '/Videos', data);
    
    return this.uploadBase64(file, ref, data.fileName);

  }

  public async UploadBase64Image(currentFacilityId, file: File, data) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(`Visualization/Images/${currentFacilityId}/` + data.fileName);

    const res = await this.addCollectionItam('SQACustomers/' + currentFacilityId + '/Images', data);

    return this.uploadBase64(file, ref, res.id+".png");

  }

  public UploadVideo(currentFacilityId, file: File, data) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(`Visualization/Videos/${currentFacilityId}/` + file.name);

    this.addCollectionItam('SQACustomers/' + currentFacilityId + '/Videos', data);

    return this.uploadFile(file, ref, 'video/mp4');

  }

  public UploadImage(currentFacilityId, file: File, data) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(`Visualization/Images/${currentFacilityId}/` + file.name);

    this.addCollectionItam('SQACustomers/' + currentFacilityId + '/Images', data);

    return this.uploadFile(file, ref, 'image/jpeg');

  }


  public UploadSignal(path, signalData) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(path);

    // ref.put(signalData)

    return new Promise<any>((res, rej) => {
      ref.putString(signalData).then((snapshot) => {
        snapshot.ref.getDownloadURL().then((url) => {
          res(url);
        }).catch(err => {
          rej(err);
        });
      });
    });

    //return this.uploadFile(file, ref,'signal');

  }



  // ====================================================================================================
  // End of Global and Generic functions
  // ====================================================================================================

  /*
    public updateItemDatainCollection1(collection1, Itemid, data) {

      let url = "https://us-central1-messqasystem.cloudfunctions.net/UpdateCollectionDataLevel_1?collection1=" + collection1 + "&collection1id=" + Itemid;

      return this._http.post(url, data)
        .subscribe(
          res => {
            console.log(res);
          },
          err => {
            console.log("Error occured");
          }
        )

    }
  */

  // ============================

  public UpdateFacilityType(currentFacilityId, facilityType: string) {
    let data = { customerType: facilityType };
    this.updateCollectionData('SQACustomers', currentFacilityId, data);
  }

  public UpdateFacilityProfile(currentFacilityId, data) {
    return this.updateCollectionData('SQACustomers', currentFacilityId, data);
  }

  public gettestsForPatient(currentFacilityId, patientid) {
    // let url = "https://us-central1-messqasystem.cloudfunctions.net/getAllTestsforSelectedPatientinFacility?id=" + currentFacilityId + "&patientid=" + patientid;
    // return this._http.get(url);
    return this.getCollectionList('SQACustomers/' + currentFacilityId + '/patients/' + patientid + '/tests');

  }

  public getControltests(currentFacilityId) {
    // let url = "https://us-central1-messqasystem.cloudfunctions.net/getAllTestsforSelectedPatientinFacility?id=" + currentFacilityId + "&patientid=" + patientid;
    // return this._http.get(url);
    return this.getCollectionList('SQACustomers/' + currentFacilityId + '/controlTests');

  }

  public getallPatientsForFacility(currentFacilityId) {
    // let url = "https://us-central1-messqasystem.cloudfunctions.net/getAllPatientsforSelectedFacility?id=" + currentFacilityId;
    // return this._http.get(url);
    return this.getCollectionList('SQACustomers/' + currentFacilityId + '/patients');
  }

  public getAllTestsForFacility(currentFacilityId) {
    // let url = `https://us-central1-messqasystem.cloudfunctions.net/getNestedCollection?id=${facilityId}&maincollection=patients&subcollection=tests`;
    // return this._http.get(url);
    // return new Promise<any>((res, rej) => {

    //   this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').get()
    //     .then(async patientsSnapshot => {
    //       let markers: any = [];
    //       for (const patient of patientsSnapshot.docs) {
    //         await this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').doc(patient.id).collection('tests').get()
    //           .then(async testSnapshot => {
    //             for (const test of testSnapshot.docs) {
    //               if (test.id !== 'stats') {
    //                 await markers.push({ id: patient.id, data: patient.data(), testid: test.id, testdata: test.data() });
    //               }
    //             }
    //           }).catch(error => {
    //             console.log("Error getting sub-collection documents", error);
    //             rej(error);
    //           })
    //       }
    //       res(await markers);
    //       console.log(await 'success')
    //     })
    //     .catch(error => {
    //       console.log("Error getting documents: ", error);
    //       rej(error);
    //     });
    // });

    return new Promise<any>((res, rej) => {
      let markers: any = [];
      this.db.collectionGroup('tests').where('facilityId', '==', currentFacilityId).get()
        .then(async tests => {
          if (tests.empty) {
            console.log('No matching documents.');
            // rej('No tests found.');
          }
          for (const test of tests.docs) {
            await markers.push({ testid: test.id, testdata: test.data() });
          }
          res(await markers);
        }).catch(err => {
          rej(err);
        })

    });
  }

  public getFacilityData(currentFacilityId) {
    return this.getCollectionData('SQACustomers', currentFacilityId);
  }

  public getallCustomers() {
    return this.getCollectionList("SQACustomers")
  }

  public getCustomersDistributors(customerTypes: string[] = ['facility']) {

    // query => {property, value}   i.e. {property: "age"}
    const customers: any = [];
    const distribs:  any = [];

    return new Promise<any>((res, rej) => {
      this.db.collection('Distributers').get().then(dSnapshot => {
        dSnapshot.forEach(ddoc => {
          let data = ddoc.data();
          data.id = ddoc.id;
          distribs.push(data);
        })
        console.log('distribs ', distribs);
        return distribs;
      }).then(distributers => {
      this.db.collection('SQACustomers').where("customerType", "in", customerTypes).get().then(snapshot => {
        snapshot.forEach(doc => {

          let addData = doc.data();
          if(doc.data().servicePersons !== undefined && doc.data().servicePersons.length > 0) {
            addData.distributers = doc.data().servicePersons.map(pp => ({
              id: pp.id, support: pp.support, order: pp.order, data: distributers.find(obj => {
                return obj.id === pp.id
              })
            }));
          } else {
            addData.distributers = "NA";
          }
          customers.push({ id: doc.id, data: addData });

        });
        //return markers;
        res(customers);
      })
    })
    })
  }

  public UpdatePatientData(currentFacilityId, patientid, data) {
    let path = 'SQACustomers/' + currentFacilityId + '/patients/';
    this.updateCollectionData(path, patientid, data);

  }

  public async DeleteFacilityWithUsers(currentFacilityId) {
    const users = await this.getUsersForFacility(currentFacilityId);
    console.log('facility users ', users);
    await users.forEach(async user => {
      await this.deleteSQAUser(user.id);
    });
    let url = this.apiEP + "/deleteFacilityUserWithAuth?id=" + currentFacilityId.toString();
    return new Promise((res, rej) => {
      firebase.auth().currentUser.getIdToken().then(async token => {
        const headers = new HttpHeaders ({'Authorization': 'Bearer ' + token});

        this._http.get(url, { headers: headers }).toPromise().then(user => {
          res(user);
        }).catch(err => {
          rej(err);
        });

      });
    })
  }

  public DeletePatient(currentFacilityId, patientid) {
    return new Promise<any>((res, rej) => {

      const statsRef = this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').doc('stats');
      const increment = firebase.firestore.FieldValue.increment(-1);
      const batch = this.db.batch();
      const reqRef = this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').doc(patientid.toString());
      batch.delete(reqRef);
      batch.set(statsRef, { patientCount: increment }, { merge: true });
      batch.commit().then(val => {
        console.log('batch res ', val);
        res(val);
      }).catch(err => {
        rej(err);
      });
    });
  }

  public DeleteTest(currentFacilityId, patientid, testid) {
    return new Promise<any>((res, rej) => {

      const statsRef = this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').doc('stats');
      const statsRef2 = this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').doc(patientid.toString()).collection('tests').doc('stats');
      const increment = firebase.firestore.FieldValue.increment(-1);
      const batch = this.db.batch();
      const reqRef = this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').doc(patientid.toString()).collection('tests').doc(testid.toString());
      batch.delete(reqRef);
      batch.set(statsRef, { testCount: increment }, { merge: true });
      batch.set(statsRef2, { testCount: increment }, { merge: true });
      batch.commit().then(val => {
        console.log('batch res ', val);
        res(val);
      }).catch(err => {
        rej(err);
      });
    });
  }


  UploadProfilePicture(currentFacilityId, file: File) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(`${currentFacilityId}/profileimg.jpg`);

    return new Promise<any>((res, rej) => {
      ref.put(file).then((snapshot) => {
        snapshot.ref.getDownloadURL().then((url) => {
          res(url);
        }).catch(err => {
          rej(err);
        });
      });
    });

  }

  public getDevicedata(deviceID) {
    return this.getCollectionData("Devices", deviceID)
  }

  public updateDevicedata(deviceID, data) {
    this.updateCollectionData('Devices', deviceID, data);
  }


  // =================
  // Filtercalibration
  // =================
  public addFilterCalibration(data) {
    this.addCollectionItam('FilterCalibrations', data);
  }

  public addDevicetoFilterCalibration(calibrationid, deviceid: string, data) {
    this.updateCollectionData('FilterCalibrations/' + calibrationid + '/Devices/', deviceid, data);
  }

  public addMeasurementtoFilterCalibration(calibrationid, deviceid: string, data) {
    let path = 'FilterCalibrations/' + calibrationid + '/Devices/' + deviceid + '/measurements';
    return this.addCollectionItam(path, data);

  }

  // Two level functions that currently cannot be replaced with simple global functions
  public getFilterCalibrationmeasurements(calibrationid) {
    // let url = "https://us-central1-messqasystem.cloudfunctions.net/getAllCalibrationMeasurements?id=" + calibrationid;
    // return this._http.get(url);

    return new Promise<any>((res, rej) => {
      let markers: any = [];

      this.db.collection('FilterCalibrations').doc(calibrationid).collection('Devices').get()
        .then(async querySnapshot => {

          for (const doc of querySnapshot.docs) {

            await this.db.collection('FilterCalibrations').doc(calibrationid).collection('Devices').doc(doc.id).collection('measurements').get()
              .then(async querySnapshot2 => {

                querySnapshot2.forEach(measuredoc => {
                  markers.push({ Calibrationid: calibrationid, Deviceid: doc.id, measurementid: measuredoc.id, data: measuredoc.data() });
                });

              }).catch(error => {
                console.log("Error getting sub-collection documents", error);
                rej(error);
              })
          }
          res(await markers);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
          rej(error);
        });
    });

  }


  public DeleteFilterMeasurement(calibrationid, deviceid, measurementid) {
    let path = 'FilterCalibrations/' + calibrationid + '/Devices/' + deviceid + '/measurements';
    this.deleteCollectionItem(path, measurementid);
  }

  public getFilterCalibrations() {
    return this.getCollectionList("FilterCalibrations");
  }


  public getDevicesinFilterCalibration(calibrationid) {
    return this.getCollectionList("FilterCalibrations/" + calibrationid + "/Devices")
  }

  public UpdateFilterCalibrations(calibrationid, data) {
    this.updateCollectionData('FilterCalibrations', calibrationid, data);
  }






  // ==================================  end Fiilter calibration


  // ===================
  // Calibration section
  // ===================
  public addCalibration(data) {
    this.addCollectionItam('Calibrations', data);
  }

  public addClinicalAnalysis(data) {
    this.addCollectionItam('ClinicalAnalysis', data);
  }


  public addDevicetoCalibration(calibrationid, deviceid: string, data) {
    this.updateCollectionData('Calibrations/' + calibrationid + '/Devices/', deviceid, data);
  }

 
  public addMeasurementtoCalibration(calibrationid, deviceid: string, data) {
    let path = 'Calibrations/' + calibrationid + '/Devices/' + deviceid + '/measurements';
    this.addCollectionItam(path, data);
  }

  public addMeasurementtoClinicalAnalysis(clinicalanalysisidid, deviceid: string, data) {
    let path = 'ClinicalAnalysis/' + clinicalanalysisidid + '/Devices/' + deviceid + '/measurements';
    this.addCollectionItam(path, data);
  }


  // Two level functions that currently cannot be replaced with simple global functions
  public getCalibrationmeasurements(calibrationid, collection: string = "Calibrations") {
    // let url = "https://us-central1-messqasystem.cloudfunctions.net/getAllCalibrationMeasurements?id=" + calibrationid;
    // return this._http.get(url);

    return new Promise<any>((res, rej) => {
      let markers: any = [];

      this.db.collection(collection).doc(calibrationid).collection('Devices').get()
        .then(async querySnapshot => {

          for (const doc of querySnapshot.docs) {

            await this.db.collection(collection).doc(calibrationid).collection('Devices').doc(doc.id).collection('measurements').get()
              .then(async querySnapshot2 => {

                querySnapshot2.forEach(measuredoc => {
                  markers.push({ Calibrationid: calibrationid, Deviceid: doc.id, measurementid: measuredoc.id, data: measuredoc.data() });
                });

              }).catch(error => {
                console.log("Error getting sub-collection documents", error);
                rej(error);
              })
          }
          res(await markers);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
          rej(error);
        });
    });

  }


  public DeleteMeasurement(calibrationid, deviceid, measurementid) {
    let path = 'Calibrations/' + calibrationid + '/Devices/' + deviceid + '/measurements';
    this.deleteCollectionItem(path, measurementid);
  }

  public getCalibrations() {
    return this.getCollectionList("Calibrations");
  }

  public getClinicalAnalysises() {
    return this.getCollectionList("ClinicalAnalysis");
  }

  public getDevicesinClinicalAnalysis(clinicalanalysisid) {
    return this.getCollectionList("ClinicalAnalysis/" + clinicalanalysisid + "/Devices")
  }

  public getDevicesinCalibration(calibrationid) {
    return this.getCollectionList("Calibrations/" + calibrationid + "/Devices")
  }

  public UpdateCalibrations(calibrationid, data) {
    this.updateCollectionData('Calibrations', calibrationid, data);
  }

// ===================
  // Control Calibration
  // ===================
  public addControlCalibration(data) {
    this.addCollectionItam('ControlCalibrations', data);
  }

  public addDevicetoControlCalibration(calibrationid, deviceid: string, data) {
    this.updateCollectionData('ControlCalibrations/' + calibrationid + '/Devices/', deviceid, data);
  }

  public addMeasurementtoControlCalibration(calibrationid, deviceid: string, data) {
    let path = 'ControlCalibrations/' + calibrationid + '/Devices/' + deviceid + '/measurements';
    this.addCollectionItam(path, data);
  }

  public getControlCalibrations() {
    return this.getCollectionList("ControlCalibrations");
  }


  public DeleteControlMeasurement(calibrationid, deviceid, measurementid) {
    let path = 'ControlCalibrations/' + calibrationid + '/Devices/' + deviceid + '/measurements';
    this.deleteCollectionItem(path, measurementid);
  }


  public getDevicesinControlCalibration(calibrationid) {
    return this.getCollectionList("ControlCalibrations/" + calibrationid + "/Devices")
  }

  public UpdateControlCalibrations(calibrationid, data) {
    this.updateCollectionData('ControlCalibrations', calibrationid, data);
  }


  public getControlCalibrationmeasurements(calibrationid) {
    // let url = "https://us-central1-messqasystem.cloudfunctions.net/getAllCalibrationMeasurements?id=" + calibrationid;
    // return this._http.get(url);

    return new Promise<any>((res, rej) => {
      let markers: any = [];

      this.db.collection('ControlCalibrations').doc(calibrationid).collection('Devices').get()
        .then(async querySnapshot => {

          for (const doc of querySnapshot.docs) {

            await this.db.collection('ControlCalibrations').doc(calibrationid).collection('Devices').doc(doc.id).collection('measurements').get()
              .then(async querySnapshot2 => {

                querySnapshot2.forEach(measuredoc => {
                  markers.push({ Calibrationid: calibrationid, Deviceid: doc.id, measurementid: measuredoc.id, data: measuredoc.data() });
                });

              }).catch(error => {
                console.log("Error getting sub-collection documents", error);
                rej(error);
              })
          }
          res(await markers);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
          rej(error);
        });
    });

  }



  // ==============================================  Calibration


  public addNewRequest(data: Request) {
    // return this.addCollectionItam("Requests", data);
    // let url = "https://us-central1-messqasystem.cloudfunctions.net/addNewRequest";
    // return this._http.post(url, data);

    return new Promise<any>((res, rej) => {
      const statsRef = this.db.collection('Requests').doc('stats');
      const currentFacilityId = data.facilityId;
      const customerRef = this.db.collection('SQACustomers').doc(currentFacilityId);
      const increment = firebase.firestore.FieldValue.increment(1);
      const batch = this.db.batch();
      const reqRef = this.db.collection('Requests').doc();
      batch.set(reqRef, data);
      batch.set(statsRef, { requestsCount: increment }, { merge: true });
      batch.set(customerRef, { requestCount: increment }, { merge: true });
      batch.commit().then(val => {
        console.log('batch res ', val);
        res(val);
      }).catch(err => {
        rej(err);
      });
    })

  }

  public getAllRequests() {
    return this.getCollectionList("Requests");
    // let url = 'https://us-central1-messqasystem.cloudfunctions.net/getRequests';
    // return this._http.get(url);

  }

  // not in use
  public getRequestsForFacility(id) {

    return new Promise<any>((res, rej) => {
      const markers: any = [];
      this.db.collection('Requests').where("facilityId", "==", id)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // if (doc.data().facilityId === id) {
            markers.push({ id: doc.id, data: doc.data() });
            // }
          });

          res(markers);
        });
    });

    // let url = "https://us-central1-messqasystem.cloudfunctions.net/getRequestsforSelectedFacility?id=" + id;
    // return this._http.get(url);
  }

  public updateRequest(id, data) {
    return this.updateCollectionData('Requests', id, data);
    // const updateRequestURL = 'https://us-central1-messqasystem.cloudfunctions.net/updateRequest?id=' + id;
    // return this._http.post(updateRequestURL, data);
  }

  public deleteRequest(id) {
    return this.deleteCollectionItem('Requests', id);
    // const url = "https://us-central1-messqasystem.cloudfunctions.net/deleteRequest?id=" + id;
    // return this._http.get(url);
  }

  public updateTestCreditItem(creditId, creditData) {

    return new Promise<any>((res, rej) => {
      this.afs.collection('Credits').doc(creditId).get()
        .subscribe(doc => {
          if (doc.data().status === 'unused') {
            let requStatus = creditData['status'];

            this.afs.collection('Credits').doc(creditId).set(creditData, { merge: true })
              .then((docRef) => {
                res({ id: creditId, data: creditData });
              })
              .catch((error) => {
                console.log("Error getting documents: ", error);
                rej(error);
              });
          } else {
            rej('Cannot reset status!');
          }
        })
    });

    // const updateCreditURL = 'https://us-central1-messqasystem.cloudfunctions.net/updateCreditItem?id=' + creditId;
    // return this._http.post(updateCreditURL, data).toPromise();
  }

  public createTestCreditBatch(amount: number, creditCount: number, creditType: string = 'Development') {

    return new Promise<any>((res, rej) => {

      let admin = {
        adminEmail: this.currentAdmin.email,
        adminId: this.currentAdmin.uid
      };
      let currentBatchId = formatDate(new Date(), 'yyyy-MM-dd_HH:mm:ss', 'en'); // new Date().toISOString();
      let batchArr: any = [];
      for (let index = 0; index < amount; index++) {
        let item: TestCredit = {
          status: 'unused',
          credit: creditCount,
          creator: admin,
          creationDate: new Date(),
          type: creditType,
          batchId: currentBatchId
        };

        this.addCollectionItam("Credits", item).then(async doc => {
          batchArr.push(await { 'id': doc.id, 'data': item });
        }).catch(err => {
          console.log('batch err', err);
          rej(err);
        });

      }

      let itemData: CreditBatch = {
        creator: admin,
        type: creditType,
        creditNumber: creditCount,
        creditAmount: amount,
        printLock: false
      }
      this.afs.collection('CreditBatch').doc(currentBatchId).set(itemData)
        .then((docRef) => {
          console.log('created batch document');
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });

      res(batchArr);
    });
  }

  public getListOfTestCreditBatches() {
    return this.getCollectionList('CreditBatch');
  }

  public getCreditItemsOfBatch(batchId: string) {
    return new Promise<any>((res, rej) => {
      const markers: any = [];
      this.db.collection('Credits').where("batchId", "==", batchId)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // if (doc.data().facilityId === id) {
            markers.push({ id: doc.id, data: doc.data() });
            // }
          });

          res(markers);
        })
        .catch(err => {
          rej(err);
        });
    });
  }

  public deleteTestCreditBatch(id) {

    return new Promise<any>((res, rej) => {
      this.deleteCollectionItem('CreditBatch', id)
        .then(batchItem => {
          this.db.collection('Credits').where("batchId", "==", id).get()
            .then(snapShot => {
              snapShot.forEach(doc => {
                this.deleteCollectionItem('Credits', doc.id);
              })
              res(batchItem);
            })
            .catch(err => {
              rej(err);
            })
        })
        .catch(err => {
          rej(err);
        });

    });
  }

  public updateTestCreditBatch(id, data) {
    return this.updateCollectionData('CreditBatch', id, data);
  }

  public addNewTestCreditItem(amount: number = 50, creditType: string = "Production") {
    let admin = {
      adminEmail: this.currentAdmin.email,
      adminId: this.currentAdmin.uid
    };
    let item: TestCredit = {
      status: 'unused',
      credit: amount,
      creator: admin,
      creationDate: new Date(),
      type: creditType,
    };

    return new Promise((res, rej) => {
      this.afs.collection("Credits").add(item)
      .then((doc) => {
        console.log({ "id": doc.id, "data": item });
        res({ "id": doc.id, "data": item });
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        rej(error);
      });
    })

    // return this.addCollectionItam("Credits", item);
    // const url = "https://us-central1-messqasystem.cloudfunctions.net/addNewCreditItem";
    // return this._http.post(url, JSON.stringify(item));
  }

  public deleteTestCreditItem(id: string) {
    return new Promise<any>((res, rej) => {
      this.db.collection('Credits').doc(id).get()
        .then(snapshot => {
          console.log(snapshot.data());
          let batchId = snapshot.data()["batchId"];
          if (batchId !== undefined) {
            console.log('part of batch ', batchId);
            const decrement = firebase.firestore.FieldValue.increment(-1);
            this.db.collection('CreditBatch').doc(batchId).set({ creditAmount: decrement }, { merge: true })
              .then(batch => {
                this.deleteCollectionItem('Credits', id).then(creditItem => {
                  res(creditItem);
                }).catch(err => {
                  rej(err);
                });
              }).catch(err => {
                rej(err);
              });
          } else {
            this.deleteCollectionItem('Credits', id).then(creditItem => {
              res(creditItem);
            }).catch(err => {
              rej(err);
            });
          }
        });
    });

    // const url = "https://us-central1-messqasystem.cloudfunctions.net/deleteCreditItem?id=" + id;
    // return this._http.get(url);
  }

  public getAllTestCredits() {
    return this.getCollectionList("Credits");

    // const url = "https://us-central1-messqasystem.cloudfunctions.net/getAllCreditItems";
    // return this._http.get(url);
  }

  public getAllTestCreditsByFilter(status: string, typeArr, creatorIsMe: boolean) {
    return new Promise<any>((res, rej) => {
      let markers = [];
      if (creatorIsMe) {
        this.db.collection('Credits')
        .where('creator.adminId', '==', this.currentAdmin.uid)
        .where('status', '==', status)
        .where('type', 'in', typeArr)
        .get().then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // if (doc.data().facilityId === id) {
            markers.push({ id: doc.id, data: doc.data() });
            // }
          });

          res(markers);
        }).catch(err => {
          rej(err);
        })
      } else {
        this.db.collection('Credits')
        .where('status', '==', status)
        .where('type', 'in', typeArr)
        .get().then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // if (doc.data().facilityId === id) {
            markers.push({ id: doc.id, data: doc.data() });
            // }
          });
          res(markers);
        }).catch(err => {
          rej(err);
        })
      }
    })
  }

  public redeemTestCredits(creditId, facilityID, facilityNAME) {

    return new Promise<any>(res => {
      let currentFacilityCredits: number;
      let creditsToAdd: number = 0;

      this.getTestCreditItem(creditId).then((creditItem) => {
        console.log('creditItem', creditItem);
        if (creditItem.toString().includes('Error')) {
          res(creditItem);
        } else {
          creditsToAdd = Number(creditItem['data']['credit']);
          console.log('creditsToAdd', creditsToAdd);
          return this.getFacilityCurrentCredits(facilityID);
        }
      })
        .then((facilityCredits) => {
          console.log('facilityCredits', facilityCredits);
          if (facilityCredits.toString().includes('Error')) {
            res(facilityCredits);
          } else {
            currentFacilityCredits = Number(facilityCredits['testCredits']);
            let newCreditNumber: number = currentFacilityCredits + creditsToAdd;
            console.log('new credit number ', newCreditNumber);
            let data = {
              testCredits: newCreditNumber
            };
            console.log('facility to add data', data);
            return this.updateFacilityCredits(facilityID, data);
          }
        })
        .then((update) => {
          console.log('update', update);
          let facility = {
            facilityId: facilityID,
            facilityName: facilityNAME
          };
          let admin = {
            email: this.currentAdmin.email,
            id: this.currentAdmin.uid,
            userType: 'admin'
          };
          let data = { status: 'used', usingFacility: facility, redeemingDate: new Date(), redeemedBy: admin };
          console.log('data', data);

          return this.updateTestCreditItem(creditId, data);
        })
        .then((status) => {
          console.log(status);
          res('test credits updated successfully!');
        })
        .catch((err) => {
          console.log('redeem credits err', err);
          res(err);
        });
    });
  }

  private getTestCreditItem(creditId) {
    return new Promise<any>((res, rej) => {
      this.afs.collection('Credits').doc(creditId).get()
        .subscribe((docRef) => {
          if (docRef.exists) {
            let credit = docRef.data();
            if (credit !== undefined) {
              let status = credit['status'];
              if (status === 'unused') {
                res({ id: creditId, data: docRef.data() });
              } else {
                res('Error: Credit has already been used!');
              }
            }

          }
          else {
            res('Error: No such document!');
            console.log("No such document!");
          }
        });
    });

    // const creditItemURL = 'https://us-central1-messqasystem.cloudfunctions.net/redeemCredit?id=' + creditId;
    // return this._http.get(creditItemURL).toPromise();
  }

  private getFacilityCurrentCredits(facilityid) {

    return new Promise<any>((res, rej) => {
      this.afs.collection('SQACustomers').doc(facilityid).get()
        .subscribe((doc) => {
          if (doc.exists) {
            let data = doc.data();
            if (data !== undefined) {
              res({ id: facilityid, testCredits: data['testCredits'] });
            }
            else {
              res("Error: Empty document!");
            }
          }
          else {
            res("Error: No such document!");
            console.log("No such document!");
          }
        });
    });

    // const facilityCreditURL = 'https://us-central1-messqasystem.cloudfunctions.net/getCredit?id=' + facilityid;
    // return this._http.get(facilityCreditURL).toPromise();
  }

  private updateFacilityCredits(facilityid, data) {
    return this.updateCollectionData('SQACustomers', facilityid, data);
    // const updateFacilityURL = 'https://us-central1-messqasystem.cloudfunctions.net/UpdateFacilitySettings?id=' + facilityid;
    // return this._http.post(updateFacilityURL, data).toPromise();
  }

  public getTotalCustomerNumbers() {
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getTotalCustomerNumbers';
    // return this._http.get(url);

    return new Promise<any>((res, rej) => {
      this.afs.collection('SQACustomers').get()
        .subscribe(async function (querySnapshot) {
          let totalCustomers = querySnapshot.docs.length;
          let patientscounter = 0;
          let testscounter = 0;
          await querySnapshot.forEach(facility => {
            if (facility.data().testCount !== undefined && facility.data().patientCount !== undefined) {
              testscounter += facility.data().testCount;
              patientscounter += facility.data().patientCount;
            }
          });
          console.log('count ', testscounter, patientscounter);
          res(await { customers: totalCustomers, patients: patientscounter, tests: testscounter });
        });
    });
  }

  public getTotalRequestsNumber() {
    return this.getTotalNumber('Requests');
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getTotalNumbers?collection=Requests';
    // return this._http.get(url);
  }

  public getTotalCalibrationsNumber() {
    return this.getTotalNumber('Calibrations');
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getTotalNumbers?collection=Calibrations';
    // return this._http.get(url);
  }

  public getTotalDevicesNumber() {
    return this.getTotalNumber('Devices');
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getTotalNumbers?collection=Devices';
    // return this._http.get(url);
  }

  public getTotalAdminsNumber() {
    return this.getTotalNumber('MESUsers');
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getTotalNumbers?collection=MESUsers';
    // return this._http.get(url);
  }

  public getTotalCreditsNumber() {
    return this.getTotalNumber('Credits');
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getTotalNumbers?collection=Credits';
    // return this._http.get(url);
  }

  public getTotalTests() {
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getTotalTests';
    // return this._http.get(url);

    return new Promise<any>((res, rej) => {
      let markers: any = [];

      this.db.collectionGroup('tests').get()
        .then(async tests => {
          console.log('total test count ', tests.size);
          for (const test of tests.docs) {
            let path = test.ref.path.split('/');
            let facility = path[1];
            let patient = path[3];
            if (test.id !== 'stats') {
              await markers.push({ facilityid: facility, patientid: patient, testid: test.id, data: test.data() });
            }
          }
          res(await markers);
          return;
        }).catch(err => {
          rej(err);
        });
    });
  }

  public getTotalPatients() {
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getTotalPatients';
    // return this._http.get(url);
    return new Promise<any>((res, rej) => {

      this.db.collectionGroup('patients').get()
        .then(async patients => {
          let markers: any = [];
          for (const patient of patients.docs) {
            let path = patient.ref.path.split('/');
            let facility = path[1];
            // let testCnt = 0; //patient.ref.collection('tests')
            markers.push({ facilityid: facility, patientid: patient.id, data: patient.data() });
          }
          console.log('data patients ', markers);
          res(await markers);
          return;
        }).catch(err => {
          rej(err);
        });
    });
  }

  public addNewAdmin(data) {

    const url = this.apiEP + '/addNewAdminUserWithAuth';

      return new Promise<any>((ress, rejj) => {
        firebase.auth().currentUser.getIdToken().then(token => {
          const headers = new HttpHeaders ({'Authorization': 'Bearer ' + token});
          // const myUID = '';
          // const notMyUID = '';
    
          // const body = { uid: myUID };
        this._http.post(url, data, { headers: headers }).toPromise().then((res) => {
          ress(res);
        }).catch(err => {
          if (err.error.code === 'auth/email-already-exists') {
            console.log('err adding admin  http ', err);
            rejj(new EmailNotAvailableError());
          } else {
            rejj(err);
          }
        });
      });
    });

  }

  public changeFacilityPassword(id: string, newPassword) {
    const data = JSON.stringify({ password: newPassword })
    const url = this.apiEP + '/changeFacilityPasswordWithAuth?id=' + id;
    return new Promise<any>((ress, rejj) => {
      firebase.auth().currentUser.getIdToken().then(token => {
        const headers = new HttpHeaders ({'Authorization': 'Bearer ' + token});

      return this._http.post(url, data, { headers: headers }).toPromise().then((res) => {
        ress(res);
      }).catch(err => {
          rejj(err);
      });
    });
  });
  }

  public updateAdmin(id: string, data) {
    const url = this.apiEP + '/updateAdminUserWithAuth?id=' + id;
    return new Promise<any>((ress, rejj) => {
      firebase.auth().currentUser.getIdToken().then(token => {
        const headers = new HttpHeaders ({'Authorization': 'Bearer ' + token});

      return this._http.post(url, data, { headers: headers }).toPromise().then((res) => {
        ress(res);
      }).catch(err => {
          rejj(err);
      });
    });
  });
  }

  public deleteAdmin(id: string) {
    const url = this.apiEP + '/deleteAdminUserWithAuth?id=' + id;
    return new Promise<any>((ress, rejj) => {
      firebase.auth().currentUser.getIdToken().then(token => {
        const headers = new HttpHeaders ({'Authorization': 'Bearer ' + token});

      return this._http.get(url, { headers: headers }).toPromise().then((res) => {
        ress(res);
      }).catch(err => {
          rejj(err);
      });
    });
  });
  }

  public getAllAdmins() {
    return this.getCollectionList('MESUsers');
  }

  public getAdmin(id: string) {
    return this.getCollectionData('MESUsers', id);
  }

  public getAllAdminsWithInfo() {
    const url = this.apiEP + '/getAllAdminsWithInfoWithAuth';
    return new Promise<any>((ress, rejj) => {
      firebase.auth().currentUser.getIdToken().then(token => {
        const headers = new HttpHeaders ({'Authorization': 'Bearer ' + token});
        // const myUID = '';
        // const notMyUID = '';
  
        // const body = { uid: myUID };
      this._http.get(url, { headers: headers }).toPromise().then((res) => {
        ress(res);
      }).catch(err => {
          rejj(err);
      });
    });
  });
  }

  public getAllAverageTestsPerDay() {
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getAvrgTestsTotal';
    // return this._http.get(url);

    return new Promise<any>((res, rej) => {

      const allDates: any = [];
      let distinctDates: any = [];
      const avrgTests: any = [];

      this.db.collectionGroup('tests').get().then(async tests => {

        for (const test of tests.docs) {
          try {
            let timestamp = test.data().dateTime.toDate();
            let element = formatDate(new Date(timestamp), 'yyyy/MM/dd', 'en')
            allDates.push(element);
          } catch (error) {
            console.log('avrg test date error');
          }
        }

        const distinct = (value: any, index: any, self: any) => {
          return self.indexOf(value) === index;
        };
        distinctDates = allDates.filter(distinct);
        distinctDates.sort();
        console.log(distinctDates);

        let days = 1;
        let test_count = 0;
        for (const date of distinctDates) {
          let daily_test_count = 0;
          for (const testDate of allDates) {
            if (testDate === date) {
              test_count++;
              daily_test_count++;
            }
          }
          let avrg = test_count / days;

          avrgTests.push({ Date: date, DailyTests: daily_test_count, TotalTests: test_count, Days: days, Avrg: avrg });
          days++;
        }
        res(await avrgTests);
      }).catch(err => {
        console.log("Error getting documents: ", err);
        rej(err);
      });
    });

  }

  public addDeviceServiceData(deviceID, data) {
    // let path = 'Devices/' + deviceID.toString() + '/ServiceData';
    // return this.addCollectionItam(path, data);

    return new Promise<any>((res, rej) => {
      const statsRef = this.db.collection('Devices').doc(deviceID);

      const increment = firebase.firestore.FieldValue.increment(1);
      const batch = this.db.batch();
      const reqRef = this.db.collection('Devices').doc(deviceID).collection('servicedata').doc();
      batch.set(reqRef, data);
      batch.set(statsRef, { selfTestCount: increment }, { merge: true });
      batch.commit().then(val => {
        console.log('batch res ', val);
        res(val);
      }).catch(err => {
        rej(err);
      });
    })
  }

  // public addAdminGeoToFacilities() {
  //   this.db.collection('SQACustomers').get().then(snapshot => {
  //     for (const doc of snapshot.docs) {
  //       this.db.collection('SQACustomers').doc(doc.id).get().then(data => {
  //         let city = data.data().city;
  //         let country = data.data().country;
  //         let newInfo: SQACustomer = {
  //           geoCity: city,
  //           geoCountry: country
  //         };
  //         this.UpdateFacilityProfile(doc.id, newInfo);
  //       });
  //     }
  //   })
  // }

  // public addDatatotestsForFacility(currentFacilityId, currentFacilityName) {
  //   // let url = "https://us-central1-messqasystem.cloudfunctions.net/getNestedCollection?id=" + this.currentFacilityId + "&maincollection=patients&subcollection=tests";
  //   // return this._http.get(url);

  //   return new Promise<any>((res, rej) => {

  //     this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').get()
  //       .then(async patientsSnapshot => {
  //         let markers: any = [];
  //         let noOfPatients = patientsSnapshot.size;
  //         let fTestCount = 0;

  //         for (const patient of patientsSnapshot.docs) {
  //           await this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').doc(patient.id).collection('tests').get()
  //             .then(async testSnapshot => {
  //               let pTestCount = testSnapshot.size;
  //               fTestCount = fTestCount + pTestCount;
  //               await this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').doc(patient.id)
  //               .set({ testCount: pTestCount }, { merge: true }).then(pTCnt => {
  //                 console.log('pTestCount ', pTestCount);
  //               });
  //               for (const test of testSnapshot.docs) {
  //                 if (test.id !== 'stats') {
  //                   let itemData = {
  //                     facilityId: currentFacilityId,
  //                     facilityName: currentFacilityName,
  //                     patientId: patient.id,
  //                     patientFirstName: patient.data().firstName,
  //                     patientLastName: patient.data().lastName
  //                   }
  //                   await this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').doc(patient.id).collection('tests').doc(test.id)
  //                   .set(itemData, { merge: true }).then(snap => {
  //                     console.log('updated data ', snap);
  //                   });

  //                 }
  //               }
  //             }).catch(error => {
  //               console.log("Error getting sub-collection documents", error);
  //               rej(error);
  //             })
  //         }
  //         await this.db.collection('SQACustomers').doc(currentFacilityId)
  //         .set({patientCount: noOfPatients, testCount: fTestCount}, {merge: true })
  //         .then(pCount => {
  //           console.log('pCount ', noOfPatients);
  //           console.log('tCount ', fTestCount);

  //         });
  //         res(await markers);
  //         console.log(await 'success')
  //       })
  //       .catch(error => {
  //         console.log("Error getting documents: ", error);
  //         rej(error);
  //       });
  //   });
  // }

  public updateFacilityLocation(facilityId, latitude, longitude) {
    return new Promise<any>((res, rej) => {
      const location = {
        lat: latitude,
        lng: longitude
      }
      this.afs.doc(`SQACustomers/${facilityId}`).set({ geoLocation: location }, { merge: true }).then(userlocation => {
        res(location);
      })
        .catch(err => {
          console.log('loc saving err ', err);
          rej(err);
          // rej(err);
        });
    });

  }

  public updateDeviceLocation(deviceSN, latitude, longitude) {
    return new Promise<any>((res, rej) => {
      const deviceData: Device = {};
      const location = {
        lat: latitude,
        lng: longitude
      };
      deviceData.usingFacility = {
        facilityAddress: {
          geo: location
        }
      };
      this.afs.doc(`Devices/${deviceSN}`).set(deviceData, { merge: true }).then(userlocation => {
        res(location);
      })
        .catch(err => {
          console.log('loc saving err ', err);
          rej(err);
          // rej(err);
        });
    });

  }

  public getFacilityTotalCredits(facilityId) {
    return new Promise<any>((res, rej) => {

      this.db.collection(`Credits`).where('usingFacility.facilityId', '==', facilityId).get().then(async dox => {
        let credits = 0;
        for (const doc of dox.docs) {
          credits = credits + doc.data().credit;
          console.log("all credits ", credits);
          console.log('ids ', doc.id);

        };
        res(await credits);
      })
        .catch(err => {
          console.log('loc saving err ', err);
          rej(err);
          // rej(err);
        });
    });
  }

  public AddNotification(data) {
    return this.addCollectionItam('Notifications', data);
  }

  public DeleteNotification(notificationId) {
    return this.deleteCollectionItem('Notifications', notificationId);
  }

  public UpdateNotification(notificationId, data) {
    return this.updateCollectionData('Notifications', notificationId, data);
  }

  public getAllNotifications() {
    return this.getCollectionList('Notifications');
  }

  public AddDistributer(data) {
    return this.addCollectionItam('Distributers', data);
    // return new Promise<any>((res, rej) => {
    //   this.db.collection('Distributers').doc(code).set(data)
    //   .then((doc) => {
    //     console.log({ "id": code, "data": data });
    //     res(code);
    //   })
    //   .catch((error) => {
    //     console.error("Error adding document: ", error);
    //     rej(error);
    //   });
    // });
  }

  public DeleteDistributer(code) {
    return this.deleteCollectionItem('Distributers', code);
  }

  public UpdateDistributer(code, data) {
    return this.updateCollectionData('Distributers', code, data);
  }

  public getDistributerById(id: string) {
    return this.getCollectionData('Distributers', id);
  }

  public getAllDistributers() {
    return this.getCollectionList('Distributers');
  }

  public getAllDistributerCount() {
    return new Promise<any>((res, rej) => {
      this.db.collection('Distributers').get().then(snap => {
        res(snap.size);
      }).catch(e => {
        res(0);
      })
    })

  }

  public getMESDistributers() {
    return this.getCollectionList('MESDistributers');
  }

  public addQCBatch(data: ControlsBatch) {
    let dData: ControlsBatch = {
      ...data,
      adminEditor: {
        id: this.currentAdmin.uid,
        email: this.currentAdmin.email,
        name: this.currentAdmin.name,
        dateTime: new Date()
      }
    }
    return new Promise<any>((res, rej) => {

      this.db.collection('Controls').doc(data.batchID).set(dData)
        .then((doc) => {
          console.log({ "id": data.batchID, "data": dData });
          res(dData);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });
  }

  public DeleteQCBatch(batchId) {
    return this.deleteCollectionItem('Controls', batchId);
  }

  public UpdateQCBatch(batchId, data: ControlsBatch) {
    let dData: ControlsBatch = {
      ...data,
      adminEditor: {
        id: this.currentAdmin.uid,
        email: this.currentAdmin.email,
        name: this.currentAdmin.name,
        dateTime: new Date()
      }
    }
    return this.updateCollectionData('Controls', batchId, dData);
  }

  public getAllQCControls() {
    return this.getCollectionList('Controls');
  }

  public getUsersForFacility(facilityId) {
    return new Promise<any>((res, rej) => {
      let markers:any = [];
      this.db.collection('SQAUsers').where("facilityID", "==", facilityId).get().then(async users => {
        if (users.empty) {
          console.log('No matching documents.');
          // rej('No tests found.');
        }
        for (const user of users.docs) {
          await markers.push({ id: user.id, data: user.data() });
        }
        res(await markers);
      }).catch(e => {
        rej(e);
      });

    });

  }

  public getTotalQCTests() {
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getTotalTests';
    // return this._http.get(url);

    return new Promise<any>((res, rej) => {
      let markers: any = [];

      this.db.collectionGroup('controlTests').get()
        .then(async tests => {
          console.log('total qc test count ', tests.size);
          for (const test of tests.docs) {
            let path = test.ref.path.split('/');
            let facility = path[1];
            if (test.id !== 'stats') {
              await markers.push({ facilityid: facility, testid: test.id, data: test.data() });
            }
          }
          res(await markers);
          return;
        }).catch(err => {
          rej(err);
        });
    });
  }

  public getAllUsers() {
    return this.getCollectionList('SQAUsers');
  }

  public deleteSQAUser(id: string) {
    const url = this.apiEP + '/deleteSQAUserAdminWithAuth?id=' + id;

    return new Promise((res, rej) => {
      firebase.auth().currentUser.getIdToken().then(async token => {
        const headers = new HttpHeaders ({'Authorization': 'Bearer ' + token});

        this._http.get(url, { headers: headers }).toPromise().then(user => {
          res(user);
        }).catch(err => {
          rej(err);
        });

      });
    })
  }
  public getProficiencyBatch(id) {
    return this.getCollectionData('Proficiency', id);
  }

  public getAllProficiencyBatches(scheme: string) {
    return new Promise<any>((res, rej) => {
      let markers: any = [];
      this.db.collection('Proficiency').where('scheme', '==', scheme).get()
        .then(async proficiencyBatches => {
          if (proficiencyBatches.empty) {
            // console.log('No active notifications.');
            rej('No data found.');
          }
          for (const batch of proficiencyBatches.docs) {
            await markers.push({ id: batch.id, data: batch.data() });
          }
          res(await markers);
        }).catch(err => {
          rej(err);
        })
  
    });
    // return this.getCollectionList('Proficiency');
  }

  public addProficiencyBatch(data: ProficiencyBatch) {
    let dData: ProficiencyBatch = {
      ...data,
      adminEditor: {
        id: this.currentAdmin.uid,
        email: this.currentAdmin.email,
        name: this.currentAdmin.name,
        dateTime: new Date()
      }
    }
    return new Promise<any>((res, rej) => {

      this.db.collection('Proficiency').doc(data.distributionNo).set(dData)
        .then((doc) => {
          console.log({ "id": data.distributionNo, "data": dData });
          res(dData);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });
  }

  public DeleteProficiencyBatch(distributionNo) {
    return this.deleteCollectionItem('Proficiency', distributionNo);
  }

  public UpdateProficiencyBatch(distributionNo, data: ProficiencyBatch) {
    let dData: ProficiencyBatch = {
      ...data,
      adminEditor: {
        id: this.currentAdmin.uid,
        email: this.currentAdmin.email,
        name: this.currentAdmin.name,
        dateTime: new Date()
      }
    }
    return this.updateCollectionData('Proficiency', distributionNo, dData);
  }

  public getSystemVersions() {
    return this.getCollectionData('System', 'versions');
  }

  public backupFacility(id: string) {
    const url = this.apiEP + '/testbackupFacility?id=SQACustomers/'+ id;
    return new Promise((res, rej) => {
      firebase.auth().currentUser.getIdToken().then(async token => {
        const headers = new HttpHeaders ({'Authorization': 'Bearer ' + token});

        this._http.get(url, { headers: headers }).toPromise().then(backup => {
          const myblob = new Blob([JSON.stringify(backup, null, 2)], {type: 'application/json'});
          console.log(myblob);
          let filename = new Date().toISOString() + '.json';
          const file = new File([myblob], filename, {type: 'application/json'});
          var storageRef = firebase.storage().ref();
          var ref = storageRef.child(`Backups/${id}/${filename}`);
          this.uploadBackup(file, ref).then(resurl => { 
            console.log('resurl', resurl)
            res(resurl);
          }).catch(err => {
            console.log(err);
            rej(err);
          })
        }).catch(err => {
          rej(err);
        });

      });
    })
  }

  private uploadBackup(file, ref) {

    return new Promise<any>((res, rej) => {
        ref.put(file).then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            res(url);
          }).catch(err => {
            rej(err);
          });
        });


    });

  }

  public getFacilityBackupList(id:string) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(`Backups/${id}`);

    return new Promise<any>((res, rej) => {
      ref.list().then(filelist => {
        console.log(filelist.items);
        let paths = filelist.items.map(item => {
          let path = item['location']['path'];
          let patharr = path.split('/');
          console.log(patharr);
          let filename = patharr[patharr.length - 1];
          let name = filename.split('.')[0];
          let date = new Date(name);
          return name;
        })
        console.log(paths);
        res(paths);
      }).catch(err => {
        console.log(err);
        rej(err);
      })
  
    })

  }

  // public addAllFacilitiesToUsers () {
  //   return new Promise<any>((res, rej) => {
  //     let userList = [];
  //     this.db.collection('SQACustomers').get().then(async facilitySnapshot => {
  //       for (const f of facilitySnapshot.docs) {
  //       await this.db.collection('SQACustomers').doc(f.id).get()
  //       .then(async data => {
  //         console.log('facility to add ', data);
  //         let facilityUser = {
  //           facilityID: data.id,
  //           userID: data.id,
  //           isFacilityAdmin: true,
  //           doctorName: '',
  //           designation: '',
  //           email: data.data().email,
  //           photoURL: '',
  //           signatureURL: '',
  //           phone: '',
  //           creationTime: new Date()
  //         }
  //         if (data.data().doctorName !== undefined) {
  //           facilityUser.doctorName = data.data().doctorName;
  //         }
  //         if (data.data().designation !== undefined) {
  //           facilityUser.designation = data.data().designation;
  //         }
  //         if (data.data().photoURL !== undefined) {
  //           facilityUser.photoURL = data.data().photoURL;
  //         }
  //         if (data.data().signatureURL !== undefined) {
  //           facilityUser.signatureURL = data.data().signatureURL;
  //         }
  //         if (data.data().phone !== undefined) {
  //           facilityUser.phone = data.data().phone;
  //         }
  //         if (data.data().creationTime !== undefined) {
  //           facilityUser.creationTime = data.data().creationTime;
  //         }
  //         await this.db.collection('SQAUsers').doc(data.id).set(facilityUser, {merge: true}).then(async user => {
  //           console.log('new facility user ', user);
  //           // res(user);
  //           await userList.push(facilityUser);
  //         }).catch(e => {
  //           console.error("Error adding facility user: ", e);
  //         })
  //       })
  //       .catch((error) => {
  //         console.error("Error geting facility: ", error);
  //         // rej(error);
  //       });
  //     }
  //     res(await userList);
  //     }).catch(err => {
  //       console.log('error with fSnaps', err);
  //     })
  //   });
  // }
}


