import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import * as XLSX from 'xlsx';
import { Observable, Subject } from 'rxjs';
export enum return_data_For {
  patients = 1,
  tests = 2,
  installationDates = 3,
}
@Injectable({
  providedIn: 'root'
})

export class GlobalService {
  selectedFacilityId = "";
  selectedRowEditData = {};
  facilityList: any = [];
  filterPatientFacility = false;
  filterTestFacility = false;
  Fresh_Test_Data = {};
  Patient_Info = {};
  navigateFromRequestTable = false;
  navigateFromAllCustomerTable = false;
  facilityNavData = {};
  allPatientFilterFacilityId: any = "";
  allPatientFilterPatientId: any = "";
  testPatientArray: any = {};
  allPatientFilter: boolean = false;
  allPatientTestFilter: boolean = false;
  customerCompleteInfoData = {};
  currentAdmin: boolean = false;
  selectedEditRowAllRequests = {};
  editAdminData: any;
  editClickedTrue: boolean = false;
  currentDate: any;
  outputArray: any = [];
  aggrResult: any = 0;
  aggrResultForfacility: any = 0;
  private facFilter = new Subject<any>();
  private facPatientsFilter = new Subject<any>();
  //Distributors

  selectedDistributorData: any;
  navigatedFromAllDistributorsTable: boolean = false;
  //Excel
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';

  // Calibration global data
  // =======================
  activeCalibrationid = "";
  selectedCalibration = 0;
  activeVerificationid = "";
  selectedVerification = 0;
  activeClinicalAnalysisid = "";
  selectedClinicalAnalysis = 0;

  saveSignal = false;
  selectedPortindex = 0;
  SNReference1 = "0";
  SNReference2 = "0";
  SelectDevice = 0;
  SelectDeviceIndex = 0;
  // Pre Calibration global data
  // ===========================
  NumberofSamplesPoints = 1000;
  CurrentSteps = 0;
  filteredExportData: any = [];
  filteredExportFacilityWithDistributorData: any = []
  filteredExportDistributorData: any = [];
  filteredExportTestData: any = [];
  version = "05_07_2020_10_47";
  objectTopush: any = {};
  finalOutputObject: any = {};
  arraytoReturn: any = [];
  weeklyDataobj: any = {};
  finalPatientsOutputObject: any = {};
  PatientsobjectTopush: any = {};
  PatientsarraytoReturn: any = [];
  customerType: any = [];
  cuswithDistributer: any = [];
  facilityDistributerData: any = [];
  isGrid: boolean = false;
  constructor(private _http: HttpService) { }

  toExportFileName(excelFileName: string): string {
    return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
  }

  public exportAsExcelFile(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    this.currentDate = formatDate(new Date(), 'dd_MMM_yyyy', 'en');
    XLSX.writeFile(workbook, this.toExportFileName("Facility_Report_" + this.currentDate));
  }
  public exportDistributorAsExcelFile(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    this.currentDate = formatDate(new Date(), 'dd_MMM_yyyy', 'en');
    XLSX.writeFile(workbook, this.toExportFileName("Distributor_Report_" + this.currentDate));
  }
  public exportDistributorWithFacilityAsExcelFile(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    this.currentDate = formatDate(new Date(), 'dd_MMM_yyyy', 'en');
    XLSX.writeFile(workbook, this.toExportFileName("Facility_Distributor_Report_" + this.currentDate));
  }
  public exportTestAsExcelFile(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    this.currentDate = formatDate(new Date(), 'dd_MMM_yyyy', 'en');
    XLSX.writeFile(workbook, this.toExportFileName("Test_Report_" + this.currentDate));
  }
  exportDistributorAsExcel() {
    console.log("filteredExportData", this.filteredExportDistributorData);
    let Dataname: any;
    let Datavalue: any;
    let DatatoExport = [];
    let data;
    let facilityId;
    data = this.filteredExportDistributorData;
    let item = {};
    let item_test = {};
    for (var j = 0; j < data.length; j++) {
      if (data != undefined) {
        Dataname = Object.keys(data[j]);
        Datavalue = Object.values(data[j]);
        item = data[j];
        facilityId = data[j].facilityId;
        item_test = {
          "S.No": j + 1,
          "Id": data[j].id,
          "First Name": data[j].data.firstName,
          "Last Name": data[j].data.lastName,
          "Email": data[j].data.email,
          "Mes Distributor": data[j].data.mesDistributer,
          "Role": data[j].data.role,
          "Type": data[j].data.type,
        }
        DatatoExport.push(item_test);
      }
    }
    this.exportDistributorAsExcelFile(DatatoExport)
  }
  exportDistributorWithFacilityAsExcel() {
    console.log("exportDistributorWithFacilityAsExcel");
    console.log("customerType", this.customerType);
    if (this.customerType == undefined || this.customerType == "") {
      this.customerType = ['facility']
    }
    this.cuswithDistributer = [];
    this._http.getCustomersDistributors(this.customerType).then((data: any) => {
      console.log("data", data);
      console.log("data length", data.length);
      for (let i = 0; i < data.length; i++) {
        this.cuswithDistributer.push({
          facilityId: data[i].id,
          facilityName: data[i].data.facilityName,
          distributers: data[i].data.distributers
        })
      }
      console.log("cuswithDistributer", this.cuswithDistributer);
      console.log("cuswithDistribute r  length", this.cuswithDistributer.length);
      this.getDistributerData()
    })

  }
  getDistributerData() {
    var excelDistributerData = [];
    for (let i = 0; i < this.cuswithDistributer.length; i++) {
      if (this.cuswithDistributer[i].distributers.length != [] && this.cuswithDistributer[i].distributers != "NA") {
        for (let j = 0; j < this.cuswithDistributer[i].distributers.length; j++) {
          var firstName, lastName, email, role, name;
          var companyName, city, country

          if (this.cuswithDistributer[i].distributers[j].data != undefined) {
            console.log("this.cuswithDistributer[i].distributers[j].data.country", this.cuswithDistributer[i].distributers[j]);

            firstName = this.cuswithDistributer[i].distributers[j].data.firstName;
            lastName = this.cuswithDistributer[i].distributers[j].data.lastName;
            email = this.cuswithDistributer[i].distributers[j].data.email;
            role = this.cuswithDistributer[i].distributers[j].data.role;
            city = this.cuswithDistributer[i].distributers[j].data.city;
            country = this.cuswithDistributer[i].distributers[j].data.country;
            companyName = this.cuswithDistributer[i].distributers[j].data.companyName;
            console.log("first", firstName);
            var fName, lName, dName
            console.log("lastttt", lastName);


            if (firstName != undefined && lastName == undefined) {
              name = firstName
            } else if (firstName == undefined && lastName != undefined) {
              name = lastName
            } else if (firstName != undefined && firstName != undefined) {
              name = firstName + " " + lastName
            } else {
              name = "NA"
            }
          } else {
            firstName = ""
            lastName = ""
            name = "NA"
            email = "NA"
            role = "NA"
            city = "NA",
              companyName = "NA",
              country = 'NA'
          }
          console.log("name", name, this.cuswithDistributer[i].facilityId);

          excelDistributerData.push({
            facilityId: this.cuswithDistributer[i].facilityId,
            facilityName: this.cuswithDistributer[i].facilityName,
            distributerId: this.cuswithDistributer[i].distributers[j].id,
            distributerName: name,
            distributerEmail: email,
            distributerRole: role,
            companyName: companyName,
            country: country,
            city: city,
            order: this.cuswithDistributer[i].distributers[j].order,
            support: this.cuswithDistributer[i].distributers[j].support,
          })

        }
      } else {
        console.log("excelDistributerData", this.cuswithDistributer[i].distributers);
        excelDistributerData.push({
          facilityId: this.cuswithDistributer[i].facilityId,
          facilityName: this.cuswithDistributer[i].facilityName,
          distributerId: 'NA',
          distributerName: 'NA',
          distributerEmail: 'NA',
          distributerRole: 'NA',
          companyName: 'NA',
          city: 'NA',
          country: 'NA',
          order: 'NA',
          support: 'NA',
        })

      }


    }
    this.facilityDistributerData = excelDistributerData;
    this.exportFacilityAsExcel()
  }
  exportFacilityAsExcel() {
    console.log("filteredExportData", this.facilityDistributerData);
    let Dataname: any;
    let Datavalue: any;
    let DatatoExport = [];
    let data;
    let facilityId;
    var order, support;
    data = this.facilityDistributerData;
    let item = {};
    let item_test = {};
    for (var j = 0; j < data.length; j++) {
      if (data != undefined) {
        Dataname = Object.keys(data[j]);
        Datavalue = Object.values(data[j]);
        item = data[j];
        facilityId = data[j].facilityId;
        if (data[j].order == true) {
          order = "Yes"
        } else {
          order = "No"
        }
        if (data[j].support == true) {
          support = "Yes"
        } else {
          support = "No"
        }

        item_test = {
          "S.No": j + 1,
          "Facility Id": data[j].facilityId,
          "Facility Name": data[j].facilityName,
          "Distributor Id": data[j].distributerId,
          "Distributor Name": data[j].distributerName,
          "Company Name": data[j].companyName,
          "Distributor Email": data[j].distributerEmail,
          "Distributor Role": data[j].distributerRole,
          "City": data[j].city,
          "Country": data[j].country,
          "Order": order,
          "Support": support
        }
        DatatoExport.push(item_test);
      }
    }
    console.log("DatatoExport", DatatoExport);

    this.exportDistributorWithFacilityAsExcelFile(DatatoExport)
  }
  exportAsExcel() {
    console.log("filteredExportData", this.filteredExportData);
    let Dataname: any;
    let Datavalue: any;
    let DatatoExport = [];
    let data;
    let facilityId;
    data = this.filteredExportData;
    let item = {};
    let item_test = {};
    for (var j = 0; j < data.length; j++) {
      if (data != undefined) {
        Dataname = Object.keys(data[j]);
        Datavalue = Object.values(data[j]);
        item = data[j];
        facilityId = data[j].facilityId;
        console.log("no sale", data[j]);

        item_test = {
          "S.No": j + 1,
          "Facility Id": data[j].facilityId,
          "Facility Name": data[j].facilityName,
          "Doctor Name": data[j].doctorName,
          "Email": data[j].email,
          "Phone": data[j].phone,
          "Country": data[j].country,
          "City": data[j].city,
          "Customer Type": data[j].customerType,
          // "Distributer": data[j].distributer,

          "No. of Service Person": data[j].noSalesperson,
          "Device SN": data[j].deviceSN,
          "Installation Date  ": data[j].creationTime,
          "Last Log-in  ": data[j].lastSignInTime,
          "Total # Patients  ": data[j].patientCount,
          "Total # Tests  ": data[j].testCount,
          "Total QC Tests  ": data[j].qcTestCount,
          "Total Requests  ": data[j].requestCount,
          "Remaining Test Credits ": data[j].testCredits,
          "Total # Test Credits": data[j].totalCredits
        }
        DatatoExport.push(item_test);
      }
    }
    this.exportAsExcelFile(DatatoExport)
  }
  exportAsTestExcel() {
    console.log("filteredExportData", this.filteredExportTestData);
    let Dataname: any;
    let Datavalue: any;
    let DatatoExport = [];
    let data;
    let facilityId;
    data = this.filteredExportTestData;
    let item = {};
    let item_test = {};
    for (var j = 0; j < data.length; j++) {
      if (data != undefined) {
        Dataname = Object.keys(data[j]);
        Datavalue = Object.values(data[j]);
        item = data[j];
        facilityId = data[j].facilityId;
        item_test = {
          "S.No": j + 1,
          "Facility Id": data[j].facilityid,
          "Facility Name": data[j].facilityName,
          "Test Date": formatDate(data[j].testDate, 'dd-MMM-yyyy', 'en')
        }
        DatatoExport.push(item_test);
      }
    }
    this.exportTestAsExcelFile(DatatoExport)
  }
  dateStringToTimestamp(calenderstring: string) {
    // takes "dd/mm/yyyy" or "dd/mm/yyyy hh:mm" and converts to js Date object
    // console.log('date from calendar ', calenderstring);
    let datesection;
    let timesection;
    let date;
    let time;
    let formatedDate;
    try {
      datesection = calenderstring.split(' ')[0];
      timesection = calenderstring.split(' ')[1];
      date = datesection.split('/');
      time = timesection.split(':');
      formatedDate = new Date(Number(date[2]), Number(date[1]) - 1, Number(date[0]), Number(time[0]), Number(time[1]));
    } catch (TypeError) {
      date = calenderstring.split('/');
      formatedDate = new Date(Number(date[2]), Number(date[1]) - 1, Number(date[0]));
    }

    return formatedDate;
  }


  timestampToDateString(timestamp: Date, dateTime: boolean = true) {
    // take js Date object and returns "yyyy/mm/dd hh:mm"
    // console.log('timestamp ', timestamp);
    // let day = timestamp.getDate().toString();
    // let month = (timestamp.getMonth() + 1).toString();
    // let year = timestamp.getFullYear().toString();
    // let hours = timestamp.getHours().toString()
    // let minutes = timestamp.getMinutes().toString();

    // if (day.length < 2) { day = '0' + day };
    // if (month.length < 2) { month = '0' + month };
    // if (hours.length < 2) { hours = '0' + hours };
    // if (minutes.length < 2) { minutes = '0' + minutes };

    // let datestring = year + '/' + month + '/' + day + ' ' + hours + ':' + minutes;
    if (!timestamp) {
      return;
    }
    let datestring;
    if (dateTime) {
      datestring = formatDate(new Date(timestamp), 'yyyy/MM/dd HH:mm', 'en')
    } else {
      datestring = formatDate(new Date(timestamp), 'yyyy/MM/dd', 'en')

    }
    // console.log('datestring ', datestring);
    return datestring;

  }
  //<----------------- Monthly data for all facilities starts here ----------------->

  filterMonthlyTests(arrayOfallTest) {

    var Dates: any = [];
    arrayOfallTest.forEach(element => {
      Dates.push(element.testdata.dateTime);
    });
    // this.getWeeklyData(Dates);
    return this.returnMonthlyData(Dates, return_data_For.tests);
  }

  filterMonthlyPatients(arrayOfallPatients) {
    var Dates: any = [];
    arrayOfallPatients.forEach(element => {
      Dates.push(element.data.addedDate);
    });

    return this.returnMonthlyData(Dates, return_data_For.patients);
  }
  filterMonthlyInstallations(installationDatesforFac) {

    var Dates: any = []
    installationDatesforFac.forEach(element => {
      Dates.push(element.data.creationTime);
    });
    return this.returnMonthlyData(Dates, return_data_For.installationDates)
  }
  returnMonthlyData(Dates, data_For) {


    this.aggrResult = 0
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    Dates.sort()
    var Check: any = []
    var AggrValue = 0
    var Key1 = " "
    var Key2 = " "
    if (data_For == return_data_For.patients) {
      Key1 = "numberOfPatients"
      Key2 = "aggrPatients"
    } else if (data_For == return_data_For.tests) {
      Key1 = "numberOfTests"
      Key2 = "aggrTests"
    } else if (data_For == return_data_For.installationDates) {
      Key1 = "numberOfInstallations"
      Key2 = "aggrInstallations"
    }

    Dates.forEach(element => {
      if (element != undefined) {
        Check.push(element.toDate())

      }

    });
    this.outputArray = []
    let monthCountArr = new Array(12).fill(0);
    // Dates.forEach(({ date }) => monthCountArr[date.toDate().getMonth()] += 1);
    Dates.forEach(element1 => {
      if (element1 != undefined) {
        monthCountArr[element1.toDate().getMonth()] += 1
      }
    });
    for (let index = 0; index < Dates.length; index++) {
      const element = Dates[index];
      // if (element.toDate().getFullYear() != Dates[index - 1].toDate().getFullYear()) {
      //   monthCountArr = []
      // } else {

      // }
      if (element != undefined) {
        if (index > 0) {
          if (element.toDate().getFullYear() != Dates[index - 1].toDate().getFullYear()) {

            monthCountArr.fill(0);

            Dates.forEach(element1 => {
              if (element1 != undefined) {
                if (element1.toDate().getFullYear() == element.toDate().getFullYear()) {
                  monthCountArr[element1.toDate().getMonth()] += 1
                }
              }
            });
          }
          if (element.toDate().getMonth() != Dates[index - 1].toDate().getMonth()) { // if months are different Create new object and push
            // for (let index1 = 0; index1 <= index; index1++) {
            //   AggrValue += monthCountArr[index1];
            //   console.log(">>>>>>>>>>",monthCountArr,AggrValue);
            // }
            this.aggrResult += monthCountArr[element.toDate().getMonth()]
            this.objectTopush = {
              month: months[element.toDate().getMonth()] + "-" + element.toDate().getFullYear(),
              [Key1]: monthCountArr[element.toDate().getMonth()],
              [Key2]: this.aggrResult
            }
            this.outputArray.push(this.objectTopush);
          }
        } else {
          // for (let index1 = 0; index1 <= index; index1++) {
          //   AggrValue += monthCountArr[index1];
          // }
          this.aggrResult += monthCountArr[element.toDate().getMonth()]
          this.objectTopush = {
            month: months[element.toDate().getMonth()] + "-" + element.toDate().getFullYear(),
            [Key1]: monthCountArr[element.toDate().getMonth()],
            [Key2]: this.aggrResult
          }
          this.outputArray.push(this.objectTopush);

        }
      }
    }
    return this.outputArray;
  }
  //<-------------------- Monthly data for all facilities ends here ---------------------->

  //<-------------------- Monthly data for individual facilities starts here ------------------>
  filterMonthlyTestsForFacility(filteredCustomers) {
    var Dates: any = []
    var outputArray: any = []
    if (this.arraytoReturn.length != 0) {
      this.arraytoReturn = []
    }
    for (let index = 0; index < filteredCustomers.length; index++) {
      const element = filteredCustomers[index];
      this._http.getAllTestsForFacility(filteredCustomers[index].id).then((data: any) => {
        data.forEach(element => {
          Dates.push(element.testdata.dateTime);
        });
        if (index == filteredCustomers.length - 1) {
          this.returnMonthlyDataForFacility(Dates, filteredCustomers[index].id, false, true);
        } else {
          this.returnMonthlyDataForFacility(Dates, filteredCustomers[index].id, false, false);
        }
        Dates = []

      })
    }
  }

  filterMonthlyPatientsForFacility(filteredCustomers) {
    var Dates: any = []
    var outputArray: any = []
    this.PatientsarraytoReturn = []
    for (let index = 0; index < filteredCustomers.length; index++) {
      const element = filteredCustomers[index];
      this._http.getallPatientsForFacility(filteredCustomers[index].id).then((data: any) => {

        data.forEach(element => {
          Dates.push(element.data.addedDate);
        });
        if (index == filteredCustomers.length - 1) {
          this.returnMonthlyPatientDataForFacility(Dates, filteredCustomers[index].id, true)
        } else {
          this.returnMonthlyPatientDataForFacility(Dates, filteredCustomers[index].id, false)
        }
        Dates = []
      })
    }

  }

  returnMonthlyDataForFacility(Dates, FacilityID, isPatients?: boolean, canReturn?: boolean) {
    this.finalOutputObject = {}
    this.aggrResultForfacility = 0
    var id = FacilityID
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var yearlyTestcount = 0;
    var year;
    Dates.sort()
    var Check: any = []
    var Key1 = "numberOfTests"
    var Key2 = "aggrTests"
    if (isPatients) {
      Key1 = "numberOfPatients"
      Key2 = "aggrPatients"
    }
    Dates.forEach(element => {
      Check.push(element.toDate())
    });
    this.outputArray = []
    let monthCountArr = new Array(12).fill(0);
    // Dates.forEach(({ date }) => monthCountArr[date.toDate().getMonth()] += 1);
    Dates.forEach((element1, index) => {
      monthCountArr[element1.toDate().getMonth()] += 1
      year = Dates[index].toDate().getFullYear();
      if (index > 0) {
        if (Dates[index].toDate().getFullYear() == Dates[index - 1].toDate().getFullYear()) {
          yearlyTestcount += 1
          this.finalOutputObject["totals-" + year] = yearlyTestcount;
        } else {
          yearlyTestcount = 0
          yearlyTestcount += 1
          this.finalOutputObject["totals-" + year] = yearlyTestcount;
        }
      } else {
        yearlyTestcount += 1
        this.finalOutputObject["totals-" + year] = yearlyTestcount;
      }
    });
    for (let index = 0; index < Dates.length; index++) {
      const element = Dates[index];
      if (index > 0) {
        if (element.toDate().getFullYear() != Dates[index - 1].toDate().getFullYear()) {
          monthCountArr.fill(0);
          Dates.forEach(element1 => {

            if (element1.toDate().getFullYear() == element.toDate().getFullYear()) {
              monthCountArr[element1.toDate().getMonth()] += 1
            }
          });
        }
        if (element.toDate().getMonth() != Dates[index - 1].toDate().getMonth()) { // if months are different Create new object and push
          this.aggrResultForfacility += monthCountArr[element.toDate().getMonth()]
          this.objectTopush = {
            month: months[element.toDate().getMonth()] + "-" + element.toDate().getFullYear(),
            [Key1]: monthCountArr[element.toDate().getMonth()],
            [Key2]: this.aggrResultForfacility,
          }
          this.outputArray.push(this.objectTopush);
        }
      } else {
        this.aggrResultForfacility += monthCountArr[element.toDate().getMonth()]
        this.objectTopush = {
          month: months[element.toDate().getMonth()] + "-" + element.toDate().getFullYear(),
          [Key1]: monthCountArr[element.toDate().getMonth()],
          [Key2]: this.aggrResultForfacility,
        }
        this.outputArray.push(this.objectTopush);
      }
    }
    this.finalOutputObject["facilityID"] = id;
    this.finalOutputObject["data"] = this.outputArray;
    this.arraytoReturn.push(this.finalOutputObject)
    if (canReturn) {
      this.facFilter.next(this.arraytoReturn);
      this.arraytoReturn = []
    }
  }
  onUpdateFilter(): Observable<any> {
    return this.facFilter.asObservable();
  }
  //<------------- Monthly data for individual facilities ends here ---------------->
  //<------------- Monthly data for individual Patients starts here ---------------->
  returnMonthlyPatientDataForFacility(Dates, FacilityID, canReturn?: boolean) {
    this.finalPatientsOutputObject = {}
    this.aggrResultForfacility = 0
    var id = FacilityID
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var yearlyTestcount = 0;
    var year;

    Dates.sort()
    var Check: any = []
    var Key1 = "numberOfPatients"
    var Key2 = "aggrPatients"
    Dates.forEach(element => {
      try {
        if (element != undefined) {
          Check.push(element.toDate())
        }
      } catch (error) {

      }
    });
    this.outputArray = []
    let monthCountArr = new Array(12).fill(0);
    // Dates.forEach(({ date }) => monthCountArr[date.toDate().getMonth()] += 1);
    try {
      Dates.forEach((element1, index) => {
        if (Dates[index] != undefined) {
          monthCountArr[element1.toDate().getMonth()] += 1
          year = Dates[index].toDate().getFullYear();
          if (index > 0) {
            if (Dates[index].toDate().getFullYear() == Dates[index - 1].toDate().getFullYear()) {
              yearlyTestcount += 1
              this.finalPatientsOutputObject["totals-" + year] = yearlyTestcount;
            } else {
              yearlyTestcount = 0
              yearlyTestcount += 1
              this.finalPatientsOutputObject["totals-" + year] = yearlyTestcount;
            }
          } else {
            yearlyTestcount += 1
            this.finalPatientsOutputObject["totals-" + year] = yearlyTestcount;
          }
        }
      });
    } catch (error) {

    }
    try {
      for (let index = 0; index < Dates.length; index++) {
        const element = Dates[index];
        if (index > 0) {
          if (element.toDate().getFullYear() != Dates[index - 1].toDate().getFullYear()) {
            monthCountArr.fill(0);
            Dates.forEach(element1 => {
              if (element1 != undefined) {
                if (element1.toDate().getFullYear() == element.toDate().getFullYear()) {
                  monthCountArr[element1.toDate().getMonth()] += 1
                }
              }
            });
          }
          if (element.toDate().getMonth() != Dates[index - 1].toDate().getMonth()) { // if months are different Create new object and push
            this.aggrResultForfacility += monthCountArr[element.toDate().getMonth()]
            this.PatientsobjectTopush = {
              month: months[element.toDate().getMonth()] + "-" + element.toDate().getFullYear(),
              [Key1]: monthCountArr[element.toDate().getMonth()],
              [Key2]: this.aggrResultForfacility,
            }
            this.outputArray.push(this.PatientsobjectTopush);
          }
        } else {
          this.aggrResultForfacility += monthCountArr[element.toDate().getMonth()]
          this.PatientsobjectTopush = {
            month: months[element.toDate().getMonth()] + "-" + element.toDate().getFullYear(),
            [Key1]: monthCountArr[element.toDate().getMonth()],
            [Key2]: this.aggrResultForfacility,
          }
          this.outputArray.push(this.PatientsobjectTopush);
        }

      }
    } catch (error) {

    }

    this.finalPatientsOutputObject["facilityID"] = id;
    this.finalPatientsOutputObject["data"] = this.outputArray;
    this.PatientsarraytoReturn.push(this.finalPatientsOutputObject)
    if (canReturn) {
      this.facPatientsFilter.next(this.PatientsarraytoReturn);
      this.PatientsarraytoReturn = []
    }
  }
  onUpdatePatientsFilter(): Observable<any> {
    return this.facPatientsFilter.asObservable();
  }
  //<------------- weekly data implementation starts here -------------->
  getWeeklyData(Dates) {
    Dates.sort()
    let weekCountArr = new Array(7).fill(0);
    var xx = new Date();
    var startDate: any;
    var endDate: any;
    var returnArray: any = []
    var x = 0
    Dates.forEach(async (element, index) => {
      // xx.setTime(element * 1000);
      // document.write(xx.getDay());
      var arrayCountOfWeek;
      // if (new Date(element.toDate()).getDay()==0) {
      //   startDate = element
      // }else if (new Date(element.toDate()).getDay()==6) {
      //   endDate = element
      //
      // }
      if (index > 0) {
        if (new Date(Dates[index - 1].toDate()).getDay() > new Date(element.toDate()).getDay()) {
          arrayCountOfWeek = weekCountArr.reduce((a, b) => a + b, 0)
          x = new Date(element.toDate()).getDay()

          endDate = await this.getEnddate(startDate)

          this.weeklyDataobj = {
            value: arrayCountOfWeek,
            week: formatDate(startDate.toDate(), 'dd/MM/yyyy', 'en') + '-' + formatDate(endDate, 'dd/MM/yyyy', 'en')
          }
          startDate = new Date(new Date(endDate).getDate() + 1)
          returnArray.push(this.weeklyDataobj)
          weekCountArr.fill(0);
          weekCountArr[new Date(element.toDate()).getDay()] += 1
        } else {
          weekCountArr[new Date(element.toDate()).getDay()] += 1
        }
      } else {
        startDate = element;
        endDate = await this.getEnddate(startDate)
        weekCountArr[new Date(element.toDate()).getDay()] += 1
      }

    });
  }
  getEnddate(currentDate) {
    var datetoReturn: any
    var x1: any = 0
    var day = new Date(inputDate).getDay()
    try {
      var inputDate: any = currentDate.toDate()

    } catch (error) {
      var inputDate: any = currentDate
    }

    for (let day = new Date(inputDate).getDay(); day < 6; day++) {
      x1++;
      datetoReturn = new Date(inputDate).setDate(new Date(inputDate).getDate() + x1)
      console.log("My log", day, inputDate, new Date(datetoReturn));
    }
    return new Date(datetoReturn);
  }
  public exportTestCreditAsExcelFile(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    var currentDate = formatDate(new Date(), 'dd_MMM_yyyy_hh_mm_ss', 'en');
    XLSX.writeFile(workbook, this.toExportFileName("Test_Credit_List_" + currentDate));
  }

  widowResize( ){

    let video = document.getElementById('videoElement');

    if(video){

      let width = video.clientWidth - 30;
      let height = video.clientHeight - 2;

      let html = '';
      for( let i=1; i<=20; i++){
        if(i< 16){
          if( i == 5 || i == 10 || i == 15 ){
            html += '<div style="width: '+width/5+'px; height: '+height/4+'px; border-bottom: 4px solid black;"></div>';
          } else {
            html += '<div style="width: '+width/5+'px; height: '+height/4+'px; border-right: 4px solid black;border-bottom: 4px solid black;"></div>';
          }
        }else {
          if( i != 20 ){
            html += '<div style="width: '+width/5+'px; height: '+height/4+'px; border-right: 4px solid black;"></div>';
          }
        }
      }

      if(document.getElementById("gridBox")){
        document.getElementById("gridBox").innerHTML = html;
        document.getElementById("gridBox").style.width = width.toString();
        document.getElementById("gridBox").style.height = height.toString();
      }

    }
  }

}
